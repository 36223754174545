import { createContext, useContext, useEffect, useState } from 'react'

export enum QuizState {
  START_SCREEN = 'start-screen',
  COUNTDOWN = 'countdown',
  ACTIVE_QUIZ = 'active-quiz',
  CALCULATING = 'calculating',
  FINISHED = 'finished',
}

const LeagueQuizContext = createContext<{
  quizState: QuizState
  setQuizState: (state: QuizState) => void
  isExpandedAdActive: boolean
  setIsExpandedAdActive: (active: boolean) => void
  campaignHeaderHeight: string
  hasSanityCampaign?: boolean
  setHasSanityCampaign?: (hasCampaign: boolean) => void
  videoAdActive: boolean
  setVideoAdActive: (active: boolean) => void
  showMobileImages: boolean
  leagueId?: string
  setLeagueId: (leagueId: string) => void
  quizId?: string
  setQuizId: (quizId: string) => void
  instanceId?: string
  setInstanceId: (instanceId?: string) => void
}>({
  quizState: QuizState.START_SCREEN,
  setQuizState: () => Promise.reject(),
  isExpandedAdActive: false,
  setIsExpandedAdActive: () => Promise.reject(),
  campaignHeaderHeight: '0px',
  hasSanityCampaign: false,
  setHasSanityCampaign: () => Promise.reject(),
  videoAdActive: false,
  setVideoAdActive: () => Promise.reject(),
  showMobileImages: false,
  leagueId: undefined,
  setLeagueId: () => Promise.reject(),
  quizId: undefined,
  setQuizId: () => Promise.reject(),
  instanceId: undefined,
  setInstanceId: () => Promise.reject(),
})

// 33vh on mobile, 50vh on desktop screen sizes
export const calculateScoreHeaderHeight =
  window.innerWidth < 500 ? '33vh' : '50vh'

export const expandedHeaderHeight = '33vh'
export const collapsedHeaderHeight = '110px'
export const headerNotVisibleHeight = '0px'

export const useLeagueQuizContext = () => {
  return useContext(LeagueQuizContext)
}

export const LeagueQuizContextProvider = ({
  isEmbed = false,
  children,
}: {
  isEmbed?: boolean
  children: React.ReactNode
}) => {
  const [leagueId, setLeagueId] = useState<string | undefined>(undefined)
  const [quizId, setQuizId] = useState<string | undefined>(undefined)
  const [instanceId, setInstanceId] = useState<string | undefined>(undefined)

  const [quizState, setQuizState] = useState<QuizState>(QuizState.START_SCREEN)
  const [hasSanityCampaign, setHasSanityCampaign] = useState<boolean>(false)
  const [videoAdActive, setVideoAdActive] = useState<boolean>(false)

  const [isExpandedAdActive, setIsExpandedAdActive] = useState<boolean>(false)
  const [campaignHeaderHeight, setCampaignHeaderHeight] = useState<string>(
    headerNotVisibleHeight
  )

  const showMobileImages = isEmbed || window.innerWidth < 700

  useEffect(() => {
    switch (quizState) {
      case QuizState.START_SCREEN:
        setCampaignHeaderHeight(headerNotVisibleHeight)
        break
      case QuizState.COUNTDOWN:
        setCampaignHeaderHeight(expandedHeaderHeight)
        break
      case QuizState.ACTIVE_QUIZ:
        setCampaignHeaderHeight(collapsedHeaderHeight)
        break
      case QuizState.CALCULATING:
        setCampaignHeaderHeight(calculateScoreHeaderHeight)
        break
      default:
        setCampaignHeaderHeight(headerNotVisibleHeight)
    }
  }, [quizState, setCampaignHeaderHeight, hasSanityCampaign])

  const value = {
    quizState,
    setQuizState,
    isExpandedAdActive,
    setIsExpandedAdActive,
    campaignHeaderHeight,
    hasSanityCampaign,
    setHasSanityCampaign,
    videoAdActive,
    setVideoAdActive,
    showMobileImages,
    leagueId,
    setLeagueId,
    quizId,
    setQuizId,
    instanceId,
    setInstanceId,
  }

  return (
    <LeagueQuizContext.Provider value={value}>
      {children}
    </LeagueQuizContext.Provider>
  )
}
