/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SecondaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { PageContent } from '../../../components/layout/PageContent'
import { ModalTrigger } from '../../../components/modal/Modal'
import { isDevelopment } from '../../../config/config'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { LeagueItem } from '../../Home/LeagueItem'
import { LeaguesContainer } from '../../Home/LeaguesContainer'
import { ClubProfileQueryResult, useClubProfileQuery } from '../queries'
import { ClubTopContent } from './ClubTopContent'
import { EmbedCodeModal } from './EmbedCodeModal'
import { Members } from './Members'

type Props = { club: ClubProfileQueryResult['club'] }

const Content: FC<Props> = ({ club }) => {
  const t = useT()

  const showEmbedButton =
    isDevelopment ||
    (club.verified &&
      (club.viewerIsClubAdmin ||
        club.viewerRole === 'moderator' ||
        club.viewerRole === 'senior'))

  useEffect(() => {
    if (club) {
      document.title = `fcQuiz | ${club.name} `
    }
  }, [club])

  return (
    <>
      <PageContent>
        <ClubTopContent club={club} />
      </PageContent>

      {club.subscribedLeagues.length > 0 && (
        <>
          <Spacer height="huge" />
          <PageContent>
            <Heading
              level={3}
              looksLikeLevel={6}
              css={css`
                color: white;
                text-align: center;
                text-transform: uppercase;
              `}
            >
              {t('Leagues')}
            </Heading>
          </PageContent>

          <LeaguesContainer center>
            {club.subscribedLeagues.map((league) => (
              <div
                key={league.id}
                css={css`
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                `}
              >
                <LeagueItem key={league.id} league={league} />
                {showEmbedButton && (
                  <ModalTrigger
                    button={({ openModal }) => (
                      <SecondaryButton
                        variant="white"
                        css={margin.top()}
                        onClick={openModal}
                      >
                        {t('Generate embed')}
                      </SecondaryButton>
                    )}
                    modal={({ closeModal }) => (
                      <EmbedCodeModal
                        clubLeagueSubscriptionId={
                          league.clubLeagueSubscriptionId
                        }
                        closeModal={closeModal}
                      />
                    )}
                  />
                )}
              </div>
            ))}
          </LeaguesContainer>
        </>
      )}

      <PageContent css={margin.vertical('huge')}>
        <Spacer height="huge" />
        <Heading
          level={3}
          looksLikeLevel={6}
          css={css`
            color: white;
            text-align: center;
            text-transform: uppercase;
          `}
        >
          {t('Members')}
        </Heading>
        <Spacer height="large" />
        <Members
          clubId={club.id}
          clubSlug={club.slug}
          viewerRole={club.viewerRole}
        />
      </PageContent>
    </>
  )
}

export function DataLoader() {
  const slug = useParams<'slug'>().slug!
  const query = useClubProfileQuery(slug)
  const club = query.data?.club
  const backgroundImagePath = club?.backgroundImageUrl
    ? club.backgroundImageUrl
    : '/logo-green.png'

  return (
    <>
      <Background backgroundImagePath={backgroundImagePath} />

      <Flex column grow>
        {club ? (
          <Content club={club} />
        ) : query.loading ? null /* Spinner */ : null}
      </Flex>
    </>
  )
}

export const ClubProfilePage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Clubs')}`} backgroundColor="black" withHeader>
      <DataLoader />
    </Layout>
  )
}

const Background = ({
  backgroundImagePath,
}: {
  backgroundImagePath: string
}) => {
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50vh;
        max-height: 500px;
        z-index: -1;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 1px;
          background-image: url(${backgroundImagePath});
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          z-index: -1;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background: linear-gradient(rgba(0, 0, 0, 0.1), #000);
        `}
      />
    </div>
  )
}
