/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { orderBy } from 'lodash'
import { Fragment, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { FramedClubLogo } from '../../components/Picture'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { CheckClubMembershipBadges } from '../../components/badges/CheckClubMembershipBadges'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { isDevelopment, isProduction } from '../../config/config'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, useTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { PageRefreshComponent } from '../Challenge/DisplayPage/PageRefreshComponent'
import UserChallengeList from '../Challenge/UserChallengesList'
import { TopPlayersTodayPreviewComponent } from '../Leaderboards/TopPlayersTodayPreviewComponent'
import { AvatarPromoBanner } from './AvatarPromoBanner'
import { DailyCareerPathQuiz } from './DailyCareerPathQuiz'
import { DailyQuiz } from './DailyQuiz'
import { EnablePushNotificationsBanner } from './EnablePushNotificationsBanner'
import { LeagueItem } from './LeagueItem'
import { LeaguesContainer } from './LeaguesContainer'
import { ShimmerComponent } from './ShimmerComponent'
import { SpecialQuiz } from './SpecialQuiz'
import { WelcomeBanner } from './WelcomeBanner'
import { HomePageLeague, useHomePageQuery } from './queries'
import { PopularQuizzesComponent } from './PopularQuizzesComponent'
import { FriendActivity } from './FriendActivity'

export const sortLeagues = (leagues: HomePageLeague[]) => {
  return orderBy(
    leagues,
    [
      (league) => {
        const canStartAQuiz = league.quizzes.some(
          (quiz) =>
            quiz.viewerCanStartQuiz || quiz.viewerData.activeQuizInstance
        )

        return canStartAQuiz ? 0 : 1
      },
      (league) => {
        return league.availableFrom
      },
    ],
    ['asc', 'desc']
  )
}

const JoinLiveQuizButton = () => {
  const t = useT()

  return (
    <Link
      to="/join"
      css={css`
        text-decoration: none;
      `}
    >
      <PrimaryButton
        css={[
          css`
            width: 100%;
            padding: 16px;
            border-radius: 10px;
            margin: 0 auto;
            background-color: ${colors.purple500};
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 4px 0px ${colors.black};
          `,
        ]}
      >
        <TextNew size={24}>🎉</TextNew>
        <Spacer width={10} />
        <TextNew
          size={32}
          color="white"
          italic
          condensed
          css={css`
            font-weight: 600;
          `}
        >
          {t(`Join live quiz`)}
        </TextNew>
        <Spacer width={14} />
        <TextNew size={24}>🎉</TextNew>
      </PrimaryButton>
    </Link>
  )
}

const Content = () => {
  const t = useT()

  const { checkForRedirectResult } = useAuthContext()

  const homePageQuery = useHomePageQuery()

  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  const authenticated = Boolean(authUser)

  const hasAvatar = () =>
    viewerQuery.data?.viewer?.avatarData &&
    !JSON.parse(viewerQuery.data?.viewer?.avatarData).IsBase

  const nativeApp = useNativeApp()
  const navigate = useNavigate()

  useEffect(() => {
    if (!nativeApp.isNativeApp) {
      return
    }

    if (
      !nativeApp.trackingPermissionStatus ||
      nativeApp.trackingPermissionStatus === 'undetermined'
    ) {
      nativeApp.requestTrackingPermission()
    }

    if (nativeApp.pushNotificationsPermissionStatus === 'undetermined') {
      nativeApp.askForPushNotificationPermission()
    }
  }, [
    nativeApp.isNativeApp,
    nativeApp.pushNotificationsPermissionStatus,
    nativeApp.trackingPermissionStatus,
  ])

  useEffect(() => {
    const checkAndHandleRedirect = async () => {
      await checkForRedirectResult()
    }

    checkAndHandleRedirect()
  }, [])

  const { promotedLeagues, showPromotedLeagueCollection } = useMemo(() => {
    const promotedLeagues = sortLeagues(
      homePageQuery.data?.homePage.promotedLeagueCollection?.leagues ?? []
    )

    const showPromotedLeagueCollection =
      homePageQuery.data?.homePage.promotedLeagueCollection &&
      promotedLeagues.length > 0

    return {
      promotedLeagues,
      showPromotedLeagueCollection,
    }
  }, [homePageQuery.data?.homePage.promotedLeagueCollection])

  const leagueCollections = useMemo(
    () => homePageQuery.data?.homePage.leagueCollections ?? [],
    [homePageQuery.data?.homePage.leagueCollections]
  )

  const popularQuizzes = useMemo(
    () => homePageQuery.data?.homePage.popularQuizzes ?? [],
    [homePageQuery.data?.homePage.popularQuizzes]
  )

  const friendActivity = useMemo(
    () => homePageQuery.data?.homePage.friendActivity,
    [homePageQuery.data?.homePage.friendActivity]
  )

  const PromotedLeagues = () => (
    <>
      {showPromotedLeagueCollection &&
        promotedLeagues.map((league) => (
          <LeagueItem
            key={league.id}
            league={league}
            css={css`
              max-width: 380px;
              flex: 1;
              margin-right: 10px;
            `}
          />
        ))}
    </>
  )

  // unpack homepage data
  const specialQuiz = homePageQuery.data?.homePage.specialQuiz
  const promotedClubs = homePageQuery.data?.homePage.promotedClubs ?? []
  const joinableLiveQuizExists = homePageQuery.data?.getJoinableLiveQuizExists
  const earnedBadges = homePageQuery.data?.earnedBadges

  return (
    <Flex column grow>
      <PageContent>
        <PageRefreshComponent />
        <WelcomeBanner css={margin.bottom('large')} />
        <EnablePushNotificationsBanner css={margin.bottom('large')} />
        <CheckClubMembershipBadges earnedBadges={earnedBadges} />

        {!homePageQuery.loading &&
          authenticated &&
          !authUser?.isAnonymous &&
          !hasAvatar() && <AvatarPromoBanner />}

        {joinableLiveQuizExists && (
          <>
            <Spacer height="large" />
            <JoinLiveQuizButton />
            <Spacer height="large" />
          </>
        )}

        <DailyQuiz
          data={{ league: homePageQuery.data?.dailyQuiz }}
          loading={homePageQuery.loading}
        />

        <DailyCareerPathQuiz
          data={{ league: homePageQuery.data?.dailyCareerPath }}
          loading={homePageQuery.loading}
        />

        {specialQuiz ? (
          <SpecialQuiz
            leaguePageQueryResult={{
              league: specialQuiz,
            }}
          />
        ) : (
          <ShimmerComponent
            css={css`
              border-radius: 10px;
            `}
          />
        )}
      </PageContent>

      <Spacer height="large" />
      <TopPlayersTodayPreviewComponent
        entries={homePageQuery.data?.usersGlobalLeaderboard ?? []}
        loading={homePageQuery.loading}
      />

      {friendActivity && (
        <PageContent>
          <Spacer height="large" />
          <FriendActivity friendActivity={friendActivity} />
        </PageContent>
      )}

      {popularQuizzes.length > 0 && (
        <PageContent>
          <Spacer height="large" />
          <PopularQuizzesComponent quizzes={popularQuizzes} />
        </PageContent>
      )}

      {promotedLeagues.length > 0 && (
        <>
          <Spacer height="large" />
          <LeaguesContainer>
            <PromotedLeagues />
          </LeaguesContainer>
        </>
      )}
      {isDevelopment && (
        <>
          <Spacer height="large" />
          <PageContent>
            <PrimaryButton
              css={css`
                height: 48px;
              `}
              onClick={() => {
                navigate('/leagues')
              }}
            >
              <Text color="white">{t('Leagues')}</Text>
            </PrimaryButton>
          </PageContent>
        </>
      )}
      {isDevelopment && (
        <>
          <Spacer height="large" />
          <PageContent>
            <PrimaryButton
              css={css`
                height: 48px;
              `}
              onClick={() => {
                navigate('/feed')
              }}
            >
              <Text color="white">{'Feed'}</Text>
            </PrimaryButton>
          </PageContent>
        </>
      )}
      {isDevelopment && (
        <>
          <Spacer height="large" />
          <PageContent>
            <PrimaryButton
              css={css`
                height: 48px;
              `}
              onClick={() => {
                navigate('/store')
              }}
            >
              <Text color="white">{'Store'}</Text>
            </PrimaryButton>
          </PageContent>
        </>
      )}

      <Spacer height="large" />
      <UserChallengeList daysLimit={7} isOnHomePage />
      {(leagueCollections ?? []).map((leagueCollection) => {
        if (leagueCollection.leagues.length === 0) {
          return null
        }

        return (
          <Fragment key={leagueCollection.id}>
            <Spacer height="large" />
            <PageContent>
              <Flex horizontal="space-between">
                <Text color="white" size="small">
                  {leagueCollection.title}
                </Text>

                <Text color="white" size="small">
                  ({leagueCollection.leagues.length})
                </Text>
              </Flex>
            </PageContent>

            <Spacer
              height={leagueCollection.leagues.length === 1 ? 'large' : 'small'}
            />

            {leagueCollection.leagues.length === 1 ? (
              <PageContent>
                <LeagueItem
                  key={leagueCollection.leagues[0].id}
                  league={leagueCollection.leagues[0]}
                  css={css`
                    max-width: 380px;
                  `}
                />
              </PageContent>
            ) : (
              <LeaguesContainer>
                {leagueCollection.leagues.map((league) => (
                  <LeagueItem
                    key={league.id}
                    league={league}
                    css={css`
                      max-width: 380px;
                    `}
                  />
                ))}
              </LeaguesContainer>
            )}
          </Fragment>
        )
      })}

      {promotedClubs.length > 0 && (
        <>
          <Spacer height="large" />
          <PageContent>
            <Text color="white" size="small">
              {t('Popular clubs')}
            </Text>
          </PageContent>

          <LeaguesContainer>
            {promotedClubs.map((club) => {
              return (
                <Link to={`/clubs/${club.id}`} key={club.id}>
                  <Flex
                    column
                    horizontal="center"
                    vertical="space-between"
                    css={css`
                      background-color: black;
                      min-width: 240px;
                      max-width: 240px;
                      padding: 16px;
                      padding-bottom: 20px;
                      border-radius: 8px;
                      height: 100%;
                    `}
                  >
                    <Flex column horizontal="center">
                      <Text color="white" size="tiny">
                        {t('{{count}} member', { count: club.membersCount })}
                      </Text>

                      <Text
                        color="white"
                        wide
                        css={css`
                          text-transform: uppercase;
                          text-align: center;
                          font-size: 21px;
                          line-height: 25px;
                          font-weight: 800;
                          ${margin.vertical('small', 'medium')};
                        `}
                      >
                        {club.name}
                      </Text>
                    </Flex>

                    {club.logoUrl && club.logoShape && (
                      <FramedClubLogo
                        size={50}
                        url={club.logoUrl}
                        shape={club.logoShape}
                      />
                    )}
                  </Flex>
                </Link>
              )
            })}
          </LeaguesContainer>
        </>
      )}
      <Spacer height="large" />
    </Flex>
  )
}

export const HomePage = () => {
  useTrackEvent(AnalyticsEventType.PageViewHome)
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      showDownLoadButtons
      backgroundColor={colors.green600}
      showMaintenanceMessage
      showCookiebotButton={isProduction}
      showFooterDownloadButtons
    >
      <Flex column grow>
        <Content />
      </Flex>
    </Layout>
  )
}
