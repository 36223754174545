/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FcQuizLogo } from '../../../components/icons'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import {
  getIconImageForLeague,
  getTextColorForLeague,
} from '../../Challenge/DisplayPage/util'
import { ChallengeLeagueMinimal } from '../../Challenge/queries'

export const QuestionHeader = ({
  league,
  locale,
  questionNumber,
}: {
  league?: ChallengeLeagueMinimal
  locale?: string
  questionNumber?: number
}) => {
  const iconImageUrl = league ? getIconImageForLeague(league) : undefined

  const textColor = league ? getTextColorForLeague(league) : colors.black
  const t = useT(locale)
  return (
    <Flex
      horizontal="space-between"
      vertical="center"
      css={css`
        padding: 2vw;
      `}
    >
      {iconImageUrl && (
        <img
          src={iconImageUrl}
          alt="League icon"
          css={css`
            max-height: 7vw;
            max-width: 30vw;
            object-fit: contain;
          `}
        />
      )}

      {!iconImageUrl && (
        <FcQuizLogo
          color={textColor}
          css={css`
            width: 8vw;
            height: 3vw;
            max-height: 7vw;
            max-width: 30vw;
            object-fit: contain;
          `}
        />
      )}

      <TextNew
        italic
        condensed
        shadow
        color={textColor}
        css={css`
          font-size: 5vw;
          line-height: 1;
          font-weight: 600;
          margin-right: 1vw;
        `}
      >
        {t(`Question {{number}}`, {
          number: questionNumber,
        })}
      </TextNew>
    </Flex>
  )
}
