/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { Flex } from './layout/Flex'
import { Text } from './Text'
import { FiChevronRight } from 'react-icons/fi'

type Props = {
  label: string
  backgroundColor: string
  color: string
  style?: SerializedStyles
  clickable?: boolean
} & React.HTMLAttributes<HTMLDivElement>
export const Chip = ({
  label,
  backgroundColor,
  color,
  style,
  clickable,
  onClick,
}: Props) => {
  return (
    <Flex
      vertical="center"
      onClick={clickable ? onClick : undefined}
      css={[
        css`
          padding: 1px 12px 4px;
          border-radius: 100px;
          background-color: ${backgroundColor};
        `,
        style,
      ]}
    >
      <Text color={color} size="small">
        {label}
      </Text>
      {clickable && <FiChevronRight size={21} color={color} />}
    </Flex>
  )
}
