/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useReward } from 'react-rewards'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { useT } from '../../../lib/i18n/useT'
import { confettiColors } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import {
  getTextColorForLeague,
  ScreenSize,
} from '../../Challenge/DisplayPage/util'
import {
  LiveQuizDisplayInstance,
  useGetLeaderboardForLiveQuizInstanceQuery,
} from '../../LiveQuiz/queries'
import { LiveQuizLeaderboard } from './LiveQuizLeaderboard'

export const FinishedDisplayLiveQuiz = ({
  instance,
  screenSize,
}: {
  instance: LiveQuizDisplayInstance
  screenSize: ScreenSize
}) => {
  const t = useT()
  const leaderboardQuery = useGetLeaderboardForLiveQuizInstanceQuery(
    instance.id
  )

  // total confetti shots = maxConfettiShots + 1 due to useEffect
  const maxConfettiShots = 2
  const [confettiShotTimes, setConfettiShotTimes] = useState(0)

  const confettiFinished = confettiShotTimes >= maxConfettiShots
  const confettiInterval = 1000

  // first confetti shot
  useEffect(() => {
    confettiRewardLeft()
    confettiRewardRight()
  }, [])

  //following confetti shots
  useInterval(
    () => {
      if (confettiShotTimes < maxConfettiShots) {
        setConfettiShotTimes(confettiShotTimes + 1)
        confettiRewardLeft()
        confettiRewardRight()
      }
    },
    confettiFinished ? null : confettiInterval
  )

  const results = [
    ...(leaderboardQuery.data?.getLeaderboardForLiveQuizInstance?.leaderboard ??
      []),
  ]
  const league = instance.league
  const textColor = league ? getTextColorForLeague(league) : colors.black

  const elementCount = 100
  const decay = 0.95
  const spread = 90

  const getElementSizeFromScreenSize = () => {
    switch (screenSize) {
      case 'small':
        return 12
      case '1080p':
        return 16
      case '1440p':
        return 20
      case '4k':
        return 24
    }
  }

  const getStartVelocityFromScreenSize = () => {
    switch (screenSize) {
      case 'small':
        return 80
      case '1080p':
        return 100
      case '1440p':
        return 120
      case '4k':
        return 150
    }
  }

  const { reward: confettiRewardLeft } = useReward(
    'confettiRewardLeft',
    'confetti',
    {
      angle: 45,
      colors: confettiColors,
      startVelocity: getStartVelocityFromScreenSize(),
      elementCount,
      elementSize: getElementSizeFromScreenSize(),
      zIndex: 1000,
      lifetime: 1000,
      decay,
      spread,
    }
  )

  const { reward: confettiRewardRight } = useReward(
    'confettiRewardRight',
    'confetti',
    {
      angle: 135,
      colors: confettiColors,
      startVelocity: getStartVelocityFromScreenSize(),
      elementCount,
      elementSize: getElementSizeFromScreenSize(),
      zIndex: 1000,
      lifetime: 1000,
      decay,
      spread,
    }
  )

  return (
    <Flex
      column
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <div
        id="confettiRewardLeft"
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
        `}
      />

      <div
        id="confettiRewardRight"
        css={css`
          position: absolute;
          bottom: 0;
          right: 0;
        `}
      />

      <Flex
        column
        css={css`
          margin-top: 10vh;
          padding: 0 20vw;
        `}
      >
        <HeadingNew
          level={1}
          color={textColor}
          shadow
          italic
          textAlign="center"
          condensed
          css={[
            css`
              font-weight: 600;
              font-size: 3vw;
            `,
          ]}
        >
          {t(`Final results`)}
        </HeadingNew>

        <LiveQuizLeaderboard results={results} />
      </Flex>
    </Flex>
  )
}
