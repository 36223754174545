/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { MdTv } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { SecondaryButton } from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { Loader as LoadingComponent } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { Cross } from '../../components/icons/Cross'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { colors } from '../../styles/theme'
import { ActiveQuestionLiveQuiz } from './components/ActiveQuestionLiveQuiz'
import { CompletedQuestionLiveQuiz } from './components/CompletedQuestionLiveQuiz'
import { FinishedLiveQuiz } from './components/FinishedLiveQuiz'
import { JoinLiveQuiz } from './components/JoinLiveQuiz'
import { PendingLiveQuiz } from './components/PendingLiveQuix'
import { ReadableQuestionLiveQuiz } from './components/ReadableQuestionLiveQuiz'
import {
  HostInstanceStatus,
  LiveActiveQuestion,
  LiveCompletedQuestion,
  LiveQuizHostInstance,
  useGetLiveQuizHostInstanceQuery,
  useHostSetQuestionReadableMutation,
} from './queries'
import { useWakeLock } from 'react-screen-wake-lock'

export enum LiveQuizStatus {
  PENDING = 'pending',
  JOINABLE = 'joinable',
  READABLEQUESTION = 'readableQuestion',
  ACTIVEQUESTION = 'activeQuestion',
  COMPLETEDQUESTION = 'completedQuesion',
  FINISHED = 'finished',
}

export const getLiveQuizStatus = ({
  completedQuestions = [],
  readableQuestion,
  activeQuestion,
  status,
}: {
  completedQuestions?: LiveCompletedQuestion[]
  readableQuestion?: LiveActiveQuestion
  activeQuestion?: LiveActiveQuestion
  status: HostInstanceStatus
}) => {
  if (status === 'finished' || status === 'completed') {
    return LiveQuizStatus.FINISHED
  }

  if (readableQuestion) {
    return LiveQuizStatus.READABLEQUESTION
  }

  if (activeQuestion) {
    return LiveQuizStatus.ACTIVEQUESTION
  }

  if (completedQuestions.length > 0) {
    return LiveQuizStatus.COMPLETEDQUESTION
  }

  if (status === 'active') {
    return LiveQuizStatus.JOINABLE
  }

  return LiveQuizStatus.PENDING
}

const Content = ({ instance }: { instance: LiveQuizHostInstance }) => {
  const [quizStatus, setQuizStatus] = useState(
    getLiveQuizStatus({ ...instance })
  )

  const [showMobileUrlMenu, setShowMobileUrlMenu] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { isSupported, released, request, release } = useWakeLock()

  useEffect(() => {
    if (isSupported && !released) {
      console.log('Requesting wake lock')
      request()
    }

    return () => {
      if (isSupported && released) {
        console.log('Releasing wake lock')
        release()
      }
    }
  }, [isSupported, released, request, release])

  const activeQuestion = instance.activeQuestion
  const completedQuestions = instance.completedQuestions
  const readableQuestion = instance.readableQuestion

  const lastCompletedQuestion =
    instance.completedQuestions[instance.completedQuestions.length - 1]

  useEffect(() => {
    setQuizStatus(getLiveQuizStatus(instance))
  }, [activeQuestion, completedQuestions, instance.status, readableQuestion])

  const [setReadableMutation] = useHostSetQuestionReadableMutation()

  const nextQuestion = useCallback(
    () =>
      setReadableMutation({
        variables: {
          input: {
            instanceId: instance.id,
            currentQuestionId: lastCompletedQuestion?.id,
          },
        },
      }),
    [instance.id, setReadableMutation]
  )

  const displayPageUrl = () => {
    const league = instance.league

    const root = window.location.origin

    if (league) {
      return `${root}/league/${league.slug}/display`
    }

    return `${root}/live-display/${instance.invitationCode}`
  }

  const urlReadable = displayPageUrl()
    .replaceAll('http://', '')
    .replaceAll('https://', '')

  const Component = () => {
    switch (quizStatus) {
      case LiveQuizStatus.PENDING:
        return <PendingLiveQuiz instance={instance} />
      case LiveQuizStatus.JOINABLE:
        return <JoinLiveQuiz instance={instance} nextQuestion={nextQuestion} />
      case LiveQuizStatus.READABLEQUESTION:
        return <ReadableQuestionLiveQuiz instance={instance} />
      case LiveQuizStatus.ACTIVEQUESTION:
        return <ActiveQuestionLiveQuiz instance={instance} />
      case LiveQuizStatus.COMPLETEDQUESTION:
        return (
          <CompletedQuestionLiveQuiz
            nextQuestion={nextQuestion}
            lastCompletedQuestion={lastCompletedQuestion}
            questionsCount={instance.questionsCount}
            numCompletedQuestions={instance.completedQuestions.length}
          />
        )
      case LiveQuizStatus.FINISHED:
        return <FinishedLiveQuiz instance={instance} />
    }
  }

  return (
    <Flex
      column
      grow
      css={css`
        position: relative;
      `}
    >
      <Component />
      <div
        css={css`
          position: absolute;
          bottom: 16px;
          left: 16px;
        `}
      >
        {isDesktop && (
          <a href={displayPageUrl()} target="_blank" rel="noreferrer">
            <SecondaryButton
              css={css`
                height: 48px;
                width: 48px;
                border-radius: 50%;
                padding: 0;
                padding-top: 5px;
                border: 2px solid ${colors._grey450};
              `}
            >
              <MdTv size={24} />
            </SecondaryButton>
          </a>
        )}
        {!isDesktop && showMobileUrlMenu && (
          <Flex
            column
            gap={'medium'}
            css={css`
              background-color: ${colors.white};
              padding: 16px;
              border-radius: 8px;
              box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
              animation: ${fadeIn} 150ms ease-in-out;
            `}
          >
            <Flex
              horizontal="flex-end"
              role="button"
              aria-label="Close popup"
              vertical="center"
              css={css`
                height: 48px;
              `}
              onClick={() => {
                setShowMobileUrlMenu(false)
              }}
            >
              <Cross color={colors.black} size={24} />
            </Flex>
            <SecondaryButton
              onClick={() => navigator.clipboard.writeText(urlReadable)}
            >
              Copy display url
            </SecondaryButton>
            <TextNew color={colors._grey450}>{urlReadable}</TextNew>
          </Flex>
        )}

        {!isDesktop && !showMobileUrlMenu && (
          <SecondaryButton
            css={css`
              height: 48px;
              width: 48px;
              border-radius: 50%;
              padding: 0;
              padding-top: 4px;
              border: 2px solid ${colors._grey450};
            `}
            onClick={() => {
              setShowMobileUrlMenu(true)
            }}
          >
            <MdTv size={24} />
          </SecondaryButton>
        )}
      </div>
    </Flex>
  )
}

const Loader = ({ instanceId }: { instanceId: string }) => {
  const instanceQuery = useGetLiveQuizHostInstanceQuery(instanceId)
  const { authUser } = useAuthContext()

  if (instanceQuery.loading) {
    return <LoadingComponent center />
  }

  if (
    instanceQuery.error ||
    !instanceQuery.data ||
    !instanceQuery.data.getLiveQuizHostInstance
  ) {
    const reason = instanceQuery.error ? instanceQuery.error.message : 'No data'
    return (
      <DefaultError
        sentryErrorMessage={`LiveQuizHostPage, Loader | reason: ${reason}`}
      />
    )
  }

  const userIsHost: boolean =
    authUser?.uid === instanceQuery.data.getLiveQuizHostInstance.hostId

  if (!userIsHost) {
    const reason = 'User is not host'
    return (
      <DefaultError
        sentryErrorMessage={`LiveQuizHostPage, Loader | reason: ${reason}`}
      />
    )
  }

  return <Content instance={instanceQuery.data.getLiveQuizHostInstance} />
}

export const LiveQuizHostPage = () => {
  const instanceId = useParams<{ instanceId: string }>().instanceId
  const t = useT()

  if (!instanceId) {
    const reason = 'No instance ID'
    return (
      <DefaultError
        sentryErrorMessage={`LiveQuizHostPage | reason: ${reason}`}
      />
    )
  }

  return (
    <Layout title={`fcQuiz | ${t('Host screen')}`} hideNavbar>
      <Loader instanceId={instanceId} />
    </Layout>
  )
}
