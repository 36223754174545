/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { isProduction } from '../../config/config'
import { fadeIn, fadeOut } from '../../styles/animations'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'
import { useT } from '../../lib/i18n/useT'
import adcat from '../../assets/ad-cat.gif'

export enum AdSize {
  BANNER = '468x60',
  LARGE_BANNER = '720x90',
  MEDIUM_RECTANGLE = '300x250',
  LARGE_RECTANGLE = '336x280',
  SQUARE = '250x250',
}

const adsizeToCss = (adSize: AdSize) => {
  switch (adSize) {
    case AdSize.BANNER:
      return css`
        width: 468px;
        height: 60px;
      `
    case AdSize.LARGE_BANNER:
      return css`
        width: 720px;
        height: 90px;
      `
    case AdSize.MEDIUM_RECTANGLE:
      return css`
        width: 300px;
        height: 250px;
      `
    case AdSize.LARGE_RECTANGLE:
      return css`
        width: 336px;
        height: 280px;
      `
    case AdSize.SQUARE:
      return css`
        width: 250px;
        height: 250px;
      `
  }
}

export const RevIqAdComponent = ({
  adSize,
  visible = true,
  trackEvent,
  adblockDetected,
}: {
  adSize: AdSize
  visible?: boolean
  trackEvent?: () => void
  adblockDetected?: boolean
}) => {
  const t = useT()
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!visible || !trackEvent) {
        return
      }

      trackEvent()
    }, 1000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [visible, trackEvent])

  const backgroundColor = visible ? 'black' : 'transparent'

  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={[
        css`
          opacity: 0;
          padding: 16px;
        `,
        visible &&
          css`
            animation: ${fadeIn} 600ms ease-out;
            animation-delay: 600ms;
            animation-fill-mode: forwards;
          `,

        !visible &&
          css`
            animation: ${fadeOut} 350ms ease-out forwards;
            animation-delay: ${0}ms;
          `,
      ]}
    >
      {isProduction && !adblockDetected && (
        <div data-ad="right-rail-1" data-ad-size={adSize} />
      )}

      {isProduction && adblockDetected && (
        <Flex
          column
          horizontal="center"
          vertical="center"
          gap={'medium'}
          css={[
            css`
              background-color: ${backgroundColor};
              border-radius: 4px;
              padding: 16px;
            `,
            adsizeToCss(adSize),
          ]}
        >
          <img
            src={adcat}
            alt="cat-gif"
            style={{
              height: '82px',
              borderRadius: '100%',
              aspectRatio: '1/1',
              margin: '0',
              marginTop: '-11px',
              padding: '6px',
            }}
          />
          <TextNew condensed size="large" color="white" textAlign="center">
            {t('We see you dont like ads, you can watch this cat instead')}
          </TextNew>
        </Flex>
      )}

      {!isProduction && (
        <div
          css={[
            css`
              background-color: ${backgroundColor};
              border-radius: 4px;
            `,
            adsizeToCss(adSize),
          ]}
        />
      )}
    </Flex>
  )
}
