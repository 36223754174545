/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useStartQuickMatchMutation } from '../ChallengeMatch/queries'
import { SpecialQuiz } from '../Home/SpecialQuiz'

import { useSpecialQuizQuery } from '../League/queries'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { FeedItem, FeedLeague, FeedLeagueQuiz } from './queries'

const gapHeight = 32

export const FeedItemComponent = ({ item }: { item: FeedItem }) => {
  if (item.league) {
    return FeedLeagueComponent(item.league)
  }

  if (item.leagueQuiz) {
    return FeedLeagueQuizComponent({
      quiz: item.leagueQuiz,
    })
  }

  if (item.specialLeagueSlug) {
    return <SpecialQuizComponent specialLeagueSlug={item.specialLeagueSlug} />
  }

  return null
}

export const FeedLeagueComponent = (league: FeedLeague) => {
  return (
    <Flex column css={[containerStyle]}>
      <Text>{`${league.title}`}</Text>
    </Flex>
  )
}

export const FeedLeagueQuizComponent = ({
  quiz,
  showChips = true,
}: {
  quiz: FeedLeagueQuiz
  showChips?: boolean
}) => {
  const { authUser, signInAsAnonymous } = useAuthContext()

  const [startLeagueQuizMutation, { loading: startQuizLoading }] =
    useStartLeagueQuizMutation()

  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const [startQuickMatchMutation, { loading: quizMatchLoading }] =
    useStartQuickMatchMutation()

  const navigate = useNavigate()
  const t = useT()

  const isLoading = startQuizLoading || quizMatchLoading

  return (
    <Flex
      horizontal="space-between"
      gap="small"
      css={[
        containerStyle,
        css`
          position: relative;
        `,
      ]}
    >
      {!quiz.league?.viewerIsSubscribed && showChips && (
        <div
          css={css`
            position: absolute;
            padding: 4px 12px;
            background-color: ${colors.yellow400};
            width: fit-content;
            border-radius: 16px;
            top: -16px;
            left: -4px;
          `}
        >
          <Text
            size="small"
            condensed
            css={css`
              line-height: 1;
            `}
          >
            {t(`Popular`)}
          </Text>
        </div>
      )}
      <Flex column vertical="space-between" gap="medium">
        <Flex column>
          {quiz.league && <Text condensed>{`${quiz.league.title}`}</Text>}
          <Text size="huge" strong condensed>{`${quiz.title}`}</Text>
        </Flex>

        <Flex gap="tiny">
          <Text size="small" condensed>
            {quiz.questionsCount > 1
              ? t('{{count}} question_other', {
                  count: quiz.questionsCount,
                })
              : t('{{count}} question', {
                  count: quiz.questionsCount,
                })}
          </Text>
          <Text size="small" condensed>
            {' | '}
            {quiz.participantCount > 1
              ? t('{{count}} player_other', {
                  count: quiz.participantCount,
                })
              : t('{{count}} player', {
                  count: quiz.participantCount,
                })}
          </Text>
        </Flex>
      </Flex>
      <Flex gap="small" column horizontal="flex-end" vertical="center">
        {(quiz.viewerCanStartQuiz || !authUser) && !isLoading && (
          <PrimaryButton
            onClick={async () => {
              if (!authUser) {
                await signInAsAnonymous()
              }

              if (quiz.quizType === 'careerPath') {
                startCareerPathQuizMutation({
                  variables: { input: { leagueQuizId: quiz.id } },
                }).then((result) => {
                  const quizInstanceId = result.data?.startCareerPathQuiz.id
                  if (quizInstanceId) {
                    trackEvent(AnalyticsEventType.StartQuiz, {
                      quizId: quiz.id,
                      leagueId: quiz.league?.id,
                      isCareerPath: true,
                    })
                    navigate(
                      `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`
                    )
                  }
                })
                return
              }

              if (quiz.viewerCanQuickMatch) {
                const res = await startQuickMatchMutation({
                  variables: { input: { quizId: quiz.id } },
                })

                const match = res.data?.startQuickMatch

                if (match) {
                  trackEvent(AnalyticsEventType.QuickMatchStarted, {
                    leagueQuizId: quiz.id,
                  })
                  trackEvent(AnalyticsEventType.StartQuiz, {
                    quizId: quiz.id,
                    leagueId: quiz.league?.id,
                  })

                  navigate(`/challenge-match/${match.id}`)
                  return
                }
              }

              startLeagueQuizMutation({
                variables: { input: { leagueQuizId: quiz.id } },
              }).then((result) => {
                const quizInstanceId = result.data?.startLeagueQuiz.id
                if (quizInstanceId) {
                  trackEvent(AnalyticsEventType.StartQuiz, {
                    quizId: quiz.id,
                    leagueId: quiz.league?.id,
                  })
                  navigate(
                    `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`
                  )
                }
              })
            }}
            css={css`
              height: 48px;
            `}
          >
            <Text color="white" condensed>
              {t('Play Now!')}
            </Text>
          </PrimaryButton>
        )}

        {isLoading && (
          <Flex horizontal="center">
            <Loader delay={0} size={48} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export const SpecialQuizComponent = ({
  specialLeagueSlug,
}: {
  specialLeagueSlug: string
}) => {
  const {
    data: specialQuizData,
    loading: specialQuizLoading,
    error: specialQuizError,
  } = useSpecialQuizQuery(specialLeagueSlug)
  if (
    specialLeagueSlug !== 'afcon' &&
    specialLeagueSlug !== 'champions-league'
  ) {
    return null
  }

  if (specialQuizLoading) {
    return <Loader delay={0} size={48} css={containerStyle} />
  }

  const league = specialQuizData?.league
  if (specialQuizError || !specialQuizData || !league) {
    return null
  }

  return (
    <>
      <SpecialQuiz leaguePageQueryResult={specialQuizData} />
      <Spacer height={gapHeight} />
    </>
  )
}

const containerStyle = css`
  padding: 12px 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  margin-bottom: ${gapHeight}px;
`
