import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useCallback, useMemo, useState } from 'react'
import { Locale } from '../../lib/i18n/locale'
import { MaskShape } from '../../lib/imageMask'
import { LeagueQuizStatus, LeagueStatus } from '../../lib/types'
import { Club, ClubRole } from '../Clubs/queries'
import { badgeFragment } from '../LeagueQuiz/queries'
import {
  HomePageLeague,
  HomePageLeagueCollection,
  homePageLeagueFragment,
} from './../Home/queries'

export type LeagueQuizMinimal = {
  id: string
  title: string
  slug: string
  imageUrl?: string
  viewerCanStartQuiz: boolean
  questionsCount: number
  availableFrom: string
  availableTo?: string
  status: LeagueQuizStatus
  embedPromotionalText?: string
  viewerData: {
    canStartQuiz: boolean
    hasCompletedQuiz: boolean
    activeQuizInstance?: { id: string }
    result?: {
      leagueQuizInstanceId: string
      correctAnswersCount: number
      correctAnswerScore: number
      timeBonus: number
      totalScore: number
    }
  }
  participantCount: number
  quizType?: string
  quizNumber?: number
}

export enum LeagueFrequency {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export const frequencyToString = (frequency: LeagueFrequency) => {
  switch (frequency) {
    case LeagueFrequency.daily:
      return 'Daily'
    case LeagueFrequency.weekly:
      return 'Weekly'
    case LeagueFrequency.monthly:
      return 'Monthly'
  }
}

export enum RewardFrequency {
  once = 'once',
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export type RewardCriteria = {
  minScore: number
  hasPlayedAllQuizzesInPeriod: boolean
}

export type Badge = {
  id: string
  dbId?: string
  title: string
  imageUrl: string
  criteria: {
    quizzesCompleted: number
  }
  requirementMessage: string
  earnedMessage: string
  league: {
    id: string
  }
  progressBackgroundColor: string
  progressForegroundColor: string
  seenByUser: boolean
  earnedAt?: string
}

export type League = {
  id: string
  slug: string
  title: string
  description?: string
  imageUrl?: string
  leagueCardImageUrl?: string
  frequency?: LeagueFrequency
  status: LeagueStatus
  availableFrom: string
  availableTo?: string
  activeQuizzes: LeagueQuizMinimal[]
  lastCompletedQuizzes: LeagueQuizMinimal[]
  currentQuiz?: LeagueQuizMinimal
  live: number
  participantCount: number
  subscriberCount: number
  match?: {
    matchDate: string
    homeTeam: {
      name: string
      imageUrl?: string
      club?: {
        id: string
        slug?: string
        backgroundImageUrl?: string
      }
    }
    awayTeam: {
      name: string
      imageUrl?: string
      club?: {
        id: string
        slug?: string
        backgroundImageUrl?: string
      }
    }
  }
  linkedLeagues: HomePageLeague[]
  isTournament?: boolean
  leagueCollections?: HomePageLeagueCollection[]
  promotedClubs?: Array<{
    id: string
    name: string
    slug?: string
    logoUrl?: string
    logoShape: MaskShape
    membersCount: number
  }>
  viewerIsSubscribed: boolean
  viewerResult: {
    correctAnswersCount: number
    questionsCount: number
    timeBonus: number
    totalScore: number
  }
  isRewarded?: boolean
  reward?: {
    rewardDescription: string
    rewardImageUrl: string
    numberOfWinners: number
    rewardFrequency: RewardFrequency
    rewardCriteria: RewardCriteria
  }
  languageRestrictedLeague?: Locale[]
  hasSubscribedClubs?: boolean
  showClubsLeaderboard?: boolean
  badges: Badge[]
  quizzesPlayed: number
  viewerIsOwner?: boolean
  viewerEarnedBadges: Badge[]
}

export type LeaguePageQueryResult = {
  league?: League
}

export type DailyLeague = {
  id: string
  currentQuiz?: LeagueQuizMinimal
  streak: number
  hasSeenStreak: boolean
  live: number
  participantCount: number
  viewerIsOwner?: boolean
  viewerHasPlayedToday?: boolean
}

export type LeagueStreakQueryResult = {
  league?: DailyLeague
}

export type DailyCareerPathLeague = {
  id: string
  latestStartableQuiz?: LeagueQuizMinimal
  streak: number
  hasSeenStreak: boolean
  viewerIsOwner?: boolean
  participantCount: number
  viewerHasPlayedToday: boolean
  live: number
}

export type DailyCareerPathQueryResult = {
  league?: DailyCareerPathLeague
}

export enum TrophyType {
  first = 'FIRST',
  second = 'SECOND',
  third = 'THIRD',
}

export const leaguePageLeagueQuizFragment = gql`
  fragment LeaguePageLeagueQuiz on LeagueQuiz {
    id
    title
    slug
    questionsCount
    availableFrom
    availableTo
    status
    viewerCanStartQuiz
    participantCount
    viewerData {
      id
      canStartQuiz
      hasCompletedQuiz
      activeQuizInstance {
        id
        quizType
      }
      result {
        leagueQuizInstanceId
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
    }
    quizType
    quizNumber
  }
`

const leaguePageQuery = gql`
  query leaguePage($slug: String!) {
    league(slug: $slug) {
      id
      slug
      title
      description
      leagueCardImageUrl
      imageUrl
      frequency
      status
      availableFrom
      availableTo
      currentQuiz {
        ...LeaguePageLeagueQuiz
      }
      live
      participantCount
      subscriberCount
      match {
        matchDate
        homeTeam {
          name
          imageUrl
          club {
            id
            slug
            backgroundImageUrl
          }
        }
        awayTeam {
          name
          imageUrl
          club {
            id
            slug
            backgroundImageUrl
          }
        }
      }
      linkedLeagues {
        ...HomePageLeagueFragment
      }
      isTournament
      leagueCollections {
        id
        display
        title
        leagues {
          ...HomePageLeagueFragment
        }
      }
      promotedClubs {
        id
        name
        slug
        logoUrl
        logoShape
        membersCount
      }
      viewerIsSubscribed
      viewerResult {
        correctAnswersCount
        questionsCount
        timeBonus
        totalScore
      }

      activeQuizzes: quizzes(status: [active, upcoming]) {
        ...LeaguePageLeagueQuiz
      }
      lastCompletedQuizzes: quizzes(status: [completed], limit: 3) {
        ...LeaguePageLeagueQuiz
      }
      isRewarded
      reward {
        rewardDescription
        rewardImageUrl
        numberOfWinners
        rewardFrequency
      }
      languageRestrictedLeague
      hasSubscribedClubs
      viewerLeaderboardEntry {
        user {
          id
          username
          slug
          avatarData
          displayName
          mainClubMembership {
            id
            role
            club {
              id
              name
              logoUrl
              logoShape
            }
          }
        }
        rank
        score
      }
      badges {
        ...BadgeFragment
      }
      showClubsLeaderboard
      viewerIsOwner
      quizzesPlayed
      viewerEarnedBadges {
        ...BadgeFragment
      }
    }
  }

  ${leaguePageLeagueQuizFragment}
  ${homePageLeagueFragment}
  ${badgeFragment}
`

export const useLeaguePageQuery = (leagueSlug: string, skip = false) => {
  return useQuery<LeaguePageQueryResult, { slug: string }>(leaguePageQuery, {
    variables: { slug: leagueSlug },
    fetchPolicy: 'cache-and-network',
    skip,
  })
}

export const leagueStreakQuery = gql`
  query leagueStreak($slug: String!) {
    league(slug: $slug) {
      id
      currentQuiz {
        ...LeaguePageLeagueQuiz
      }
      streak
      hasSeenStreak
      live
      participantCount
      viewerIsOwner
    }
  }
  ${leaguePageLeagueQuizFragment}
`

export const dailyCareerPathQuery = gql`
  query dailyCareerPath($slug: String!) {
    league(slug: $slug) {
      id
      latestStartableQuiz {
        id
        title
        slug
        questionsCount
        availableFrom
        availableTo
        status
        viewerCanStartQuiz
        participantCount
        quizType
        viewerData {
          id
          canStartQuiz
          hasCompletedQuiz
          activeQuizInstance {
            id
          }
          result {
            leagueQuizInstanceId
            correctAnswersCount
            correctAnswerScore
            timeBonus
            totalScore
          }
        }
      }
      streak
      hasSeenStreak
      viewerHasPlayedToday
      viewerIsOwner
      participantCount
      live
    }
  }
`

export const useLeagueStreakQuery = (leagueSlug: string, skip = false) => {
  return useQuery<LeagueStreakQueryResult, { slug: string }>(
    leagueStreakQuery,
    {
      variables: { slug: leagueSlug },
      fetchPolicy: 'cache-and-network',
      skip,
    }
  )
}

export const useGetDailyCareerPathQuery = (
  leagueSlug: string,
  skip = false
) => {
  return useQuery<DailyCareerPathQueryResult, { slug: string }>(
    dailyCareerPathQuery,
    {
      variables: { slug: leagueSlug },
      fetchPolicy: 'cache-and-network',
      skip,
    }
  )
}

const allCompletedLeagueQuizzesQuery = gql`
  query allCompletedLeagueQuizzesQuery($slug: String!) {
    league(slug: $slug) {
      id
      slug
      quizzes(status: [completed]) {
        ...LeaguePageLeagueQuiz
      }
    }
  }

  ${leaguePageLeagueQuizFragment}
`

export const useAllCompletedLeagueQuizzesQuery = () => {
  return useLazyQuery<
    { league: { quizzes: LeagueQuizMinimal[] } },
    { slug: string }
  >(allCompletedLeagueQuizzesQuery)
}

const specialQuizQuery = gql`
  query specialQuizQuery($slug: String!, $limit: Int!) {
    league(slug: $slug) {
      id
      slug
      title
      imageUrl
      color
      showClubsLeaderboard
      latestStartableQuiz {
        ...LeaguePageLeagueQuiz
      }
      upcommingQuiz {
        ...LeaguePageLeagueQuiz
      }
      badges {
        ...BadgeFragment
      }
      clubsLeaderboard(limit: $limit) {
        id
        club {
          id
          name
          logoUrl
          logoShape
          public
          membersCount
          viewerIsMember
        }
        rank
        score
      }
      quizzesPlayed
      participantCount
      live
    }
  }

  ${leaguePageLeagueQuizFragment}
  ${badgeFragment}
`

export type SpecialQuiz = {
  id: string
  slug: string
  title: string
  imageUrl?: string
  color?: string
  showClubsLeaderboard?: boolean
  latestStartableQuiz?: LeagueQuizMinimal
  upcommingQuiz?: LeagueQuizMinimal
  clubsLeaderboard: ClubLeaderboardEntry[]
  badges: Badge[]
  quizzesPlayed: number
  participantCount: number
  live: number
}

export type SpecialQuizQueryResults = {
  league: SpecialQuiz
}

export const useSpecialQuizQuery = (slug: string, limit = 3) => {
  return useQuery<SpecialQuizQueryResults, { slug: string; limit: number }>(
    specialQuizQuery,
    {
      variables: { slug, limit },
      fetchPolicy: 'cache-and-network',
      skip: slug == '',
    }
  )
}

export type LeaderboardItem = {
  user: {
    id: string
    username?: string
    displayName?: string
    slug: string
    avatarData?: string
    mainClubMembership?: {
      id: string
      role: ClubRole
      club: {
        id: string
        slug: string
        name: string
        logoUrl?: string
        logoShape: MaskShape
      }
    }
  }
  score: number
  rank: number
}

export type ClubLeaderboardEntry = {
  id: string
  club: Club
  score: number
  rank: number
}

export type LeagueLeaderboardQueryResult = {
  league?: {
    leaderboard: LeaderboardItem[]
    viewerLeaderboardEntry?: LeaderboardItem
    friendsLeaderboard: LeaderboardItem[]
  }
}

export const leaderboardItemFragment = gql`
  fragment LeaderboardItem on LeaderboardItem {
    user {
      id
      username
      slug
      avatarData
      displayName
      mainClubMembership {
        id
        role
        club {
          id
          slug
          name
          logoUrl
          logoShape
        }
      }
    }
    rank
    score
  }
`
export const leagueLeaderboardItemFragment = gql`
  fragment LeagueLeaderboardItem on LeaderboardItem {
    user {
      id
      username
      slug
      avatarData
      displayName
    }
    rank
    score
  }
`

export const leagueLeaderboardQuery = gql`
  query leagueLeaderboard(
    $slug: String!
    $clubId: String
    $limit: Int
    $offset: Int
  ) {
    league(slug: $slug, clubId: $clubId) {
      slug
      leaderboard(limit: $limit, offset: $offset) {
        ...LeaderboardItem
      }
      viewerLeaderboardEntry {
        ...LeaderboardItem
      }
      friendsLeaderboard {
        ...LeaderboardItem
      }
    }
  }
  ${leaderboardItemFragment}
`

const LEADERBOARD_LIMIT = 20

export const useLeagueLeaderboard = (
  slug: string,
  clubId?: string,
  skip?: boolean
) => {
  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreReal,
  } = useQuery<
    LeagueLeaderboardQueryResult,
    { slug: string; clubId?: string; limit?: number; offset?: number }
  >(leagueLeaderboardQuery, {
    variables: {
      slug: slug,
      clubId,
      limit: LEADERBOARD_LIMIT,
      offset: 0,
    },
    skip,
  })

  const leaderboard = useMemo(() => data?.league?.leaderboard ?? [], [data])

  const [hasMore, setHasMore] = useState(
    data?.league?.leaderboard?.length &&
      data?.league?.leaderboard?.length > LEADERBOARD_LIMIT
  )

  const fetchMore = useCallback(() => {
    const lastItem: LeaderboardItem | undefined = leaderboard.slice(-1)[0]

    fetchMoreReal({
      variables: {
        limit: LEADERBOARD_LIMIT,
        offset: leaderboard.length,
      },
    }).then((result) => {
      const lastItemFromResult = result.data.league?.leaderboard.slice(-1)[0]

      if (!lastItemFromResult) {
        setHasMore(false)
      } else if (lastItemFromResult.user.id === lastItem?.user.id) {
        setHasMore(false)
      }
    })
  }, [fetchMoreReal, leaderboard])

  return {
    leaderboard,
    viewerLeaderboardEntry: data?.league?.viewerLeaderboardEntry,
    friendsLeaderboard: data?.league?.friendsLeaderboard ?? [],
    loading,
    error,
    hasMore: hasMore && !loading && leaderboard.length >= LEADERBOARD_LIMIT,
    fetchMore,
  }
}

export const leagueClubsLeaderboardQuery = gql`
  query leagueLeaderboard($slug: String!, $limit: Int) {
    league(slug: $slug) {
      slug
      clubsLeaderboard(limit: $limit) {
        id
        club {
          id
          name
          logoUrl
          logoShape
          public
          membersCount
          viewerIsMember
        }
        rank
        score
      }
    }
  }
`
// Be careful. Using id instead of slug will cause warning data loss errors
export const useLeagueClubsLeaderboard = (slug: string, limit?: number) => {
  const { data, loading, error } = useQuery<
    {
      league?: {
        clubsLeaderboard: ClubLeaderboardEntry[]
      }
    },
    { slug: string; limit?: number }
  >(leagueClubsLeaderboardQuery, {
    variables: {
      slug,
      limit,
    },
  })

  const clubsLeaderboard = useMemo(
    () => data?.league?.clubsLeaderboard ?? [],
    [data]
  )

  return {
    clubsLeaderboard,
    loading,
    error,
  }
}

export type LeagueQuizLeaderboardQueryResult = {
  leagueQuiz?: {
    title: string
    leaderboard: Array<LeaderboardItem>
    viewerLeaderboardEntry?: LeaderboardItem
    clubsLeaderboard: Array<ClubLeaderboardEntry>
    friendsLeaderboard: Array<LeaderboardItem>
  }
}

export const leagueQuizLeaderboardQuery = gql`
  query leagueQuizLeaderboard(
    $id: ID!
    $clubId: String
    $limit: Int
    $offset: Int
  ) {
    leagueQuiz(id: $id, clubId: $clubId) {
      id
      title
      leaderboard(limit: $limit, offset: $offset) {
        ...LeaderboardItem
      }
      viewerLeaderboardEntry {
        ...LeaderboardItem
      }
      clubsLeaderboard {
        id
        club {
          id
          name
          logoUrl
          logoShape
          public
          membersCount
          viewerIsMember
        }
        rank
        score
      }
      friendsLeaderboard {
        ...LeaderboardItem
      }
    }
  }
  ${leaderboardItemFragment}
`
export const useLeagueQuizLeaderboard = (
  leagueQuizId: string,
  clubId?: string
) => {
  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreReal,
  } = useQuery<
    LeagueQuizLeaderboardQueryResult,
    { id: string; clubId?: string; limit?: number; offset?: number }
  >(leagueQuizLeaderboardQuery, {
    variables: {
      id: leagueQuizId,
      clubId,
      limit: LEADERBOARD_LIMIT,
      offset: 0,
    },
    skip: leagueQuizId === '',
  })

  const leaderboard = useMemo(() => data?.leagueQuiz?.leaderboard ?? [], [data])

  const [hasMore, setHasMore] = useState(
    data?.leagueQuiz?.leaderboard?.length &&
      data?.leagueQuiz?.leaderboard?.length > LEADERBOARD_LIMIT
  )
  const fetchMore = useCallback(() => {
    const lastItem: LeaderboardItem | undefined = leaderboard.slice(-1)[0]

    fetchMoreReal({
      variables: {
        limit: LEADERBOARD_LIMIT,
        offset: leaderboard.length,
      },
    }).then((result) => {
      const lastItemFromResult =
        result.data.leagueQuiz?.leaderboard.slice(-1)[0]

      if (!lastItemFromResult) {
        setHasMore(false)
      } else if (lastItemFromResult.user.id === lastItem?.user.id) {
        setHasMore(false)
      }
    })
  }, [fetchMoreReal, leaderboard])

  const refetch = useCallback(() => {
    fetchMoreReal({
      variables: {
        limit: LEADERBOARD_LIMIT,
        offset: 0,
      },
    })
  }, [fetchMoreReal])

  return {
    leaderboard,
    viewerLeaderboardEntry: data?.leagueQuiz?.viewerLeaderboardEntry,
    clubsLeaderboard: data?.leagueQuiz?.clubsLeaderboard ?? [],
    leagueQuizTitle: data?.leagueQuiz?.title,
    friendsLeaderboard: data?.leagueQuiz?.friendsLeaderboard ?? [],
    loading,
    error,
    hasMore,
    fetchMore,
    refetch,
  }
}

export const viewerClubsQuery = gql`
  query viewerClub {
    viewer {
      id
      clubMemberships {
        id
        role
        club {
          id
          name
        }
      }
    }
  }
`

export const useViewerClubs = () => {
  return useQuery<{
    viewer: {
      clubMemberships: Array<{
        id: string
        role: ClubRole
        club: { id: string; name: string }
      }>
    }
  }>(viewerClubsQuery)
}

export const useSubscribeToLeague = () =>
  useMutation<
    {
      subscribeToLeague: { id: string; viewerIsSubscribed: boolean }
    },
    { leagueId: string }
  >(gql`
    mutation subscribeToLeague($leagueId: ID!) {
      subscribeToLeague(leagueId: $leagueId) {
        id
        viewerIsSubscribed
      }
    }
  `)

export const useUnsubscribeFromLeague = () =>
  useMutation<
    {
      unsubscribeFromLeague: { id: string; viewerIsSubscribed: boolean }
    },
    { leagueId: string }
  >(gql`
    mutation unsubscribeFromLeague($leagueId: ID!) {
      unsubscribeFromLeague(leagueId: $leagueId) {
        id
        viewerIsSubscribed
      }
    }
  `)

export const useSetHasSeenStreak = () =>
  useMutation<
    {
      setHasSeenStreak: { hasSeenStreak: boolean }
    },
    { leagueIdOrSlug: string }
  >(gql`
    mutation setHasSeenStreak($leagueIdOrSlug: ID!) {
      setHasSeenStreak(leagueIdOrSlug: $leagueIdOrSlug) {
        hasSeenStreak
      }
    }
  `)

export const leagueAllLiveQuizzesQuery = gql`
  query leagueAllLiveQuizzes($slug: String!) {
    league(slug: $slug) {
      id
      slug
      liveQuizzes {
        ...LeaguePageLeagueQuiz
      }
    }
  }
  ${leaguePageLeagueQuizFragment}
`

export const useLeagueAllLiveQuizzesQuery = (slug: string, skip?: boolean) => {
  return useQuery<
    { league: { id: string; slug: string; liveQuizzes: LeagueQuizMinimal[] } },
    { slug: string }
  >(leagueAllLiveQuizzesQuery, {
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    skip,
  })
}
