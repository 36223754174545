/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { FC, useEffect } from 'react'
import { useT } from '../../lib/i18n/useT'
import theme, { hexOpacity } from '../../styles/theme'
import { titleFromQuiz } from '../../utils/util'
import {
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { LeagueQuiz } from '../../views/LeagueQuiz/queries'
import { HeadingNew } from '../HeadingNew'
import { Text } from '../Text'
import { TextNew } from '../TextNew'
import { PoweredByFcQuiz } from '../icons'
import { Flex } from '../layout/Flex'

export const QuizStartScreen: FC<{
  leagueQuiz: LeagueQuiz
  colorTheme: 'red' | 'white' | 'green'
  isEmbed?: boolean
}> = ({ leagueQuiz, colorTheme, isEmbed }) => {
  const { setQuizState } = useLeagueQuizContext()

  const startQuiz = () => {
    setQuizState(QuizState.COUNTDOWN)
  }

  const campaign = leagueQuiz.campaign

  const fadeIn = keyframes`
        0%{
          opacity: 0;
        }
        100%{
          opacity: 1;
        }
    `
  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  const showEmbedCampaign = isEmbed && campaign

  useEffect(() => {
    const timer = setTimeout(
      () => {
        startQuiz()
      },
      showEmbedCampaign ? 5000 : 2000
    )

    return () => clearTimeout(timer)
  }, [])

  const t = useT()

  return (
    <Flex
      grow
      column
      vertical={'space-between'}
      css={css`
        padding: 24px 36px;
        max-width: 560px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
      `}
    >
      {showEmbedCampaign && (
        <Flex
          column
          grow
          horizontal="center"
          vertical="center"
          css={css`
            animation: ${fadeIn} 2s ease-in-out;
          `}
        >
          <Flex
            column
            vertical="center"
            css={css`
              padding: 8px 16px 12px;
            `}
          >
            <HeadingNew
              level={3}
              italic
              condensed
              css={css`
                text-align: center;
                line-height: 0.8;
                font-size: 39px;
                line-height: 30px;
                letter-spacing: 0em;
              `}
            >
              {leagueQuiz?.title ?? leagueQuiz.league.title}
            </HeadingNew>
          </Flex>

          {leagueQuiz?.embedPromotionalText && (
            <TextNew
              strong
              css={css`
                letter-spacing: 0.01em;
                text-align: center;
                font-size: 20px;
                line-height: 30px;
                margin: 4px 32px;
                padding: 12px 16px;
                max-width: 500px;
                border-radius: 4px;
              `}
            >
              {leagueQuiz?.embedPromotionalText}
            </TextNew>
          )}
          {campaign.partnerLogoUrl && (
            <Flex horizontal="center" vertical="center" gap="small">
              <TextNew
                css={css`
                  font-size: 12px;
                  line-height: 36px;
                  color: ${hexOpacity(textColor, 0.5)};
                `}
              >
                {t(`in partnership with`)}
              </TextNew>
              <img
                src={campaign.partnerLogoUrl}
                alt={'{t(`Partner logo`)}'}
                css={css`
                  width: 100px;
                  margin: 8px;
                  max-height: 36px;
                `}
              />
            </Flex>
          )}
        </Flex>
      )}
      {!showEmbedCampaign && (
        <Flex
          column
          grow
          horizontal="center"
          vertical="center"
          css={css`
            animation: ${fadeIn} 2s ease-in-out;
          `}
        >
          <Text
            extraCondensed
            strong
            css={css`
              font-size: 54px;
              line-height: 54px;
              color: ${textColor};
              text-align: center;
              font-style: italic;
            `}
          >
            {titleFromQuiz(leagueQuiz, t)}
          </Text>
        </Flex>
      )}

      <Flex
        horizontal="center"
        css={css`
          margin-top: 48px;
        `}
      >
        <div
          css={css`
            position: absolute;
            bottom: 0;
            padding-bottom: 16px;
          `}
        >
          <PoweredByFcQuiz />
        </div>
      </Flex>
    </Flex>
  )
}
