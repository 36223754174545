/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSwipeable } from 'react-swipeable'
import { LeftArrow, RightArrow } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { buttonReset } from '../../styles/styles'

const useRefWidth = <T extends HTMLElement>(initialWidth?: number) => {
  const ref = useRef<T>(null)
  const [width, setWidth] = useState(initialWidth ?? 0)

  useEffect(() => {
    const setSize = () => {
      if (ref.current) setWidth(ref.current.getBoundingClientRect().width)
    }
    window.addEventListener('resize', setSize)
    return () => {
      window.removeEventListener('resize', setSize)
    }
  }, [ref])

  return [ref, width] as const
}

const calcLeft = (
  containerWidth: number,
  index: number,
  itemWidth: number,
  itemSpacing: number
) => {
  return (
    containerWidth / 2 -
    itemWidth / 2 -
    (index * itemWidth + index * itemSpacing)
  )
}

export type SelectorProps = {
  items: ReactNode[]
  selectedIndex: number
  onSelectIndex: (index: number) => void
  itemWidth: number
  itemSpacing: number
  newLook?: boolean
}

export const LeagueQuizSelector = ({
  items,
  selectedIndex,
  onSelectIndex,
  itemWidth,
  itemSpacing,
  newLook = false,
}: SelectorProps) => {
  const [containerRef, containerWidth] = useRefWidth<HTMLDivElement>(
    window.innerWidth
  )

  const goToIndex = useCallback(
    (index: number) => {
      if (index >= 0 && index < items.length) {
        onSelectIndex(index)
      }
    },
    [items.length, onSelectIndex]
  )
  const next = useCallback(() => {
    onSelectIndex(
      selectedIndex < items.length - 1 ? selectedIndex + 1 : selectedIndex
    )
  }, [items.length, onSelectIndex, selectedIndex])

  const prev = useCallback(() => {
    onSelectIndex(selectedIndex > 0 ? selectedIndex - 1 : selectedIndex)
  }, [onSelectIndex, selectedIndex])

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.defaultPrevented ||
        event.metaKey ||
        event.shiftKey ||
        event.altKey ||
        event.ctrlKey
      ) {
        return
      }

      if (event.key === 'ArrowRight') {
        next()
        event.preventDefault()
      } else if (event.key === 'ArrowLeft') {
        prev()
        event.preventDefault()
      }
    },
    [next, prev]
  )

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    preventDefaultTouchmoveEvent: true,
  })

  const left = calcLeft(containerWidth, selectedIndex, itemWidth, itemSpacing)

  return (
    <Flex
      vertical={'center'}
      ref={containerRef}
      onKeyDown={onKeyDown}
      tabIndex={0}
      column
      css={css`
        outline: none;
        height: 100%;
      `}
    >
      <Flex
        css={css`
          overflow-x: hidden;
          position: relative;
          padding-bottom: 40px;
        `}
        {...swipeableHandlers}
      >
        <Flex
          vertical={'center'}
          css={[
            css`
              position: relative;
              min-height: 195px;
              height: 100%;
            `,
            document.dir === 'rtl'
              ? css`
                  right: ${left}px;
                  transition: right 200ms ease-in-out;
                `
              : css`
                  left: ${left}px;
                  transition: left 200ms ease-in-out;
                `,
          ]}
        >
          {items.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => goToIndex(index)}
                css={[
                  css`
                    flex-shrink: 0;
                    min-width: ${itemWidth}px;
                    height: ${itemWidth}px;
                    margin-right: ${itemSpacing}px;
                    transition: transform 200ms ease-in-out;
                    transform-origin: 50% 200%;
                    transform: scale(0.9);
                  `,
                  selectedIndex === index
                    ? css`
                        transform: scale(1);
                      `
                    : css`
                        &:hover {
                          transform: scale(0.95);
                          cursor: pointer;
                        }
                      `,
                ]}
              >
                {item}
              </div>
            )
          })}
        </Flex>
      </Flex>

      {!newLook && (
        <Controls
          numberOfItems={items.length}
          selectedIndex={selectedIndex}
          onSelectIndex={goToIndex}
          prev={prev}
          next={next}
        />
      )}
    </Flex>
  )
}

const Controls = ({
  numberOfItems,
  selectedIndex,
  onSelectIndex,
  prev,
  next,
}: {
  numberOfItems: number
  selectedIndex: number
  onSelectIndex: (index: number) => void
  prev: () => void
  next: () => void
}) => {
  return (
    <Flex horizontal="center" vertical="center">
      <Flex vertical="center" gap="tiny">
        <ArrowButton
          onClick={prev}
          direction={document.dir === 'rtl' ? 'right' : 'left'}
          disabled={selectedIndex === 0}
        />

        <Flex wrap horizontal="center">
          {new Array(numberOfItems).fill(0).map((_, index) => {
            return (
              <DotButton
                key={index}
                onClick={() => onSelectIndex(index)}
                selected={selectedIndex === index}
              />
            )
          })}
        </Flex>

        <ArrowButton
          onClick={next}
          direction={document.dir === 'rtl' ? 'left' : 'right'}
          disabled={selectedIndex === numberOfItems - 1}
          css={css`
            margin-top: -2px;
            margin-left: 2px;
          `}
        />
      </Flex>
    </Flex>
  )
}

const ArrowButton = ({
  onClick,
  direction,
  disabled,
  className,
}: {
  onClick: () => void
  direction: 'left' | 'right'
  disabled?: boolean
  className?: string
}) => {
  return (
    <button
      onClick={onClick}
      css={[
        buttonReset,
        disabled &&
          css`
            opacity: 0.25;
            cursor: initial;
          `,
      ]}
      className={className}
      disabled={disabled}
    >
      <Flex vertical="center">
        {direction === 'left' ? (
          <LeftArrow color="white" />
        ) : (
          <RightArrow color="white" />
        )}
      </Flex>
    </button>
  )
}

const DotButton = ({
  onClick,
  selected,
  className,
}: {
  onClick: () => void
  selected: boolean
  className?: string
}) => {
  return (
    <button css={buttonReset} className={className} onClick={onClick}>
      <div
        css={[
          css`
            width: 15px;
            height: 15px;
            border-radius: 8px;
            background-color: transparent;
            margin: 4px;
            border: 1px solid white;
          `,
          selected &&
            css`
              background-color: white;
            `,
        ]}
      />
    </button>
  )
}
