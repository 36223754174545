import { formatDate } from '../../utils/date'
import { RewardFrequency } from '../League/queries'
import { WinnerItem } from './queries'

export function convertDate(date: Date): Date {
  // If the date is already a Date object, return it.
  try {
    date.getTime()
    return date
  } catch (error) {
    // If the date is a number, convert it to a Date object.
    return new Date(parseInt(date.toString()))
  }
}

export function getMonthsFromDate(
  fromDate: Date,
  previousWinners: WinnerItem[]
): { label: string; value: Date }[] {
  const now = new Date()
  const months: { label: string; value: Date }[] = []

  fromDate = new Date(fromDate)

  while (fromDate <= now) {
    if (
      previousWinners.some(
        (winner) =>
          convertDate(winner.wonDate).getMonth() === fromDate.getMonth() &&
          convertDate(winner.wonDate).getFullYear() === fromDate.getFullYear()
      )
    ) {
      fromDate.setMonth(fromDate.getMonth() + 1) // Move to next month
      continue
    }
    const label = formatDate(fromDate, 'MMMM yyyy')
    months.push({ label, value: new Date(fromDate) })
    fromDate.setMonth(fromDate.getMonth() + 1) // Move to next month
  }
  return months
}

export function getWeeksFromDate(
  fromDate: Date,
  previousWinners: WinnerItem[]
): { label: string; value: Date }[] {
  const now = new Date()
  const currentWeek = getWeekNumber(now)
  const weeks: { label: string; value: Date }[] = []

  fromDate = new Date(fromDate)
  //Convert to UTC to avoid issues with timezones
  fromDate = new Date(
    Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())
  )

  while (getWeekNumber(fromDate) <= currentWeek) {
    if (
      previousWinners.some(
        (winner) =>
          getWeekNumber(convertDate(winner.wonDate)) ===
            getWeekNumber(fromDate) &&
          convertDate(winner.wonDate).getFullYear() === fromDate.getFullYear()
      )
    ) {
      fromDate.setDate(fromDate.getDate() + 7) // Move to the next week
      continue
    }
    const weekNumber = getWeekNumber(fromDate)
    const label = `Week ${weekNumber} ${fromDate.getFullYear()}`
    weeks.push({ label, value: new Date(fromDate) })
    fromDate.setDate(fromDate.getDate() + 7) // Move to the next week
  }
  return weeks
}

export function getYearsFromDate(
  fromDate: Date
): { label: string; value: Date }[] {
  const now = new Date()
  const years: { label: string; value: Date }[] = []

  while (fromDate.getFullYear() <= now.getFullYear()) {
    const label = `${fromDate.getFullYear()}`
    years.push({ label, value: new Date(fromDate) })
    fromDate.setFullYear(fromDate.getFullYear() + 1) // Move to the next year
  }

  return years
}

function getWeekNumber(date: Date): number {
  const start = new Date(date.getFullYear(), 0, 1)
  const diff =
    date.getTime() -
    start.getTime() +
    (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000
  const oneDay = 1000 * 60 * 60 * 24
  return Math.floor(diff / oneDay / 7) + 1
}

export function getStartAndEndDateOfPeriod(
  rewardFrequency: RewardFrequency,
  selectedPeriod: Date
) {
  switch (rewardFrequency) {
    case RewardFrequency.once:
      return {
        startDate: undefined,
        endDate: undefined,
      }
    case RewardFrequency.weekly:
      return {
        //First day of the current week
        startDate: new Date(
          Date.UTC(
            selectedPeriod.getFullYear(),
            selectedPeriod.getMonth(),
            selectedPeriod.getDate() - selectedPeriod.getDay()
          )
        ),
        //Last day of the current week
        endDate: new Date(
          Date.UTC(
            selectedPeriod.getFullYear(),
            selectedPeriod.getMonth(),
            selectedPeriod.getDate() - selectedPeriod.getDay() + 6
          )
        ),
      }
    case RewardFrequency.monthly:
      return {
        //First day of the current month
        startDate: new Date(
          selectedPeriod.getFullYear(),
          selectedPeriod.getMonth(),
          1
        ),
        //Last day of the current month
        endDate: new Date(
          selectedPeriod.getFullYear(),
          selectedPeriod.getMonth() + 1,
          0
        ),
      }
    case RewardFrequency.yearly:
      return {
        //First day of the current year
        startDate: new Date(selectedPeriod.getFullYear(), 0, 1),
        //Last day of the current year
        endDate: new Date(selectedPeriod.getFullYear(), 11, 31),
      }
    default:
      return {
        startDate: new Date(),
        endDate: new Date(),
      }
  }
}

export type TimeRemaining = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function datePlus(
  date: Date,
  arg: {
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
  }
) {
  const days = arg.days ?? 0
  const hours = arg.hours ?? 0
  const minutes = arg.minutes ?? 0
  const seconds = arg.seconds ?? 0
  const copyDate = new Date(date)
  copyDate.setSeconds(
    copyDate.getSeconds() +
      days * 24 * 60 * 60 +
      hours * 60 * 60 +
      minutes * 60 +
      seconds
  )
  return copyDate
}

export function calculateTimeRemaining(start: Date, end: Date): TimeRemaining {
  // Calculate the difference in milliseconds
  let diff = Math.max(end.getTime() - start.getTime(), 0)

  // Convert milliseconds to days, hours, minutes, and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  diff -= days * 1000 * 60 * 60 * 24
  const hours = Math.floor(diff / (1000 * 60 * 60))
  diff -= hours * 1000 * 60 * 60
  const minutes = Math.floor(diff / (1000 * 60))
  diff -= minutes * 1000 * 60
  const seconds = Math.floor(diff / 1000)

  return { days, hours, minutes, seconds }
}

export function calculateTimeRemainingClock(start: Date, end: Date): string {
  // Calculate the difference in milliseconds
  let diff = Math.max(end.getTime() - start.getTime(), 0)

  // Convert milliseconds to days, hours, minutes, and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  diff -= days * 1000 * 60 * 60 * 24
  const hours = Math.floor(diff / (1000 * 60 * 60))
  diff -= hours * 1000 * 60 * 60
  const minutes = Math.floor(diff / (1000 * 60))
  diff -= minutes * 1000 * 60
  const seconds = Math.floor(diff / 1000)

  return timeRemainingToStringClock({ days, hours, minutes, seconds })
}

export function noTimeRemaining(timeRemaining: TimeRemaining) {
  return (
    timeRemaining.days === 0 &&
    timeRemaining.hours === 0 &&
    timeRemaining.minutes === 0 &&
    timeRemaining.seconds === 0
  )
}

export function timeRemainingToString(timeRemaining: TimeRemaining, t: any) {
  const { days, hours, minutes, seconds } = timeRemaining

  if (days > 0) {
    return t('{{days}}d {{hours}}h {{minutes}}m {{seconds}}s', {
      days,
      hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    })
  }

  if (hours > 0) {
    return t('{{hours}}h {{minutes}}m {{seconds}}s', {
      hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    })
  }

  if (minutes > 0) {
    return t('{{minutes}}m {{seconds}}s', {
      minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    })
  }

  return t('{{seconds}}s', {
    seconds,
  })
}

export function timeRemainingToStringClock(timeRemaining: TimeRemaining) {
  const {
    days: _days,
    hours,
    minutes: _minutes,
    seconds: _seconds,
  } = timeRemaining
  const days = _days < 1 ? `` : `${_days}d `
  const minutes = _minutes < 10 ? `0${_minutes}` : _minutes
  const seconds = _seconds < 10 ? `0${_seconds}` : _seconds

  return `${days}${hours}:${minutes}:${seconds}`
}

export const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const colorIsLight = (color: string) => {
  const hex = color.replace('#', '')
  const r = parseInt(hex.substring(0, 0 + 2), 16)
  const g = parseInt(hex.substring(2, 2 + 2), 16)
  const b = parseInt(hex.substring(4, 4 + 2), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 155
}

export function stringOrUndefined(value: unknown): string | undefined {
  if (typeof value !== 'string') {
    return undefined
  }
  return value
}
