/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { SelectInput } from '../../../components/SelectInput'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { buttonReset } from '../../../styles/styles'
import { colors, hexOpacity } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { LeaderboardItem } from '../../League/queries'
import { ChallengeLeague, useLeagueLeaderboardQuery } from '../queries'

export const ChallengeLeagueLeaderboard = ({
  league,
  className,
  limit,
}: {
  league: ChallengeLeague
  className?: string
  limit?: number
}) => {
  const queryLimit = limit ?? 100
  const [canFetchMore, setCanFetchMore] = useState(true)
  const { authUser } = useAuthContext()

  const [offset, setOffset] = useState(0)

  const [results, setResults] = useState<LeaderboardItem[]>([])

  const [leaderboardVariant, setLeaderboardVariant] = useState<
    'allTime' | 'thisWeek' | 'lastWeek' | 'thisRound' | 'lastRound'
  >(league.hasRounds ? 'thisRound' : 'thisWeek')

  const t = useT()

  const { data: leaderboardData, loading } = useLeagueLeaderboardQuery({
    groupId: league.id,
    variant: leaderboardVariant,
    limit: queryLimit,
    offset: offset,
  })

  useEffect(() => {
    setCanFetchMore(true)
    setResults([])
  }, [leaderboardVariant])

  useEffect(() => {
    if (!leaderboardData) return

    setResults((prevResults) => [
      ...prevResults,
      ...leaderboardData.leagueLeaderboard,
    ])

    if (leaderboardData.leagueLeaderboard.length < queryLimit) {
      setCanFetchMore(false)
    }
  }, [leaderboardData])

  const frequencyOptions = league.hasRounds ? (
    <>
      <option value="thisRound">{'This round'}</option>
      <option value="lastRound">{'Last round'}</option>
    </>
  ) : (
    <>
      <option value="thisWeek">{'This week'}</option>
      <option value="lastWeek">{'Last week'}</option>
    </>
  )

  const viewerLeaderboardEntry = leaderboardData?.viewerLeagueLeaderboardEntry

  const placeholders = Array.from(
    { length: Math.max(league.memberCount, 20) },
    (_, i) => i
  )

  return (
    <Flex className={className} column>
      <Flex
        horizontal="space-between"
        vertical="center"
        css={css`
          width: 100%;
        `}
      >
        <TextNew
          condensed
          strong
          color={league.textColor}
          css={css`
            font-size: 20px;
            line-height: 24px;
            font-style: italic;
          `}
        >
          {viewerLeaderboardEntry ? t(`Your position`) : t('Leaderboard')}
        </TextNew>
        <Flex gap="tiny">
          <button
            css={buttonReset}
            disabled={leaderboardVariant !== 'allTime'}
            onClick={
              leaderboardVariant === 'allTime'
                ? (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setLeaderboardVariant('thisRound')
                  }
                : undefined
            }
          >
            <SelectInput
              value={leaderboardVariant}
              ariaLabel={t(`Leaderboard frequency`)}
              selectCss={css`
                background-color: ${colors.black};
                border-radius: 50px 0 0 50px;
                border: none;

                text-decoration: ${leaderboardVariant === 'allTime'
                  ? 'none'
                  : 'underline'};
                font-style: italic;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                font-family: acumin-variable, sans-serif;
                letter-spacing: 0em;
                font-variation-settings: 'wght' 500, 'wdth' 70;
                margin-right: 4px;
              `}
              onValue={(value) => setLeaderboardVariant(value)}
              textColor={hexOpacity(
                colors.white,
                leaderboardVariant === 'allTime' ? 0.66 : 1
              )}
            >
              {frequencyOptions}
            </SelectInput>
          </button>
          <button
            onClick={() => setLeaderboardVariant('allTime')}
            css={[
              buttonReset,
              css`
                display: flex;
                align-items: center;
                background-color: ${colors.black};
                border-radius: 0 50px 50px 0;
                padding: 0 8px;
              `,
            ]}
          >
            <TextNew
              condensed
              strong
              color={hexOpacity(
                colors.white,
                leaderboardVariant === 'allTime' ? 1 : 0.66
              )}
              css={css`
                font-size: 20px;
                line-height: 24px;
                font-style: italic;
                margin-right: 0px;
                margin-bottom: 2px;
                text-decoration: ${leaderboardVariant === 'allTime'
                  ? 'underline'
                  : 'none'};
              `}
            >
              {t('Total')}
            </TextNew>
          </button>
        </Flex>
      </Flex>
      <Spacer height={8} />
      {viewerLeaderboardEntry && (
        <LeaderboardItemComponent
          item={viewerLeaderboardEntry}
          isAuthUser={true}
          index={-1}
        />
      )}
      <Spacer height={16} />
      {viewerLeaderboardEntry && (
        <>
          <TextNew
            condensed
            strong
            color={league.textColor}
            css={css`
              font-size: 20px;
              line-height: 24px;
              font-style: italic;
            `}
          >
            {t(`Top players`)}
          </TextNew>
          <Spacer height={8} />
        </>
      )}
      {loading && (
        <>
          {placeholders.slice(0, 20).map((_, index) => (
            <LoadingPlaceholderComponent key={`placeholder_${index}`} />
          ))}
        </>
      )}

      {results.map((result, index) => {
        const isAuthUser = authUser?.uid === result.user.id

        return (
          <LeaderboardItemComponent
            key={result.user.id}
            item={result}
            isAuthUser={isAuthUser}
            index={index}
          />
        )
      })}

      {canFetchMore && !loading && (
        <SecondaryButton
          onClick={() => {
            setOffset(offset + queryLimit)
          }}
          css={css`
            margin-top: 8px;
            border-color: ${league.textColor};
            border-radius: 50px;
          `}
        >
          <TextNew italic size={'small'} color={league.textColor}>
            {t(`Show more`)}
          </TextNew>
        </SecondaryButton>
      )}
    </Flex>
  )
}

const LoadingPlaceholderComponent = () => {
  return (
    <div
      css={css`
        width: 100%;
        background-color: ${hexOpacity(colors.white, 0.9)};
        border-radius: 50px;
        padding-right: 16px;
        padding-left: 16px;
        height: 32px;
        margin-bottom: 3px;
      `}
    />
  )
}

export const LeaderboardItemComponent = ({
  item,
  isAuthUser,
  index,
  height = 32,
}: {
  item: LeaderboardItem
  isAuthUser: boolean
  index: number
  height?: number
}) => {
  const t = useT()
  return (
    <Flex
      key={`${item.user.id}-${item.rank}-${index}`}
      vertical="center"
      horizontal="space-between"
      css={css`
        width: 100%;
        background-color: ${isAuthUser
          ? colors.yellow200
          : index % 2 === 0
          ? colors.white
          : hexOpacity(colors.white, 0.9)};
        border-radius: 50px;
        padding-right: 16px;
        padding-left: 12px;
        height: ${height}px;
        margin-bottom: 3px;
      `}
    >
      <Flex
        vertical="center"
        css={css`
          @media (max-width: 400px) {
            max-width: 85%;
          }
        `}
      >
        <Flex
          horizontal="center"
          css={css`
            min-width: 24px;
          `}
        >
          <TextNew
            extraStrong
            condensed
            strong
            css={css`
              font-size: 24px;
              line-height: 24px;
              font-style: italic;
            `}
          >
            {item.rank}
          </TextNew>
        </Flex>

        <Spacer width={item.rank > 99 ? 12 : 8} />
        <UserAvatar
          avatarData={item.user.avatarData}
          height={height}
          clickable
        />
        <Spacer width={12} />
        <TextNew
          strong
          italic
          textAlign="left"
          css={css`
            width: 100%;
            padding: 0 8px;
            font-size: 18px;
            line-height: 21px;
            font-style: italic;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 0.08%;
            white-space: nowrap;
          `}
        >
          {isAuthUser
            ? item.user.displayName ?? t('You')
            : item.user.displayName}
        </TextNew>
      </Flex>

      <TextNew
        condensed
        strong
        italic
        textAlign="center"
        css={css`
          font-size: 21px;
          line-height: 24px;
          font-style: italic;
          padding-bottom: 2px;
        `}
      >
        {item.score}
      </TextNew>
    </Flex>
  )
}
