/** @jsxImportSource @emotion/react */

import { css, Global } from '@emotion/react'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { useNotificationContext } from '../../lib/NotificationContext'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { isAndroid } from '../../utils/browser'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ProfileBanner } from '../../views/Home/ProfileBanner'
import {
  AppStoreButton,
  DownloadButtons,
  PlayStoreButton,
} from '../DownloadButtons'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { MaintenanceMessage } from '../MaintenanceMessage'
import { Navbar } from '../Navbar'
import { ScrollToTop } from '../ScrollToTop'
import { Text } from '../Text'
import { Flex } from './Flex'
import { PageContent } from './PageContent'

type Props = {
  children?: ReactNode
  title?: string
  withHeader?: boolean
  withFooter?: boolean
  backgroundColor?: string
  hideNavbar?: boolean
  showMaintenanceMessage?: boolean
  className?: string
  showDownLoadButtons?: boolean
  showCookiebotButton?: boolean
  profileBanner?: boolean
  hideAvatar?: boolean
  hideHeaderMobile?: boolean
  showFooterDownloadButtons?: boolean
  headerForegroundColor?: string
  hideHeaderPadding?: boolean
}

export const Layout = ({
  children,
  title = 'fcQuiz',
  withHeader = false,
  withFooter = false,
  backgroundColor = 'white',
  hideNavbar,
  showMaintenanceMessage,
  className,
  showDownLoadButtons = false,
  showCookiebotButton = false,
  profileBanner = false,
  hideAvatar = false,
  hideHeaderMobile = false,
  showFooterDownloadButtons = false,
  headerForegroundColor,
  hideHeaderPadding = false,
}: Props) => {
  useDocumentTitle(title)
  const { loading } = useAuthContext()

  const { refreshNotifications } = useNotificationContext()

  useEffect(() => {
    async function showHideCookiebotButton() {
      await new Promise((resolve) => setTimeout(resolve, 300))
      if (showCookiebotButton) {
        document
          .getElementById('CookiebotWidget')
          ?.style.setProperty('display', 'block')
      } else {
        document
          .getElementById('CookiebotWidget')
          ?.style.setProperty('display', 'none')
      }
    }
    showHideCookiebotButton()
  }, [showCookiebotButton])

  const location = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => {
      refreshNotifications()
    }, 650)

    return () => clearTimeout(timer)
  }, [location.pathname])

  return (
    <>
      <ScrollToTop />

      <Flex
        column
        css={css`
          position: relative;
          min-height: 100vh;
        `}
        className={className}
      >
        <AndroidTopPadding />
        <BodyBackgroundColor backgroundColor={backgroundColor} />

        {showDownLoadButtons && <DownloadButtons insideProfileBanner={false} />}

        {loading ? (
          <div />
        ) : (
          <>
            {withHeader && (
              <Header
                hideAvatar={hideAvatar}
                headerForegroundColor={headerForegroundColor}
                css={
                  hideHeaderMobile &&
                  css`
                    @media (max-width: 599px) {
                      display: none;
                    }
                  `
                }
              />
            )}
            {profileBanner && <ProfileBanner />}

            {!hideHeaderPadding && <Spacer height={24} />}
            {showMaintenanceMessage && (
              <>
                <MaintenanceMessage />
                <Spacer height={24} />
              </>
            )}

            <Flex column grow>
              {children}
            </Flex>

            {showFooterDownloadButtons && <DownloadAppFooter />}

            {withFooter && <Footer />}
            {(withFooter || !hideNavbar) && <Spacer height={64} />}
            {!hideNavbar && <Navbar />}
          </>
        )}
      </Flex>
    </>
  )
}

export const BodyBackgroundColor = ({
  backgroundColor,
}: {
  backgroundColor: string
}) => (
  <Global
    styles={css`
      body {
        background-color: ${backgroundColor};
      }
    `}
  />
)

const AndroidTopPadding = () => {
  const { isNativeApp } = useNativeApp()
  const [isAndroidNativeApp, setIsAndroidNativeApp] = useState(false)
  useEffect(
    () => setIsAndroidNativeApp(isNativeApp && isAndroid()),
    [isNativeApp]
  )

  return (
    // Approximately same height as status bar
    // TODO: Pass device info (eg. OS: 'ios' | 'android') as part of native bridge (inside NativeAppContext)
    <div
      css={css`
        transition: height 50ms ease-in-out;
        height: ${isAndroidNativeApp ? 30 : 0}px;
      `}
    />
  )
}

const DownloadAppFooter = () => {
  const t = useT()

  const { isNativeApp } = useNativeApp()

  if (isNativeApp) {
    return null
  }

  const backgroundColor = colors.white

  const text = t('Download the fcQuiz app').replace(':', '')

  const trackingButtonPlacement = 'footer'

  return (
    <PageContent>
      <Flex
        column
        horizontal="center"
        gap="medium"
        css={css`
          background-color: ${backgroundColor};
          border-radius: 8px;
          padding: 16px 0px;
          overflow: hidden;
        `}
      >
        <Text size="huge" strong>
          {text}
        </Text>
        <Flex
          column
          css={css`
            display: none;

            @media (max-width: 599px) {
              display: flex;
            }
          `}
        >
          <AppStoreButton
            fitAndroidButtonWidth
            fitAndroidButtonHeight
            scale={1.8}
            buttonPlacement={trackingButtonPlacement}
          />
          <PlayStoreButton
            scale={1.8}
            buttonPlacement={trackingButtonPlacement}
          />
        </Flex>
        <Flex
          css={css`
            display: flex;
            @media (max-width: 599px) {
              display: none;
            }
          `}
        >
          <AppStoreButton scale={1} buttonPlacement={trackingButtonPlacement} />
          <PlayStoreButton
            scale={1}
            buttonPlacement={trackingButtonPlacement}
          />
        </Flex>
      </Flex>
    </PageContent>
  )
}
