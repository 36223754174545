/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useEffect, useState } from 'react'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  CompletedCareerPathQuestion,
  LeagueQuiz as LeagueQuizType,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../Button'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import {
  CareerPathQuestionAnswer,
  GuessIndicator,
} from './ActiveCareerPathQuestion'
import CareerPath from './CareerPath'
import { CareerPathQuizStatusComponent } from './CareerPathQuizStatusComponent'
import { TextNew } from '../TextNew'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'

export const LastCompletedCareerPathQuestion: FC<{
  leagueQuiz: LeagueQuizType
  completedQuestions: CompletedCareerPathQuestion[]
  lastCompletedQuestion: CompletedCareerPathQuestion
  allQuestionsAnswered: boolean
  triggerCalculation: () => void
  nextQuestionLoading: boolean
  nextQuestion: () => void
  isEmbed: boolean
  clubLeagueSubscriptionId?: string
}> = ({
  leagueQuiz,
  completedQuestions,
  lastCompletedQuestion,
  allQuestionsAnswered,
  triggerCalculation,
  nextQuestionLoading,
  nextQuestion,
  isEmbed,
}) => {
  const t = useT()
  const { campaignHeaderHeight } = useLeagueQuizContext()
  const correct = lastCompletedQuestion.answers.some((answer) => answer.correct)

  const [timeSpent, setTimeSpent] = useState(0)

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId: leagueQuiz.league.id,
        leagueQuizId: leagueQuiz.id,
        isEmbed,
        isCareerPath: true,
      })
    }
  }, [allQuestionsAnswered, leagueQuiz.id, leagueQuiz.league.id])

  useEffect(() => {
    const startedAt = new Date(
      parseInt(lastCompletedQuestion.answers[0].startedAt, 10)
    )
    const completedAt = new Date(
      parseInt(
        lastCompletedQuestion.answers[lastCompletedQuestion.answers.length - 1]
          .answeredAt,
        10
      )
    )
    const diff = completedAt.getTime() - startedAt.getTime()
    setTimeSpent(Math.floor(diff / 1000))
  }, [lastCompletedQuestion.answers])

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Spacer height={isEmbed ? 8 : 'medium'} />
        <CareerPathQuizStatusComponent
          currentQuestion={completedQuestions.length}
          totalQuestions={leagueQuiz.questionsCount}
          timeSpent={timeSpent}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />

        <CareerPathQuestionResult
          completedQuestion={lastCompletedQuestion}
          correct={correct}
        />

        <Spacer height={'medium'} />

        <CareerPath
          careerPath={lastCompletedQuestion.careerPath}
          showYear={true}
          lastCompleted={true}
        />

        <Spacer height={'medium'} />

        <GuessIndicator
          answers={lastCompletedQuestion.answers}
          activeQuestion={lastCompletedQuestion}
        />

        <Flex
          column
          css={[
            margin.top(isEmbed ? 'small' : 'large'),
            css`
              opacity: 0;
              width: 100%;
              padding: 0 50px;
              animation: ${fadeIn} 600ms ease-out;
              animation-delay: 2000ms;
              animation-fill-mode: forwards;
            `,
          ]}
        >
          {allQuestionsAnswered ? (
            <PrimaryButton
              onClick={() => triggerCalculation()}
              css={css`
                background-color: black;
                color: white;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 38px;
              `}
            >
              <TextNew
                color={'white'}
                condensed
                size={'massive'}
                italic
                spacingEm={0}
              >
                {t('Finish')}
              </TextNew>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={nextQuestionLoading}
              loading={nextQuestionLoading}
              onClick={nextQuestion}
              css={css`
                background-color: black;
                color: white;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 38px;
              `}
            >
              <TextNew
                color={'white'}
                condensed
                italic
                spacingEm={0}
                size={'massive'}
              >
                {t(`Next Player`)}
              </TextNew>
            </PrimaryButton>
          )}
        </Flex>

        <Spacer height={isEmbed ? 8 : 'medium'} />

        <Flex
          column
          gap={'small'}
          horizontal={'center'}
          css={css`
            width: 100%;
          `}
        >
          {lastCompletedQuestion.answers
            .filter((a) => a.playerId && a.playerId !== 'skipped')
            .sort(
              // Latest answers first
              (a, b) => parseInt(b.startedAt, 10) - parseInt(a.startedAt, 10)
            )
            .map((answer) => (
              <CareerPathQuestionAnswer
                key={answer.playerId}
                playerId={answer.playerId}
                playerName={answer.playerName}
                photoUrl={answer.photoUrl}
                nationality={answer.nationality}
                position={answer.position}
                team={answer.team}
                correct={answer.correct}
                correctNationality={lastCompletedQuestion.nationality}
                correctTeamId={lastCompletedQuestion.team.id}
                correctLeagueId={lastCompletedQuestion.team.league?.id}
                correctPosition={lastCompletedQuestion.position}
              />
            ))}
        </Flex>
        <Spacer height={'huge'} />
      </Flex>
    </PageContent>
  )
}

export const CareerPathQuestionResult: FC<{
  completedQuestion: CompletedCareerPathQuestion
  correct: boolean
}> = ({ completedQuestion, correct }) => {
  return (
    <Flex
      horizontal={'center'}
      css={css`
        padding: 8px 0;
        border-radius: 8px;
        margin-bottom: 16px;
        width: 100%;
        position: relative;
        height: 80px;
        overflow-y: visible;
        top: -40px;
      `}
    >
      <Flex
        horizontal={'center'}
        column
        gap={'medium'}
        css={css`
          flex: 1;
          align-items: center;
        `}
      >
        {/*
          <TextNew condensed italic spacingEm={0} size={'large'} strong>
            {t(`The correct player is`)}
          </TextNew>
          <TextNew condensed italic spacingEm={0} size={'huge'} strong>
          {correct ? t(`Congratulations!`) : t(`Better luck next time!`)}
        </TextNew>
        */}

        <Flex column horizontal={'center'}>
          <img
            width={100}
            height={100}
            src={completedQuestion.photo}
            css={css`
              border-radius: 50%;
              overflow: hidden;
              border: 10px solid ${correct ? colors.green300 : colors.red300};
            `}
          />
          <TextNew condensed italic spacingEm={0} size={'huge'} strong>
            {correct && '🎉 '} {completedQuestion.name} {correct && ' 🎉'}
          </TextNew>
        </Flex>
      </Flex>
    </Flex>
  )
}
