import { gql, useQuery } from '@apollo/client'
import { LeaderboardItem, leaderboardItemFragment } from '../League/queries'

export enum GlobalLeaderboardType {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export type GlobalLeaderboardInput = {
  type: GlobalLeaderboardType
  limit?: number
}

export const usersGlobalLeaderboardQuery = gql`
  query usersGlobalLeaderboard($input: GlobalLeaderboardInput) {
    usersGlobalLeaderboard(input: $input) {
      ...LeaderboardItem
    }
  }
  ${leaderboardItemFragment}
`

export const useUsersGlobalLeaderboardQuery = (
  input: GlobalLeaderboardInput,
  skip?: boolean
) => {
  return useQuery<{
    usersGlobalLeaderboard: LeaderboardItem[]
  }>(usersGlobalLeaderboardQuery, {
    variables: { input },
    skip,
  })
}

const friendsLeaderboardQuery = gql`
  query friendsLeaderboard($input: GlobalLeaderboardInput) {
    friendsLeaderboard(input: $input) {
      ...LeaderboardItem
    }
  }
  ${leaderboardItemFragment}
`

export const useFriendsLeaderboardQuery = (
  input: GlobalLeaderboardInput,
  skip?: boolean
) => {
  return useQuery<{
    friendsLeaderboard: LeaderboardItem[]
  }>(friendsLeaderboardQuery, {
    variables: { input },
    skip,
    fetchPolicy: 'cache-and-network',
  })
}
