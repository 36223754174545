/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { FaUserPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { CheckLarge } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext } from '../../lib/AuthContext'
import {
  Notification,
  notificationsQuery,
  useNotificationContext,
} from '../../lib/NotificationContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { formatDate } from '../../utils/date'
import { UserAvatar } from '../Avatar/UserAvatar'
import {
  FriendStatus,
  useAcceptFriendRequestMutation,
  userProfileQuery,
} from './queries'

enum NotificationType {
  friendRequest,
  friendRequestAccepted,
  unknown,
}

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const t = useT()
  const navigate = useNavigate()
  const fromUser = notification.fromUser
  const apolloClient = useApolloClient()

  const [acceptFriendRequest] = useAcceptFriendRequestMutation()
  const { refreshNotifications } = useNotificationContext()

  const notificationType = () => {
    if (!fromUser) {
      console.error('NotificationItem | no fromUser')
      return NotificationType.unknown
    }

    if (notification.friendshipId) {
      if (fromUser.friendStatus === FriendStatus.requestPending) {
        return NotificationType.friendRequest
      }
      if (fromUser.friendStatus === FriendStatus.friend) {
        return NotificationType.friendRequestAccepted
      }
    }

    return NotificationType.unknown
  }

  const type = notificationType()

  const ActionButton = () => {
    const buttonStyles = [
      buttonReset,
      css`
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
      `,
    ]

    switch (type) {
      case NotificationType.friendRequest:
        return (
          <button
            aria-label={t(`Accept friend request`)}
            css={buttonStyles}
            onClick={async () => {
              if (!fromUser) {
                return
              }

              await acceptFriendRequest({
                variables: { input: { userId: fromUser.id } },
              })

              trackEvent(AnalyticsEventType.FriendRequest, {
                type: 'Accepted',
              })

              await apolloClient.refetchQueries({
                include: [userProfileQuery, notificationsQuery],
              })

              await refreshNotifications()
            }}
          >
            <FaUserPlus size={32} color={colors.white} />
          </button>
        )
      case NotificationType.friendRequestAccepted:
        return (
          <div css={buttonStyles}>
            <CheckLarge color={colors.white} />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Flex
      horizontal={'space-between'}
      vertical={'center'}
      gap={'small'}
      css={css`
        padding: 16px;
        border-bottom: 1px solid ${colors.grey300};
        width: 100%;
      `}
    >
      {fromUser && (
        <button
          role="link"
          aria-label={t(`Go to profile`) + ` ${fromUser?.displayName}`}
          onClick={() => {
            navigate(`/profile/${fromUser.slug}`)
          }}
          css={[
            buttonReset,
            css`
              display: flex;
              align-items: center;
              gap: 8px;
            `,
          ]}
        >
          <UserAvatar avatarData={fromUser.avatarData} height={80} />
        </button>
      )}
      <Flex
        column
        vertical={'space-between'}
        css={css`
          height: 100%;
          width: 100%;
        `}
      >
        <Text size={'small'} color={colors.white}>
          {notification.message}
        </Text>
        <Text size={'tiny'} color={colors.grey300}>
          {formatDate(new Date(notification.createdAt))}
        </Text>
      </Flex>
      <ActionButton />
    </Flex>
  )
}

const Content = () => {
  const { notifications, setNotificationsAsSeen, refreshNotifications } =
    useNotificationContext()
  const t = useT()

  const { authUser, loading: authLoading } = useAuthContext()

  useEffect(() => {
    async function fetchNotifications() {
      await refreshNotifications()
      setNotificationsAsSeen()
    }
    fetchNotifications()
  }, [])

  if (authLoading) {
    return <Loader />
  }

  if (!authUser) {
    const reason = !authUser ? 'no authUser' : 'no userQuery.data'
    return <DefaultError sentryErrorMessage={reason} />
  }

  return (
    <Flex
      column
      css={css`
        margin: 0 auto;
      `}
    >
      <PageContent>
        <Text
          textAlign={'center'}
          italic
          strong
          color={colors.white}
          size={'huge'}
        >
          {t('Notifications')}
        </Text>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </PageContent>
    </Flex>
  )
}

export const UserNotificationsPage = () => {
  return (
    <Layout backgroundColor={colors.black}>
      <Content />
    </Layout>
  )
}
