/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn } from '../../../styles/animations'
import { Spacer } from '../../../styles/margin'
import { colors, hexOpacity } from '../../../styles/theme'
import { useNow } from '../../../utils/useNow'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { LeaderboardItem } from '../../League/queries'
import {
  ChallengeLeague,
  LeaderboardVariant,
  useLeagueLeaderboardQuery,
} from '../queries'

import {
  getParnerGraphicUrlForLeague,
  itemAvatarSpacingFromScreenSize,
  itemBottomMarginFromScreenSize,
  itemFontSizeFromScreenSize,
  itemHeightFromScreenSize,
  itemNumberFontSizeFromScreenSize,
  itemScoreMarginFromScreenSize,
  leaderboardHeaderHeightFromScreenSize,
  ScreenSize,
} from './util'

export const DisplayPageLeaderboard = ({
  league,
  screenSize,

  isLandscape = true,
  language,
}: {
  league: ChallengeLeague
  screenSize: ScreenSize
  isLandscape?: boolean
  language?: string
}) => {
  const t = useT(language)
  const queryLimit = 15

  const partnerGraphic = getParnerGraphicUrlForLeague(league)

  const leaderboardVariants: LeaderboardVariant[] = [
    league.hasRounds ? 'thisRound' : 'today', // Some leagues don't have rounds, but do have today
    league.hasRounds ? 'newestThisRound' : 'newestToday',
    'allTime',
  ]

  const screenStates: (LeaderboardVariant | 'partnerGraphic')[] = [
    ...leaderboardVariants,
  ]

  //Some leagues have partner graphics
  if (partnerGraphic) {
    screenStates.push('partnerGraphic')
  }

  const now = useNow({ updateFrequencyInMs: 1000 })

  const itemHeight = itemHeightFromScreenSize(screenSize)
  const itemBottomMargin = itemBottomMarginFromScreenSize(screenSize)

  const headerSize = leaderboardHeaderHeightFromScreenSize(screenSize)

  const [results, setResults] = useState<LeaderboardItem[]>([])

  const [screenState, setScreenState] = useState<
    LeaderboardVariant | 'partnerGraphic'
  >('thisRound')

  const { data: leaderboardData } = useLeagueLeaderboardQuery({
    groupId: league.id,
    variant: screenState === 'partnerGraphic' ? 'allTime' : screenState,
    limit: queryLimit,
    offset: 0,
  })

  const toggleLeaderboardVariant = () => {
    const currentIndex = screenStates.indexOf(screenState)

    if (currentIndex < screenStates.length - 1) {
      setScreenState(screenStates[currentIndex + 1])
    } else {
      setScreenState(screenStates[0])
    }
  }

  useEffect(() => {
    const seconds = now.getSeconds()

    if (seconds % 15 === 0) {
      toggleLeaderboardVariant()
    }
  }, [now.getSeconds()])

  useEffect(() => {
    setResults(leaderboardData?.leagueLeaderboard ?? [])
  }, [leaderboardData])

  const leaderboardTitle = () => {
    switch (screenState) {
      case 'thisRound':
        return t('Top players this round')
      case 'allTime':
        return t('Top players all time')
      case 'today':
        return t('Top players today')
      case 'thisWeek':
        return t('Top players this week')
      case 'newestToday':
        return t('Newest today')
      case 'newestThisRound':
        return t('Newest this round')
      default:
        return undefined
    }
  }

  const title = leaderboardTitle()

  return (
    <Flex
      column
      horizontal="flex-start"
      key={screenState}
      css={css`
        min-height: calc(
          ${itemHeight}px * ${queryLimit} + ${itemBottomMargin}px *
            ${queryLimit} + ${headerSize}px + 24px
        );

        max-width: ${isLandscape ? '100%' : '1000px'};

        width: 100%;
        margin: auto;
      `}
    >
      <Flex>
        {title && (
          <HeadingNew
            color={league.textColor}
            level={2}
            textAlign="center"
            italic
            condensed
            css={css`
              font-size: ${headerSize}px;
              line-height: 1;
              opacity: 0;
              animation: ${fadeIn} 650ms ease-out forwards;
            `}
          >
            {title}
          </HeadingNew>
        )}
      </Flex>

      {screenState !== 'partnerGraphic' && (
        <>
          {results.map((item, index) => (
            <LeaderboardItemComponent
              key={index}
              item={item}
              index={index}
              screenSize={screenSize}
            />
          ))}
        </>
      )}

      {screenState === 'partnerGraphic' && partnerGraphic && (
        <Flex
          horizontal="center"
          vertical="center"
          css={css`
            width: 100%;
            height: 80%;
            margin: auto;
          `}
        >
          <img
            src={partnerGraphic}
            css={css`
              width: 100%;
              height: 90%;
              object-fit: contain;
              animation: ${fadeIn} 350ms ease-out forwards;
            `}
          />
        </Flex>
      )}
    </Flex>
  )
}

const LeaderboardItemComponent = ({
  item,
  index,
  screenSize = '1080p',
}: {
  item: LeaderboardItem
  index: number
  screenSize: ScreenSize
}) => {
  const height = itemHeightFromScreenSize(screenSize)
  const fontSize = itemFontSizeFromScreenSize(screenSize)
  const numberFontSize = itemNumberFontSizeFromScreenSize(screenSize)
  const bottomMargin = itemBottomMarginFromScreenSize(screenSize)
  const scoreMargin = itemScoreMarginFromScreenSize(screenSize)
  const avatarSpacing = itemAvatarSpacingFromScreenSize(screenSize)

  const fontStyle = css`
    font-size: ${fontSize}px;
    line-height: 1.2;
    letter-spacing: -0.025em;
    font-weight: 700;
  `

  const numberFontStyle = css`
    font-size: ${numberFontSize}px;
    line-height: 1.2;
    letter-spacing: 0.025em;
    font-weight: 700;
  `

  const delayStep = 50

  return (
    <Flex
      vertical="center"
      horizontal="space-between"
      css={css`
        width: 100%;
        background-color: ${index % 2 === 0
          ? colors.white
          : hexOpacity(colors.white, 0.9)};
        border-radius: 50px;
        padding-right: 16px;
        padding-left: 16px;
        height: ${height}px;
        margin-bottom: ${bottomMargin}px;
        opacity: 0;
        animation: ${fadeIn} 650ms ease-out forwards;
        animation-delay: ${index * delayStep}ms;
      `}
    >
      <Flex
        vertical="center"
        css={css`
          @media (max-width: 400px) {
            max-width: 85%;
          }
        `}
      >
        <Flex
          horizontal="center"
          css={css`
            min-width: 24px;
            margin-left: ${scoreMargin}px;
          `}
        >
          <TextNew italic condensed strong css={[numberFontStyle]}>
            {item.rank}
          </TextNew>
        </Flex>

        <Spacer width={avatarSpacing} />
        <UserAvatar
          avatarData={item.user.avatarData}
          height={height}
          clickable
        />
        <Spacer width={avatarSpacing} />
        <TextNew
          italic
          textAlign="left"
          css={[
            css`
              width: 100%;
              padding: 0 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `,
            fontStyle,
          ]}
        >
          {item.user.displayName}
        </TextNew>
      </Flex>

      <TextNew
        italic
        strong
        condensed
        css={[
          css`
            margin-right: ${scoreMargin}px;
          `,
          numberFontStyle,
        ]}
      >
        {item.score}
      </TextNew>
    </Flex>
  )
}
