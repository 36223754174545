/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { MdLockOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useT } from '../lib/i18n/useT'
import { boxShadows, colors } from '../styles/theme'
import { Club } from '../views/Clubs/queries'
import { RightArrow, Verified } from './icons'
import { Flex } from './layout/Flex'
import { ClubLogo } from './Picture'
import { Text } from './Text'

export const ClubElement: FC<{
  club: Club
  isMember: boolean
  className?: string
}> = ({ club, isMember, className }) => {
  const t = useT()

  return (
    <Link
      to={`/clubs/${club.slug}`}
      css={css`
        text-decoration: none;
      `}
    >
      <Flex
        css={[
          css`
            padding: 20px;
            background-color: ${isMember ? colors.yellow100 : 'white'};
            border-radius: 2px;
            box-shadow: ${boxShadows.default};
          `,
        ]}
        horizontal="space-between"
        vertical="center"
        className={className}
      >
        <Flex vertical="center" gap="medium">
          <ClubLogo size={40} url={club.logoUrl} shape={club.logoShape} />
          <Flex column>
            <Flex vertical="center" gap="small">
              <Text>{club.name}</Text>
              <Flex vertical="center" gap="tiny">
                {club.verified && <Verified height={15} width={12} />}
                {!club.public && <MdLockOutline height={15} width={12} />}
              </Flex>
            </Flex>
            <Text size="small">
              {t('{{count}} member', { count: club.membersCount })}
            </Text>
          </Flex>
        </Flex>
        <RightArrow />
      </Flex>
    </Link>
  )
}
