/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'

import { HeadingNew } from '../../components/HeadingNew'
import { colors } from '../../styles/theme'
import { TextNew } from '../../components/TextNew'

export const AdTestPage = () => {
  return (
    <Layout backgroundColor={colors.green600} hideNavbar>
      <Flex column grow>
        <PageContent>
          <HeadingNew color={colors.white} level={1}>
            {'Ad Test'}
          </HeadingNew>
          <Flex horizontal="center" vertical="center" column gap={'large'}>
            {/* <div data-ad={'left-rail-1'} />
            <div data-ad={'left-rail-2'} />
            <div data-ad={'right-rail-1'} />
            <div data-ad={'right-rail-2'} /> */}
            <TextNew color="white">{'Custom 300x250'}</TextNew>
            <div data-ad="right-rail-1" data-ad-size="300x250" />

            <TextNew color="white">{'Custom 750x400'}</TextNew>
            <div data-ad="right-rail-2" data-ad-size="750x400" />

            <TextNew color="white">{'Custom 728x90'}</TextNew>
            <div data-ad="left-rail-1" data-ad-size="728x90" />

            <TextNew color="white">{'Custom 468x60'}</TextNew>
            <div data-ad="left-rail-2" data-ad-size="468x60" />

            <TextNew color="white">{'Custom 336x280'}</TextNew>
            <div data-ad="left-rail-2" data-ad-size="336x280" />
          </Flex>
        </PageContent>
      </Flex>
    </Layout>
  )
}
