/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { HeadingNew } from '../../components/HeadingNew'
import { ClubLogo } from '../../components/Picture'
import { AndroidOrIosButton } from '../../components/StoreButtons'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { FcQuizLogo, Logo, PoweredByFcQuiz } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { getIconAndTitle } from '../../utils/helperFunctions'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { EmbedTrackingComponent } from './EmbedTrackingComponent'
import { EmbedErrorPage, EmbedLoadingPage } from './SubPages'
import {
  useCurrentQuizFromSubscriptionIdQuery,
  usePublicClubLeagueQuery,
  usePublicClubQueryV2,
} from './queries'
import { EmbedCurrentQuiz, PublicClubInfo } from './types'

interface QueryParams {
  domain?: string
  quizType: string
}

const getSearchParams = (quizType: string, embedDomain?: string | null) => {
  let params: QueryParams = {
    quizType: quizType,
  }

  if (embedDomain) {
    params.domain = embedDomain
  }

  const validParams: Record<string, string> = {}
  Object.keys(params).forEach((key) => {
    if (params[key as keyof QueryParams]) {
      validParams[key] = params[key as keyof QueryParams]!
    }
  })

  return validParams
}

const Loader = ({
  clubId,
  clubLeagueSubscriptionId,
}: {
  clubId: string
  clubLeagueSubscriptionId: string
}) => {
  const navigate = useNavigate()
  const { authUser, signInAsAnonymous } = useAuthContext()
  const [loading, setLoading] = useState<boolean>(false)
  const publicClubQuery = usePublicClubQueryV2(clubId)
  const publicClub = publicClubQuery?.data?.publicClub

  const [startLeagueQuizMutation] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const currentQuizQuery = useCurrentQuizFromSubscriptionIdQuery(
    clubLeagueSubscriptionId
  )

  const currentQuiz = currentQuizQuery?.data?.currentQuizFromSubscriptionId

  useEffect(() => {
    if (!!authUser && currentQuiz?.viewerCanStartQuiz === false) {
      if (currentQuiz.viewerData?.activeQuizInstance) {
        navigate(
          `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${
            currentQuiz.viewerData?.activeQuizInstance.id
          }${
            currentQuiz.quizType === 'careerPath' ? '?quizType=careerPath' : ''
          }`
        )
      }
      if (currentQuiz?.viewerData?.hasCompletedQuiz) {
        const instanceId = currentQuiz.viewerData?.result.leagueQuizInstanceId
        // in some cases the user has completed the quiz but the quiz instance is undefined
        if (instanceId === undefined) {
          navigate(`/embed-error`)
          return
        }
        navigate(
          `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${instanceId}${
            currentQuiz.quizType === 'careerPath' ? '?quizType=careerPath' : ''
          }`
        )
      }
    }
  }, [authUser, currentQuiz?.viewerCanStartQuiz])

  if (publicClubQuery.loading) {
    return <EmbedLoadingPage />
  }

  if (publicClubQuery.error) {
    Sentry.captureException(
      `Error fetching publicClub, clubLeagueSubscriptionId: ${clubLeagueSubscriptionId}, clubId: ${clubId}, error: ${publicClubQuery.error}`
    )
    return (
      <EmbedErrorPage
        reason={`Error fetching publicClub for clubLeagueSubscriptionId: ${clubLeagueSubscriptionId}`}
        clubId={clubId}
      />
    )
  }

  if (!publicClub) {
    return (
      <EmbedErrorPage
        reason={`Could not find publicClub for clubLeagueSubscriptionId: ${clubLeagueSubscriptionId}`}
      />
    )
  }

  const onPlayClick = async () => {
    if (!currentQuiz) return
    if (!authUser) {
      await signInAsAnonymous()
    }

    if (currentQuiz.quizType === 'careerPath') {
      startCareerPathQuizMutation({
        variables: { input: { leagueQuizId: currentQuiz.id } },
      }).then((result) => {
        const quizInstanceId = result.data?.startCareerPathQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: currentQuiz.id,
            leagueId: currentQuiz.league.id,
            isCareerPath: true,
            isEmbed: true,
          })
          navigate({
            pathname: `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${quizInstanceId}`,
            search: `?${createSearchParams(
              getSearchParams(currentQuiz.quizType ?? 'text')
            )}`,
          })
          setLoading(false)
        }
      })
      return
    }

    startLeagueQuizMutation({
      variables: { input: { leagueQuizId: currentQuiz.id } },
    })
      .then((result) => {
        const quizInstanceId = result.data?.startLeagueQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: currentQuiz.id,
            leagueId: currentQuiz.league.id,
            clubLeagueSubscriptionId,
            isEmbed: true,
          })
          navigate({
            pathname: `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${quizInstanceId}`,
            search: `?${createSearchParams(
              getSearchParams(currentQuiz.quizType ?? 'text')
            )}`,
          })
          setLoading(false)
        }
      })
      .catch(() => {
        navigate(`/embed-error`)
        setLoading(false)
      })
  }

  const hasAdContent = currentQuiz?.campaign

  if (hasAdContent) {
    return (
      <ContentWithAd
        publicClub={publicClub}
        currentQuiz={currentQuiz}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        onPlayClick={onPlayClick}
        loading={loading}
        setLoading={setLoading}
      />
    )
  }

  return (
    <Content
      publicClub={publicClub}
      currentQuiz={currentQuiz}
      clubLeagueSubscriptionId={clubLeagueSubscriptionId}
      onPlayClick={onPlayClick}
      loading={loading}
      setLoading={setLoading}
    />
  )
}

const Content = ({
  publicClub,
  currentQuiz,
  clubLeagueSubscriptionId,
  onPlayClick,
  loading,
  setLoading,
}: {
  publicClub: PublicClubInfo
  currentQuiz: EmbedCurrentQuiz | undefined
  clubLeagueSubscriptionId: string
  onPlayClick: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
}) => {
  const t = useT()

  const { icon: leagueIcon, title: leagueTitle } = getIconAndTitle(
    currentQuiz?.league?.title
  )

  const backgroundImageUrl = currentQuiz?.imageUrl

  return (
    <Flex
      column
      grow
      css={[
        css`
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          background-color: white;
          padding-bottom: 20px;
        `,
        backgroundImageUrl &&
          css`
            background-image: url(${backgroundImageUrl});
            background-size: cover;
            background-position: center;
          `,
      ]}
    >
      <Flex
        column
        vertical="space-between"
        horizontal="center"
        grow
        css={css`
          width: 100%;
          height: 100%;
          min-height: 264px;
          z-index: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          padding-top: 40px;
        `}
      >
        <FcQuizLogo
          height={13}
          color={backgroundImageUrl ? colors.white : colors.black}
        />
        <Flex
          column
          css={css`
            display: flex;
            padding: 30px 25px;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin: 0 auto;
            background-color: white;
            max-width: 275px;
            border-radius: 4px;
          `}
        >
          <p
            css={css`
              font-size: 30px;
              padding-bottom: 10px;
            `}
          >
            {leagueIcon ?? null}
          </p>
          <Text
            size="small"
            css={css`
              text-align: center;
            `}
          >
            {currentQuiz?.league?.title ?? leagueTitle}
          </Text>
          <Heading
            level={3}
            looksLikeLevel={5}
            css={css`
              text-align: center;
              line-height: 1.2;
              font-size: 20px;
              padding-top: 15px;
            `}
          >
            {currentQuiz?.embedPromotionalText ?? currentQuiz?.title}
          </Heading>
          <Spacer height={16} />
          <EmbedTrackingComponent
            eventType={AnalyticsEventType.EmbedLandingpage}
            eventData={{
              clubLeagueSubscriptionId,
              clubId: publicClub.id,
              leagueId: currentQuiz?.league?.id,
              quizId: currentQuiz?.id,
              campaignId: currentQuiz?.campaign?.id,
            }}
          />
          {currentQuiz && currentQuiz.league ? (
            <PrimaryButton
              disabled={loading}
              loading={loading}
              onClick={() => {
                setLoading(true)
                onPlayClick()
              }}
              css={[
                css`
                  white-space: nowrap;
                  max-width: 300px;
                  width: 100%;
                  margin: 0 auto;
                  height: 34px;
                  padding: 0;
                `,
              ]}
            >
              {t('Play quiz')}
            </PrimaryButton>
          ) : (
            <a
              href={`/clubs/${publicClub.id}?clubLeagueSubscriptionId=${clubLeagueSubscriptionId}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
            >
              <PrimaryButton
                css={css`
                  background-color: black;
                  color: white;
                  width: fit-content;
                  padding: 5px 20px;
                  margin: 10px auto;
                  margin-bottom: 0;
                `}
              >
                {t('Play in the app!')}
              </PrimaryButton>
            </a>
          )}
        </Flex>
        <Flex
          horizontal="space-between"
          css={css`
            margin-top: 10px;
            width: 100%;
            background-color: white;
            height: 82px;
            display: flex;
            align-items: center;
            padding: 0 20px;
          `}
        >
          <Flex>
            <ClubLogo size={40} url={publicClub.logoUrl} />
            <Logo
              color="black"
              size={40}
              css={css`
                margin-left: 10px;
              `}
            />
          </Flex>
          <AndroidOrIosButton />
        </Flex>
      </Flex>
      <PoweredByFcQuiz />
    </Flex>
  )
}

const ContentWithAd = ({
  publicClub,
  currentQuiz,
  clubLeagueSubscriptionId,
  onPlayClick,
  loading,
  setLoading,
}: {
  publicClub: PublicClubInfo
  currentQuiz: EmbedCurrentQuiz | undefined
  clubLeagueSubscriptionId: string
  onPlayClick: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
}) => {
  const t = useT()

  const { title: leagueTitle } = getIconAndTitle(currentQuiz?.league?.title)

  const campaign = currentQuiz?.campaign

  const backgroundImageUrl = currentQuiz?.imageUrl

  return (
    <Flex
      column
      grow
      css={[
        css`
          width: 100%;
          height: 100vh;
          align-items: center;
        `,
        backgroundImageUrl &&
          css`
            background-image: url(${backgroundImageUrl});
            background-size: cover;
            background-position: center;
          `,
      ]}
    >
      <Flex
        horizontal="flex-end"
        css={css`
          width: 100%;
          padding: 20px;
        `}
      >
        <FcQuizLogo
          height={15}
          width={69}
          color={backgroundImageUrl ? colors.white : colors.black}
        />
      </Flex>

      <Flex column vertical="center" grow>
        <Flex
          column
          vertical="center"
          horizontal="center"
          grow
          css={css`
            max-width: 380px;
            background-color: white;
            border-radius: 4px;
          `}
        >
          <Flex
            column
            vertical="center"
            css={css`
              padding: 8px 16px 12px;
            `}
          >
            <HeadingNew
              level={3}
              italic
              condensed
              css={css`
                text-align: center;
                line-height: 0.8;
                font-size: 39px;
                line-height: 30px;
                letter-spacing: 0em;
              `}
            >
              {currentQuiz?.title ?? leagueTitle}
            </HeadingNew>
          </Flex>

          {currentQuiz?.embedPromotionalText && (
            <TextNew
              strong
              css={css`
                letter-spacing: 0.01em;
                text-align: center;
                font-size: 20px;
                line-height: 30px;
                margin: 4px 32px;
                padding: 12px 16px;
                background-color: white;
                max-width: 500px;
                border-radius: 4px;
              `}
            >
              {currentQuiz?.embedPromotionalText}
            </TextNew>
          )}
          <Spacer height={20} />
          <EmbedTrackingComponent
            eventType={AnalyticsEventType.EmbedLandingpage}
            eventData={{
              clubLeagueSubscriptionId,
              clubId: publicClub.id,
              leagueId: currentQuiz?.league?.id,
              quizId: currentQuiz?.id,
              campaignId: currentQuiz?.campaign?.id,
            }}
          />
          {currentQuiz && currentQuiz.league ? (
            <PrimaryButton
              disabled={loading}
              loading={loading}
              onClick={() => {
                setLoading(true)
                onPlayClick()
              }}
              css={css`
                width: fit-content;
                padding: 8px 40px;
                margin: 0 auto;
                border: ${backgroundImageUrl
                  ? `1px solid ${colors.grey400}`
                  : 'none'};
                border-radius: 50px;
                min-height: 56px;
                min-width: 218px;
              `}
            >
              <TextNew
                color={colors.white}
                condensed
                italic
                strong
                size="large"
                css={css`
                  font-size: 25px;
                  line-height: 36px;
                `}
              >
                {t(`Play quiz`)}
              </TextNew>
            </PrimaryButton>
          ) : (
            <a
              href={`/clubs/${publicClub.id}?clubLeagueSubscriptionId=${clubLeagueSubscriptionId}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
            >
              <PrimaryButton
                css={css`
                  width: fit-content;
                  padding: 8px 40px;
                  margin: 0 auto;
                `}
              >
                <Text
                  color={colors.white}
                  extraCondensed
                  italic
                  strong
                  size="large"
                  css={css`
                    letter-spacing: 0.01em;
                  `}
                >
                  {t('Play in the app!')}
                </Text>
              </PrimaryButton>
            </a>
          )}
        </Flex>
      </Flex>

      {campaign?.titleScreenImageUrl && (
        <Flex
          css={css`
            max-height: 240px;
          `}
        >
          <img
            src={campaign.titleScreenImageUrl}
            alt="Ad"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: contain;
            `}
          />
        </Flex>
      )}
    </Flex>
  )
}

const ClubLeagueSubLandingPage = () => {
  const { loading } = useAuthContext()
  const clubLeagueSubscriptionId =
    useParams<'clubLeagueSubscriptionId'>().clubLeagueSubscriptionId!
  const publicClubLeagueQuery = usePublicClubLeagueQuery(
    clubLeagueSubscriptionId
  )

  if (loading || publicClubLeagueQuery.loading) {
    return <EmbedLoadingPage />
  }

  const clubId = publicClubLeagueQuery.data?.clubLeagueSubscription?.clubId
  const leagueId = publicClubLeagueQuery.data?.clubLeagueSubscription?.leagueId

  if (!clubId || !leagueId) {
    const reason = `Could not find clubId or leagueId for clubLeagueSubscriptionId: ${clubLeagueSubscriptionId}`
    return <EmbedErrorPage reason={reason} clubId={clubId} />
  }

  return (
    <Loader
      clubId={clubId}
      clubLeagueSubscriptionId={clubLeagueSubscriptionId}
    />
  )
}
export default ClubLeagueSubLandingPage
