/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { greenButtonStyle } from '../../../components/Button'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset } from '../../../styles/styles'
import { colors, hexOpacity } from '../../../styles/theme'
import { useNow } from '../../../utils/useNow'
import {
  calculateTimeRemaining,
  timeRemainingToString,
} from '../../Admin/Utils'
import { ChallengeLeague } from '../queries'

const DeadlineComponent = ({
  availableTo,
  textColor,
}: {
  availableTo: string
  textColor: string
}) => {
  const now = useNow({ updateFrequencyInMs: 1000 })

  const t = useT()

  const remaining = calculateTimeRemaining(now, new Date(availableTo))

  return (
    <Flex column>
      <TextNew
        condensed
        italic
        strong
        shadow
        color={textColor}
        css={css`
          font-size: 14px;
          line-height: 1;
        `}
      >
        {t('Deadline').toUpperCase()}
      </TextNew>
      <TextNew
        condensed
        italic
        shadow
        color={textColor}
        css={css`
          font-size: 22px;
          line-height: 1;
        `}
      >
        {timeRemainingToString(remaining, t)}
      </TextNew>
    </Flex>
  )
}

const UpcomingComponent = ({
  availableTo,
  textColor,
}: {
  availableTo: string
  textColor: string
}) => {
  const now = useNow({ updateFrequencyInMs: 1000 })

  const t = useT()

  const remaining = calculateTimeRemaining(now, new Date(availableTo))

  return (
    <Flex column horizontal="center">
      <TextNew
        condensed
        italic
        strong
        shadow
        color={textColor}
        css={css`
          font-size: 20px;
          line-height: 1;
        `}
      >
        {t('Next quiz in').toUpperCase()}
      </TextNew>
      <TextNew
        condensed
        italic
        shadow
        color={textColor}
        css={css`
          font-size: 24px;
          line-height: 1;
        `}
      >
        {timeRemainingToString(remaining, t)}
      </TextNew>
    </Flex>
  )
}

export const ChallengeLeagueListComponent = ({
  league,
  marginTop = 24,
}: {
  league: ChallengeLeague
  marginTop?: number
}) => {
  const navigate = useNavigate()

  const t = useT()

  const activeQuiz =
    league.activeQuizzes.length > 0 ? league.activeQuizzes[0] : undefined

  const upcomingQuiz = league.upcomingQuiz

  const showUpcomingQuiz = upcomingQuiz && !activeQuiz

  return (
    <button
      aria-label={league.title}
      role={'navigation'}
      css={[
        buttonReset,
        css`
          cursor: pointer;
          margin-top: ${marginTop}px;
          box-shadow: 0px 4px 0px ${hexOpacity(colors.black, 0.25)};
          border-radius: 16px;
        `,
      ]}
      onClick={() => {
        //was challenge-league
        navigate(`/league/${league.slug}`)
      }}
    >
      <Flex
        column
        horizontal="space-between"
        vertical="space-between"
        css={css`
          background-color: ${league.backgroundColor};
          padding: 12px 16px;
          border-radius: 16px;
          width: 100%;

          min-height: 210px;
        `}
      >
        <Flex
          css={css`
            height: ${league.iconImageUrl ? 80 : 40}px;
            width: 100%;
            position: relative;
          `}
        >
          {activeQuiz && (
            <Flex
              css={css`
                position: absolute;
                top: -24px;
                left: -24px;
                padding: 4px 16px;
                background-color: ${colors.yellow400};
                border-radius: 16px;
                box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.25)};
              `}
            >
              <TextNew
                strong
                italic
                condensed
                css={css`
                  font-size: 16px;
                `}
              >
                {t('New Quiz')}
              </TextNew>
            </Flex>
          )}
          <Flex
            css={css`
              position: absolute;
              top: -20px;
              left: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            {league.iconImageUrl && (
              <img
                src={league.iconImageUrl}
                alt={league.title}
                css={css`
                  max-height: 100px;
                  max-width: 100px;
                `}
              />
            )}
          </Flex>
          <TextNew
            strong
            italic
            shadow
            narrowShadow
            color={league.textColor}
            css={css`
              font-size: 12px;
              line-height: 12px;
              position: absolute;
              top: 0;
              right: 0;
            `}
          >
            {t(
              league.memberCount === 1
                ? '{{count}} member'
                : '{{count}} member_other',
              {
                count: league.memberCount,
              }
            ).toUpperCase()}
          </TextNew>
        </Flex>
        <TextNew
          color={league.textColor}
          textAlign="center"
          condensed
          italic
          shadow
          strong={false}
          css={css`
            font-size: 60px;
            line-height: 1;
            margin: 8px 0;
          `}
        >
          {league.title}
        </TextNew>
        <Flex
          css={css`
            position: relative;
            width: 100%;
            height: 64px;
          `}
        >
          {activeQuiz && activeQuiz.availableTo && (
            <div
              css={css`
                position: absolute;
                left: 0;
                bottom: 0;
              `}
            >
              <DeadlineComponent
                availableTo={activeQuiz.availableTo}
                textColor={league.textColor}
              />
            </div>
          )}
          {activeQuiz && !activeQuiz.availableTo && (
            <div
              css={css`
                position: absolute;
                left: 0;
                bottom: 0;
              `}
            >
              <TextNew
                condensed
                italic
                shadow
                color={league.textColor}
                css={css`
                  font-size: 22px;
                  line-height: 1;
                `}
              >
                {t('No deadline')}
              </TextNew>
            </div>
          )}
          {activeQuiz && (
            <div
              css={css`
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Flex
                vertical="center"
                horizontal="center"
                css={[greenButtonStyle]}
              >
                <TextNew
                  extraStrong
                  wide
                  italic
                  css={css`
                    font-size: 17px;
                    line-height: 20px;
                  `}
                >
                  {t('Play').toUpperCase()}
                </TextNew>
              </Flex>
            </div>
          )}

          {showUpcomingQuiz && (
            <div
              css={css`
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <UpcomingComponent
                availableTo={upcomingQuiz.availableFrom}
                textColor={league.textColor}
              />
            </div>
          )}
        </Flex>
      </Flex>
    </button>
  )
}
