/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { ComponentPropsWithRef, forwardRef } from 'react'
import {
  textCondensed,
  textExtraCondensed,
  textSemiCondensed,
  textStyle,
  textWide,
} from '../styles/styles'
import theme, { colors, hexOpacity } from '../styles/theme'
import { colorIsLight } from '../views/Admin/Utils'

export type TextSize =
  | 'massive'
  | 'huge'
  | 'large'
  | 'medium'
  | 'small'
  | 'tiny'

export const styleBySizeNumber: { [key in TextSize]: number } = {
  massive: 30,
  huge: 22,
  large: 20,
  medium: 18,
  small: 15,
  tiny: 13,
}

export const styleBySize: { [key in TextSize]: SerializedStyles } = {
  massive: css`
    font-size: 30px;
  `,
  huge: css`
    font-size: 22px;
  `,
  large: css`
    font-size: 20px;
  `,
  medium: css`
    font-size: 18px;
  `,
  small: css`
    font-size: 15px;
  `,
  tiny: css`
    font-size: 13px;
  `,
}

export type TextProps = {
  size?: TextSize | number
  color?: string
  strong?: boolean
  extraStrong?: boolean
  weight?: number
  dimmed?: boolean
  textAlign?: 'left' | 'center' | 'right'
  semiCondensed?: boolean
  condensed?: boolean
  extraCondensed?: boolean
  wide?: boolean
  underline?: boolean
  autoHeight?: boolean
  uppercase?: boolean
  italic?: boolean
  outline?: boolean
  outlineColor?: string
  spacingEm?: string | number
  shadow?: boolean
  narrowShadow?: boolean
} & ComponentPropsWithRef<'span'>

export const TextNew = forwardRef<HTMLSpanElement, TextProps>(function Text(
  {
    size = 'medium',
    strong,
    extraStrong,
    weight,
    dimmed,
    color,
    textAlign,
    semiCondensed,
    condensed,
    extraCondensed,
    wide,
    underline,
    className,
    uppercase,
    italic,
    outline,
    outlineColor = colors.black,
    spacingEm = '',
    shadow,
    narrowShadow,
    ...rest
  },
  ref
) {
  // Simply setting font-weight: x will not match the style in a variable font
  // Therefore, the weight of the variable settings are replaced here
  const bold = (style: false | SerializedStyles | undefined) => {
    if (!style) return false
    if (weight) return style.styles.replace("'wght' 500", `'wght' ${weight}`)
    if (extraStrong) return style.styles.replace("'wght' 500", "'wght' 900")
    if (strong) return style.styles.replace("'wght' 500", "'wght' 700")
    return style
  }
  return (
    <span
      ref={ref}
      css={[
        textStyle,
        semiCondensed && textSemiCondensed,
        condensed && textCondensed,
        extraCondensed && textExtraCondensed,
        wide && textWide,
        uppercase &&
          css`
            text-transform: uppercase;
          `,
        typeof size === 'number'
          ? css`
              font-size: ${size}px;
            `
          : styleBySize[size],
        css`
          line-height: 1;
          color: ${color ?? (dimmed ? theme.colors.grey300 : colors.black)};
        `,
        textAlign &&
          css`
            text-align: ${textAlign};
          `,
        underline &&
          css`
            text-decoration: underline;
          `,
        italic &&
          css`
            font-style: italic;
          `,
        // experimental, might not work in all browsers
        outline &&
          css`
            text-shadow: -1px -1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor},
              -1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor};
          `,
        spacingEm !== '' &&
          css`
            letter-spacing: ${spacingEm}em;
          `,
        ,
        shadow &&
          css`
            text-shadow: 1px ${narrowShadow ? 1 : 3}px 0px
              ${hexOpacity(
                colors.black,
                colorIsLight(color ?? colors.black) ? 0.5 : 0.1
              )};
          `,
        // Bug in safari, text is cut-off at the right.
        // Watch the daily animation (localstorage->hideWelcomeText = false)
        // to see an example of the bug in action.
        // If you find a better fix then please use it instead.
        css`
          margin-right: -10px;
          margin-left: -10px;
          padding-right: 10px;
          padding-left: 10px;
        `,
      ].map((it) => bold(it))}
      className={className}
      {...rest}
    />
  )
})
