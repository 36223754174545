/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { buttonReset } from '../styles/styles'
import { Text } from './Text'
import { colors } from '../styles/theme'

export const RenewCookiesButton = () => {
  return (
    <button
      role="button"
      onClick={() => {
        if (window.Cookiebot?.renew !== undefined) {
          window.Cookiebot.renew()
        }
      }}
      css={[
        buttonReset,
        css`
          text-decoration: none;
          border-bottom: 1px solid white;
          &:hover {
            border-color: ${colors.grey300};
          }
        `,
      ]}
    >
      <Text size="small" color="white">
        Cookies
      </Text>
    </button>
  )
}
