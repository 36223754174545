/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useEffect } from 'react'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  CompletedQuestion,
  LeagueQuiz as LeagueQuizType,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../Button'
import { QuestionImage } from '../QuestionImage'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import { AlternativeButton } from './AlternativeButton'
import { QuizStatusComponent } from './QuizStatusComponent'

export const LastCompletedQuestion: FC<{
  leagueQuiz: LeagueQuizType
  completedQuestions: CompletedQuestion[]
  lastCompletedQuestion: CompletedQuestion
  allQuestionsAnswered: boolean
  triggerCalculation: () => void
  nextQuestionLoading: boolean
  nextQuestion: () => void
  colorTheme: 'red' | 'white' | 'green'
  isEmbed: boolean
  clubLeagueSubscriptionId?: string
}> = ({
  leagueQuiz,
  completedQuestions,
  lastCompletedQuestion,
  allQuestionsAnswered,
  triggerCalculation,
  nextQuestionLoading,
  nextQuestion,
  colorTheme,
  isEmbed,
  clubLeagueSubscriptionId,
}) => {
  const t = useT()

  const { campaignHeaderHeight } = useLeagueQuizContext()

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId: leagueQuiz.league.id,
        leagueQuizId: leagueQuiz.id,
        isEmbed,
        clubLeagueSubscriptionId,
      })
    }
  }, [allQuestionsAnswered, leagueQuiz.id, leagueQuiz.league.id])

  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  // the total amount of registered answers for this question
  // used to calculate the percentage of answers for each alternative
  const totalNumberOfAnswers = lastCompletedQuestion.alternatives.reduce(
    (acc, alternative) => {
      return acc + alternative.numberOfAnswers
    },
    0
  )

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Spacer height={isEmbed ? 8 : 'medium'} />
        <QuizStatusComponent
          currentQuestion={completedQuestions.length}
          totalQuestions={leagueQuiz.questionsCount}
          points={lastCompletedQuestion.points}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={lastCompletedQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {lastCompletedQuestion.text}
        </Text>
        {lastCompletedQuestion.imageUrl && (
          <QuestionImage imageSrc={lastCompletedQuestion.imageUrl} />
        )}
        {!lastCompletedQuestion.alternatives.some(
          (alternative) => alternative.selectedAlternative
        ) && (
          <Text color={colors.red300} css={margin.bottom()} textAlign="center">
            {t('Unfortunately, the time ran out!')}
          </Text>
        )}
        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <AlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.selectedAlternative}
              correct={alternative.isCorrectAlternative}
              percentageAnswered={
                totalNumberOfAnswers === 0
                  ? 0
                  : (alternative.numberOfAnswers / totalNumberOfAnswers) * 100
              }
              showGlobalStats={true}
            >
              {alternative.text}
            </AlternativeButton>
          ))}

          <Flex
            column
            css={[
              margin.top(isEmbed ? 'small' : 'large'),
              css`
                opacity: 0;
                animation: ${fadeIn} 600ms ease-out;
                animation-delay: 2000ms;
                animation-fill-mode: forwards;
              `,
            ]}
          >
            {allQuestionsAnswered ? (
              <PrimaryButton
                onClick={() => triggerCalculation()}
                css={css`
                  background-color: 'black';
                  height: 50px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Finish')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                disabled={nextQuestionLoading}
                loading={nextQuestionLoading}
                onClick={nextQuestion}
                css={css`
                  background-color: black;
                  color: white;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Next')}
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </PageContent>
  )
}
