/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Text } from './Text'
import { Flex } from './layout/Flex'

export const EditLeagueToggle = ({
  editEnabled,
  setEditEnabled,
}: {
  editEnabled: boolean
  setEditEnabled: (value: boolean) => void
}) => {
  return (
    <Flex gap="small">
      <Text color="white">Admin tools:</Text>
      <input
        type="checkbox"
        checked={editEnabled}
        onChange={(event) => {
          setEditEnabled(event.target.checked)
        }}
        css={css`
          margin: 0;
          height: 30px;
          width: 30px;
        `}
      />
    </Flex>
  )
}
