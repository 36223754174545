/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { sortBy } from 'lodash'
import { useMemo } from 'react'
import { MdEdit } from 'react-icons/md'
import { Flex } from '../../../components/layout/Flex'
import { Loader } from '../../../components/Loader'
import { ModalTrigger } from '../../../components/modal/Modal'
import { Text } from '../../../components/Text'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset, flexCenter } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { hasOneOfClubRoles } from '../clubUtils'
import { PlayerElement } from '../PlayerElement'
import {
  ClubRole,
  clubRoles,
  ClubWithMembers,
  useClubWithMembersQuery,
} from '../queries'
import { EditMemberModal } from './EditMemberModal'

export const Members = ({
  clubId,
  clubSlug,
  viewerRole,
}: {
  clubId: string
  clubSlug: string
  viewerRole: ClubRole | null
  membersData?: {
    club: ClubWithMembers
  }
  loading?: boolean
}) => {
  const t = useT()
  const { authUser } = useAuthContext()

  const { data: membersData, loading } = useClubWithMembersQuery(clubSlug)

  const sortedMembers = useMemo(() => {
    return sortBy(
      membersData?.club.members ?? [],
      (member) => clubRoles.indexOf(member.role),
      (member) => member.user.displayName
    )
  }, [membersData?.club.members])

  if (loading && !membersData) {
    return (
      <Flex horizontal="center">
        <Loader variant="blue" delay={0} />
      </Flex>
    )
  }

  if (sortedMembers.length === 0) {
    return (
      <div>
        <Text>{t("There's no one here")}</Text>
      </div>
    )
  }

  return (
    <Flex
      css={css`
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
      `}
      column
    >
      {sortedMembers.map((member) => (
        <PlayerElement
          key={member.id}
          role={member.role}
          {...member.user}
          backgroundColor={
            member.id === authUser?.uid ? colors.yellow100 : 'white'
          }
          adminControls={
            hasOneOfClubRoles(viewerRole, 'admin', 'moderator') ? (
              <ModalTrigger
                button={({ openModal }) => (
                  <button
                    css={[
                      buttonReset,
                      css`
                        background-color: black;
                        border-radius: 10px;
                        height: 20px;
                        width: 20px;
                        ${flexCenter};
                        &:hover {
                          background-color: ${colors.grey400};
                        }
                      `,
                    ]}
                    onClick={(event) => {
                      event.preventDefault()
                      openModal()
                    }}
                  >
                    <MdEdit size={14} color="white" />
                  </button>
                )}
                modal={({ closeModal }) => (
                  <EditMemberModal
                    member={member}
                    clubId={clubId}
                    viewerRole={viewerRole!}
                    closeModal={closeModal}
                  />
                )}
              />
            ) : null
          }
        />
      ))}
    </Flex>
  )
}
