/** @jsxImportSource @emotion/react */

import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { isProduction } from '../config/config'

export const LottieWebComponent = ({
  url,
  loop = false,
  autoplay = true,
  className,
}: {
  url: string
  loop?: boolean
  autoplay?: boolean
  className?: string
}) => {
  const [data, setData] = useState<any>(null)

  // Only fetch lottie animation from sanity
  useEffect(() => {
    if (!url.includes('sanity')) {
      if (!isProduction) {
        console.error('Lottie animation url is not from sanity', url)
      }
      return
    }

    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data)
        })
    } catch (_) {
      if (!isProduction) {
        console.error('Error fetching lottie animation', url)
      }
    }
  }, [url])

  if (!data) return null

  return (
    <Lottie
      animationData={data}
      loop={loop}
      autoplay={autoplay}
      className={className}
    />
  )
}
