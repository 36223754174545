/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { Heading } from '../../components/Heading'
import { Link } from '../../components/Link'
import { Loader } from '../../components/Loader'
import { AndroidOrIosButton } from '../../components/StoreButtons'
import { Text } from '../../components/Text'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { titleFromQuiz } from '../../utils/util'
import {
  useQuizBySlugOrIdQuery,
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from './queries'

const Content = ({
  slug,
  setTitle,
  source,
}: {
  slug: string
  setTitle: (title: string) => void
  source?: string | null
}) => {
  const [loading, setLoading] = useState(false)
  const { authUser, signInAsAnonymous } = useAuthContext()
  const [startLeagueQuizMutation] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()
  const t = useT()
  const navigate = useNavigate()
  const { data, loading: queryLoading, error } = useQuizBySlugOrIdQuery(slug)
  const { isNativeApp } = useNativeApp()

  useEffect(() => {
    trackEvent(AnalyticsEventType.QuizLandingPage, { slug, source })
  }, [])

  useEffect(() => {
    if (data && data.quizBySlugOrId) {
      setTitle(`fcQuiz | ${data.quizBySlugOrId.title}`)
    }
  }, [data])

  if (queryLoading) {
    return (
      <Loader
        center
        css={css`
          margin-top: 100px;
        `}
      />
    )
  }

  if (!data || !data.quizBySlugOrId || error) {
    const reason = error?.message || 'no data'
    return (
      <DefaultError
        sentryErrorMessage={`QuizLandingpage | reason: ${reason}`}
      />
    )
  }

  const quiz = data.quizBySlugOrId!

  const StartOrContinueQuiz = () => {
    if (quiz.viewerCanStartQuiz) {
      return (
        <PrimaryButton
          disabled={loading}
          onClick={async () => {
            setLoading(true)
            if (!authUser) {
              await signInAsAnonymous()
            }

            // If quiz is a career path quiz, start career path quiz
            if (quiz.quizType === 'careerPath') {
              startCareerPathQuizMutation({
                variables: { input: { leagueQuizId: quiz.id } },
              }).then((result) => {
                const quizInstanceId = result.data?.startCareerPathQuiz.id
                if (quizInstanceId) {
                  trackEvent(AnalyticsEventType.StartQuiz, {
                    quizId: quiz.id,
                    leagueId: quiz.league.id,
                    isCareerPath: true,
                  })
                  navigate(
                    `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`
                  )
                }
              })
              return
            }

            startLeagueQuizMutation({
              variables: { input: { leagueQuizId: quiz.id } },
            }).then((result) => {
              const quizInstanceId = result.data?.startLeagueQuiz.id
              if (quizInstanceId) {
                trackEvent(AnalyticsEventType.StartQuiz, {
                  quizId: quiz.id,
                  leagueId: quiz.league.id,
                })
                navigate(`/play-quiz/${quizInstanceId}`)
              }
            })
          }}
        >
          {t('Start')}
        </PrimaryButton>
      )
    }

    if (quiz.viewerData.activeQuizInstance?.id) {
      return (
        <PrimaryButton
          onClick={() =>
            // Continue quiz, so dont track start quiz
            navigate(`/play-quiz/${quiz.viewerData.activeQuizInstance?.id}`)
          }
        >
          {t('Continue quiz')}
        </PrimaryButton>
      )
    }

    if (quiz.viewerData.hasCompletedQuiz && quiz.viewerData.result) {
      return (
        <SecondaryButton
          onClick={() =>
            navigate(
              `/play-quiz/${quiz.viewerData.result?.leagueQuizInstanceId}`
            )
          }
        >
          {t('Results')}
        </SecondaryButton>
      )
    }

    return (
      <PrimaryButton onClick={() => navigate(`/series/${quiz.league.slug}`)}>
        {t('To the series page')}
      </PrimaryButton>
    )
  }

  const backgroundImage = quiz.imageUrl || quiz.league.imageUrl

  return (
    <Flex
      column
      grow
      vertical="center"
      horizontal="center"
      css={css`
        width: 100%;
        background-color: ${colors.white};
        z-index: 0;
        position: relative;
      `}
    >
      {backgroundImage && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url(${backgroundImage});
            background-size: cover;
            background-position: center;
            z-index: 0;
          `}
        />
      )}

      <Flex
        column
        gap="large"
        css={css`
          background-color: white;
          padding: 30px 25px;
          border-radius: 4px;
          width: 90%;
          max-width: 400px;
          z-index: 2;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        `}
      >
        <Flex column>
          <Link
            to={`/series/${quiz.league.slug}`}
            underline={false}
            css={css`
              line-height: 1;
              font-weight: 600;
              font-size: 20px;

              &:hover {
                text-decoration: underline;
              }
            `}
          >
            <Heading level={1} looksLikeLevel={5} css={css``}>
              {quiz.league.title}
            </Heading>
          </Link>
          <Heading level={2} looksLikeLevel={5}>
            {titleFromQuiz(quiz, t)}
          </Heading>
          <Spacer height="medium" />
          <Text size="small">
            {t('{{count}} question_other', {
              count: quiz.questionsCount,
            })}
            <br />
            {t('{{count}} player_other', {
              count: quiz.participantCount,
            })}
          </Text>
        </Flex>

        <StartOrContinueQuiz />
      </Flex>

      {!isNativeApp && (
        <Flex
          horizontal="space-between"
          vertical="center"
          css={css`
            z-index: 2;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 8px;
            align-items: center;
            background-color: ${colors.white};
          `}
        >
          <Logo
            color={colors.black}
            size={40}
            css={css`
              margin-left: 10px;
            `}
          />
          <AndroidOrIosButton />
        </Flex>
      )}
    </Flex>
  )
}

export const QuizLandingPage = () => {
  const slug = useParams<{ slug: string }>().slug

  const urlParams = new URLSearchParams(window.location.search)
  const source = urlParams.get('source')

  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)

  const [title, setTitle] = useState<string | undefined>()

  if (!slug) {
    return <DefaultError sentryErrorMessage="QuizLandingPage | no slug" />
  }

  return (
    <Layout
      hideNavbar
      title={title}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      backgroundColor={'white'}
      profileBanner
      headerForegroundColor={colors.black}
      hideHeaderPadding
    >
      <Content slug={slug} setTitle={setTitle} source={source} />
    </Layout>
  )
}
