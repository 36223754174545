/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { DefaultError } from '../../components/DefaultError'
import { Page } from '../../components/embed/Page'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { LeagueQuiz } from '../../components/leagueQuiz/LeagueQuiz'
import { useAuthContext } from '../../lib/AuthContext'
import { margin } from '../../styles/margin'
import theme from '../../styles/theme'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import { useLeagueQuizInstanceQuery } from '../LeagueQuiz/queries'
import { EmbedErrorPage, EmbedLoadingPage } from './SubPages'

const Content = ({
  quizId,
  quizType = 'text',
}: {
  quizId: string
  quizType?: string
}) => {
  const query = useLeagueQuizInstanceQuery(quizId, quizType)

  const { setHasSanityCampaign, setLeagueId, setQuizId, setInstanceId } =
    useLeagueQuizContext()

  useEffect(() => {
    if (query.data?.leagueQuizInstance) {
      setLeagueId(query.data.leagueQuizInstance.leagueQuiz.league.id)
      setQuizId(query.data.leagueQuizInstance.leagueQuiz.id)
      setInstanceId(query.data.leagueQuizInstance.id)
    }
  }, [quizId, setQuizId, query])

  useEffect(() => {
    if (
      query.data?.leagueQuizInstance?.leagueQuiz.campaign &&
      setHasSanityCampaign
    ) {
      setHasSanityCampaign(true)
    }
  }, [
    query.data?.leagueQuizInstance?.leagueQuiz.campaign,
    setHasSanityCampaign,
  ])

  if (query.loading) {
    return <EmbedLoadingPage />
  }

  if (!query.data?.leagueQuizInstance || query.error) {
    const reason = !query.data?.leagueQuizInstance
      ? 'no instance'
      : query.error?.message
    return (
      <Page colorTheme="white">
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`LeagueQuizEmbedPage | quizId: ${quizId}, ${reason}`}
        />
      </Page>
    )
  }

  return (
    <Flex column grow>
      <QuizAd
        campaign={query.data.leagueQuizInstance.leagueQuiz.campaign}
        isEmbed={true}
        isDailyCareerPath={
          query.data.leagueQuizInstance.leagueQuiz.league.slug ===
          'the-daily-career-path'
        }
        leagueId={query.data.leagueQuizInstance.leagueQuiz.league.id}
        quizId={query.data.leagueQuizInstance.leagueQuiz.id}
      />
      <LeagueQuiz
        leagueQuizInstance={query.data.leagueQuizInstance}
        colorTheme={'white'}
        isEmbed
      />
    </Flex>
  )
}

const LeagueQuizEmbedPage = () => {
  const quizId = useParams<'quizId'>().quizId!
  const leagueId = useParams<'leagueId'>().leagueId
  const { loading, authUser } = useAuthContext()

  const [searchParams] = useSearchParams()
  const quizType = searchParams.get('quizType')

  if (loading) {
    return <EmbedLoadingPage />
  }

  // user should be logged (anon or not) in order to play quiz
  if (!loading && !authUser) {
    // if leagueId is provided, redirect to league page
    if (leagueId) {
      return (
        <Navigate
          to={{
            pathname: `/embed/league/${leagueId}`,
          }}
        />
      )
    }
    return <EmbedErrorPage />
  }

  if (!quizId) {
    return <EmbedErrorPage />
  }

  return (
    <LeagueQuizContextProvider isEmbed>
      <Layout
        hideNavbar
        hideHeaderPadding
        backgroundColor={theme.colors.grey100}
      >
        <Content quizId={quizId} quizType={quizType || 'text'} />
      </Layout>
    </LeagueQuizContextProvider>
  )
}

export default LeagueQuizEmbedPage
