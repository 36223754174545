/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { ConfirmModal } from '../../components/ConfirmModal'
import { CroppedImageForm } from '../../components/CroppedImageForm'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { ClubLogo } from '../../components/Picture'
import { Text } from '../../components/Text'
import { ErrorMessage, TextInput } from '../../components/TextInput'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { PROFILE_PICTURE_SIZE } from '../../config/config'
import { useT } from '../../lib/i18n/useT'
import { uploadImageToFirebase } from '../../lib/uploadToStorage'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset, flexCenter } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import {
  Club,
  useClubProfileQuery,
  useDeleteClubMutation,
  useUpdateClubMutation,
  useUpdateInvitationCodeMutation,
} from './queries'

type Props = { club: Club; refetchClub: () => void }

export const validName = /^.{5,32}$/

const Content: FC<Props> = ({ club }) => {
  const t = useT()
  const navigate = useNavigate()
  const [updateClub] = useUpdateClubMutation()
  const [updateInvitationCode, { loading: updateInvitationCodeLoading }] =
    useUpdateInvitationCodeMutation()
  const [deleteClub, { loading: deleteClubLoading }] = useDeleteClubMutation()

  const form = useForm({
    initialValues: {
      name: club.name,
      description: club.description || '',
      logo: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      logoShape: club.logoShape,
      backgroundImage: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      isPublic: club.public,
    },
    validate: {
      name: (val) =>
        validName.test(val)
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        const [logoUri, backgroundImageUri] = await Promise.all([
          values.logo?.file
            ? uploadImageToFirebase(
                `clubs/${club.id}/logo`,
                values.logo.file
              ).getImageUri()
            : Promise.resolve(undefined),
          values.backgroundImage?.file
            ? uploadImageToFirebase(
                `clubs/${club.id}/background`,
                values.backgroundImage.file
              ).getImageUri()
            : Promise.resolve(undefined),
        ])

        const promises: Promise<any>[] = []

        promises.push(
          updateClub({
            variables: {
              input: {
                clubId: club.id,
                name: values.name,
                description: values.description,
                isPublic: values.isPublic,
                logoShape: values.logoShape,
                logoUri,
                backgroundImageUri,
              },
            },
          })
        )

        await Promise.all(promises)
        navigate(`/clubs/${club.id}`)
      } catch (error) {
        console.error('Error while saving club', error)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  return (
    <>
      <PageContent css={margin.vertical(32)}>
        <Heading
          level={2}
          looksLikeLevel={4}
          css={margin.bottom('large')}
          color="white"
        >
          {t('Edit club')}
        </Heading>

        <form
          onSubmit={(event) => {
            event.preventDefault()
            form.submitForm()
          }}
        >
          <CroppedImageForm
            label={t('Background image')}
            minWidth={PROFILE_PICTURE_SIZE.width}
            minHeight={PROFILE_PICTURE_SIZE.height}
            currentImage={
              club.backgroundImageUrl ? club.backgroundImageUrl : undefined
            }
            setCropped={(croppedOptions) => {
              form.setValue('backgroundImage', croppedOptions)
            }}
            textColor="white"
          />
          <br />
          <br />
          <CroppedImageForm
            label={t('Logo')}
            minWidth={PROFILE_PICTURE_SIZE.width}
            minHeight={PROFILE_PICTURE_SIZE.height}
            currentImage={club.logoUrl ? club.logoUrl : undefined}
            setCropped={(croppedOptions) => {
              form.setValue('logo', croppedOptions)
            }}
            maskShape={form.values.logoShape}
            textColor="white"
          />
          <br />
          <br />

          {club.logoUrl && (
            <>
              <Spacer height="large" />
              <Text color="white">Shape</Text>
              <Flex css={margin.top('small')}>
                <button
                  onClick={(event) => {
                    event.preventDefault()
                    form.setValue('logoShape', 'circle')
                  }}
                  css={[
                    buttonReset,
                    css`
                      width: 60px;
                      height: 60px;
                      border-radius: 8px;
                      background-color: ${form.values.logoShape === 'circle'
                        ? colors.green300
                        : 'transparent'};
                      ${flexCenter};
                    `,
                  ]}
                  color="white"
                >
                  <ClubLogo url={club.logoUrl} size={50} shape="circle" />
                </button>
                <Spacer width="medium" />
                <button
                  onClick={(event) => {
                    event.preventDefault()
                    form.setValue('logoShape', 'shield')
                  }}
                  css={[
                    buttonReset,
                    css`
                      width: 60px;
                      height: 60px;
                      border-radius: 8px;
                      background-color: ${form.values.logoShape === 'shield'
                        ? colors.green300
                        : 'transparent'};
                      ${flexCenter};
                    `,
                  ]}
                  color="white"
                >
                  <ClubLogo url={club.logoUrl} size={50} shape="shield" />
                </button>
                <Spacer width="medium" />
                <button
                  onClick={(event) => {
                    event.preventDefault()
                    form.setValue('logoShape', 'uncropped')
                  }}
                  css={[
                    buttonReset,
                    css`
                      width: 60px;
                      height: 60px;
                      border-radius: 8px;
                      background-color: ${form.values.logoShape === 'uncropped'
                        ? colors.green300
                        : 'transparent'};
                      ${flexCenter};
                    `,
                  ]}
                  color="white"
                >
                  <ClubLogo url={club.logoUrl} size={50} shape="uncropped" />
                </button>
              </Flex>
            </>
          )}
          <TextInput
            label={t('Club name')}
            value={form.values.name}
            onValue={(val) => form.setValue('name', val)}
            disabled={form.submitting || !club.viewerIsClubAdmin}
            css={css`
              flex: 1;
              ${margin.top('large')}
            `}
            error={form.submitAttempted && form.fieldErrors.name}
            textColor="white"
          />

          <TextInput
            label={t('Club description')}
            value={form.values.description}
            onValue={(val) => form.setValue('description', val)}
            disabled={form.submitting || !club.viewerIsClubAdmin}
            css={css`
              flex: 1;
              ${margin.top('large')}
            `}
            error={form.submitAttempted && form.fieldErrors.description}
            textColor="white"
          />

          <ToggleSwitch
            value={form.values.isPublic}
            onValue={(val) => form.setValue('isPublic', val)}
            label={t('Public group')}
            css={margin.top('large')}
            textColor="white"
          />

          {!form.values.isPublic && (
            <Flex
              css={margin.top()}
              horizontal="space-between"
              vertical="center"
            >
              <Text css={margin.bottom('tiny')} color="white">
                {t('Invite code')}
              </Text>

              <ModalTrigger
                button={({ openModal }) => (
                  <SecondaryButton onClick={openModal} variant="white">
                    {t('Reveal code')}
                  </SecondaryButton>
                )}
                modal={({ closeModal }) => (
                  <Modal heading={t('Current code')} closeModal={closeModal}>
                    <Flex column horizontal="flex-start" css={margin.top()}>
                      <Text
                        css={css`
                          font-family: monospace;
                        `}
                      >
                        {club.invitationCode}
                      </Text>
                      <Text css={margin.vertical()}>
                        {t(
                          'Click the button to generate a new invite code. The current code will be invalidated.'
                        )}
                      </Text>

                      <PrimaryButton
                        onClick={() =>
                          updateInvitationCode({
                            variables: { input: { clubId: club.id } },
                          })
                        }
                        disabled={updateInvitationCodeLoading}
                      >
                        {t('Create new code')}
                      </PrimaryButton>
                    </Flex>
                  </Modal>
                )}
              />
            </Flex>
          )}

          <Flex
            horizontal="space-between"
            vertical="center"
            css={margin.top('huge')}
            gap="medium"
          >
            <SecondaryButton
              type="button"
              onClick={() => {
                navigate(`/clubs/${club.id}`)
              }}
              variant="white"
            >
              {t('Cancel')}
            </SecondaryButton>

            <Flex column horizontal="flex-end">
              {form.submitError && (
                <ErrorMessage css={margin.bottom()}>
                  {form.submitError}
                </ErrorMessage>
              )}
              <PrimaryButton
                type="submit"
                disabled={form.submitting}
                variant="white"
              >
                {t('Save')}
              </PrimaryButton>
            </Flex>
          </Flex>
        </form>

        {club.viewerRole === 'admin' && (
          <Flex column horizontal="flex-start" css={margin.top('huge')}>
            <Heading level={3} looksLikeLevel={5} color="white">
              {t('Delete club')}
            </Heading>
            <Text color="white">
              {t('Do you want to delete {{name}}?', { name: club.name })}
            </Text>
            <Spacer height="small" />
            <ConfirmModal
              button={({ openModal }) => (
                <SecondaryButton
                  variant="dangerRed"
                  onClick={openModal}
                  loading={deleteClubLoading}
                >
                  {t('Delete club')}
                </SecondaryButton>
              )}
              onConfirm={() => {
                deleteClub({ variables: { clubId: club.id } }).then(() => {
                  navigate('/clubs')
                })
              }}
              loading={deleteClubLoading}
            />
          </Flex>
        )}
      </PageContent>
    </>
  )
}

const DataLoader = () => {
  const slug = useParams().slug!
  const query = useClubProfileQuery(slug)
  const club = query.data?.club

  return (
    <Flex column grow>
      {club && <Content club={club} refetchClub={query.refetch} />}
    </Flex>
  )
}

export const EditClubPage = () => {
  const t = useT()

  return (
    <Layout
      title={`fcQuiz | ${t('Clubs')}`}
      withHeader
      withFooter
      backgroundColor={colors.green600}
    >
      <DataLoader />
    </Layout>
  )
}
