import { gql, useMutation, useQuery } from '@apollo/client'
import { ChallengeGroup, ChallengeMatch } from '../Challenge/queries'
import {
  ActiveQuestion,
  LeagueQuiz,
  LeagueQuizInstance,
  LeagueQuizInstanceResult,
  LeagueQuizInstanceStatus,
  activeQuestionFragment,
  leagueQuizCampaignFragment,
} from '../LeagueQuiz/queries'
import { User } from '../Profile/queries'

export type ChallengeCompletedQuestionAlternative = {
  id: string
  isCorrectAlternative: boolean
  selectedAlternative: boolean
  text: string
  challengeUserSelected: boolean
}

export type ChallengeCompletedQuestion = {
  id: string
  text: string
  imageUrl: string
  alternatives: ChallengeCompletedQuestionAlternative[]
  secondsLeft: number
}

export type ChallengeLeagueQuizInstance = {
  id: string
  status: LeagueQuizInstanceStatus
  leagueQuiz: LeagueQuiz
  activeQuestion: ActiveQuestion | null
  result: LeagueQuizInstanceResult
  challengeQuizInstanceId: string | null
  challengeCompletedQuestions: ChallengeCompletedQuestion[]
  challengeUser: User | null
  challengeUserResult: LeagueQuizInstanceResult | null
  challengeMatchId: string | null
  challengeMatchData: ChallengeMatch | null
}

export type ChallengeQuizInput = {
  instanceId: string
  challengeInstanceId: string
}

type CreateChallengeGroupWithInvitationInput = {
  quizInstanceId: string
}

const createChallengeGroupWithInvitationMutation = gql`
  mutation createChallengeGroupWithInvitation(
    $input: CreateChallengeGroupWithInvitationInput!
  ) {
    createChallengeGroupWithInvitation(input: $input) {
      id
      title
      description
      versusInvitation
    }
  }
`

export const useCreateChallengeGroupWithInvitationMutation = () => {
  return useMutation<
    { createChallengeGroupWithInvitation: ChallengeGroup },
    { input: CreateChallengeGroupWithInvitationInput }
  >(createChallengeGroupWithInvitationMutation)
}

type startInviteChallengeQuizInput = {
  invitationId: string
  anonUsername?: string
}

const startInviteChallengeQuizMutation = gql`
  mutation startInviteChallengeQuiz($input: StartInviteChallengeQuizInput!) {
    startInviteChallengeQuiz(input: $input) {
      id
      challengeQuizInstanceId
      challengeMatchId
    }
  }
`
export const useStartInviteChallengeQuizMutation = () => {
  return useMutation<
    {
      startInviteChallengeQuiz: {
        id: string
        challengeQuizInstanceId: string
        challengeMatchId: string
      }
    },
    { input: startInviteChallengeQuizInput }
  >(startInviteChallengeQuizMutation)
}

const instanceFromChallengeMatchQuery = gql`
  query instanceFromChallengeMatch($id: ID!) {
    instanceFromChallengeMatch(id: $id) {
      id
      status
      leagueQuiz {
        id
        title
        slug
        viewerCanStartQuiz
        questionsCount
        participantCount
        viewerCanQuickMatch
        league {
          id
          slug
          title
          frequency
        }
        campaign {
          ...LeagueQuizCampaignFragment
        }
      }
      activeQuestion {
        id
        text
        imageUrl
        alternatives {
          id
          text
        }
        secondsLeft
        maxTimeToAnswerInSeconds
        timeToReadQuestionInMs
      }
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      challengeQuizInstanceId
      challengeCompletedQuestions {
        id
        text
        imageUrl
        secondsLeft
        alternatives {
          id
          text
          isCorrectAlternative
          selectedAlternative
          challengeUserSelected
        }
      }
      challengeUser {
        id
        username
        slug
        avatarData
        displayName
      }
      challengeUserResult {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      challengeMatchId
      challengeMatchData {
        id
        quizId
        challengeGroupId
        firstUserId
        secondUserId
        isChallengeLeague
      }
    }
  }
  ${leagueQuizCampaignFragment}
`

export const useChallengeMatchInstanceQuery = (id: string) => {
  return useQuery<{ instanceFromChallengeMatch: ChallengeLeagueQuizInstance }>(
    instanceFromChallengeMatchQuery,
    {
      variables: {
        id,
      },
    }
  )
}

type AnswerChallengeQuestionInput = {
  leagueQuizInstanceId: string
  questionId: string
  alternativeId: string | null
  challengeQuizInstanceId: string
  challengeMatchId: string
}

const answerChallengeQuestionMutation = gql`
  mutation answerQuestion($input: AnswerQuestionInput!) {
    answerQuestion(input: $input) {
      id
      score
      challengeCompletedQuestions {
        id
        text
        imageUrl
        secondsLeft
        alternatives {
          id
          text
          isCorrectAlternative
          selectedAlternative
          challengeUserSelected
        }
      }
      activeQuestion {
        ...ActiveQuestionFragment
      }
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      challengeMatchId
    }
  }

  ${activeQuestionFragment}
`
export const useAnswerChallengeQuestionMutation = () => {
  return useMutation<unknown, { input: AnswerChallengeQuestionInput }>(
    answerChallengeQuestionMutation
  )
}

const leagueQuizInstanceQuery = gql`
  query leagueQuizInstanceWithTransfer($id: ID!) {
    leagueQuizInstanceWithTransfer(id: $id) {
      id
      status
      leagueQuiz {
        id
        title
        slug
        viewerCanStartQuiz
        questionsCount
        participantCount
        viewerCanQuickMatch
        league {
          id
          slug
          title
          frequency
        }
        campaign {
          ...LeagueQuizCampaignFragment
        }
      }
      activeQuestion {
        ...ActiveQuestionFragment
      }
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      challengeMatchId
    }
  }

  ${leagueQuizCampaignFragment}
  ${activeQuestionFragment}
`
export const useLeagueQuizInstanceWithTransferQuery = ({
  id,
  skip,
}: {
  id: string
  skip?: boolean
}) => {
  return useQuery<{
    leagueQuizInstanceWithTransfer?: LeagueQuizInstance
  }>(leagueQuizInstanceQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip,
  })
}

type StartChallengeMatchQuizInput = {
  opponentId: string
  challengeGroupId: string
  quizId: string
}

const startChallengeMatchQuizMutation = gql`
  mutation startChallengeMatchQuiz($input: StartChallengeMatchQuizInput!) {
    startChallengeMatchQuiz(input: $input) {
      id
      quizId
      challengeGroupId
      firstUserId
      secondUserId
    }
  }
`

export const useStartChallengeMatchQuizMutation = () => {
  return useMutation<
    {
      startChallengeMatchQuiz: ChallengeMatch
    },
    { input: StartChallengeMatchQuizInput }
  >(startChallengeMatchQuizMutation)
}

type StartQuickMatchInput = {
  quizId: string
}

const startQuickMatchMutation = gql`
  mutation startQuickMatch($input: StartQuickMatchInput!) {
    startQuickMatch(input: $input) {
      id
      quizId
      firstUserId
      secondUserId
    }
  }
`

export const useStartQuickMatchMutation = () => {
  return useMutation<
    {
      startQuickMatch: ChallengeMatch
    },
    { input: StartQuickMatchInput }
  >(startQuickMatchMutation)
}
