/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useInterval } from '../../utils/useInterval'
import { useTrackFcEventMutation } from '../../lib/analytics'

export const EmbedTrackingComponent = ({
  eventType,
  eventData,
}: {
  eventType: string
  eventData: any
}) => {
  const { ref, inView } = useInView()

  const initTimeRemaining = 1

  const [remaining, setRemaining] = useState(initTimeRemaining)
  const [runTimer, setRunTimer] = useState(true)
  const [trackFcEvent] = useTrackFcEventMutation()

  useInterval(
    () => {
      if (remaining > 0) {
        setRemaining(remaining - 1)
      }
    },
    runTimer && remaining > 0 ? 1000 : null
  )

  useEffect(() => {
    if (inView) {
      // In view, start timer
      setRunTimer(true)
    } else {
      // Not inView, stop and reset timer
      setRunTimer(false)
      setRemaining(initTimeRemaining)
    }
  }, [inView])

  useEffect(() => {
    if (remaining <= 0) {
      trackFcEvent({
        variables: {
          input: {
            eventType,
            eventData: JSON.stringify(eventData),
          },
        },
      })
    }
  }, [remaining])

  return <div ref={ref} />
}
