/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Loader } from '../../../components/Loader'
import { SelectInput } from '../../../components/SelectInput'
import { Text } from '../../../components/Text'
import { ErrorMessage, TextInput } from '../../../components/TextInput'
import { Flex } from '../../../components/layout/Flex'
import { Locale, locales, localeToFlag } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import { leagueFrequencyToString } from '../../League/LeaguePage'
import { LeagueFrequency, useLeaguePageQuery } from '../../League/queries'
import { Question, useFinishContext } from '../GeneratePage'
import { SelectCategories } from '../SelectCategories'
import {
  useAddCareerPathQuestionMutation,
  useAddQuestionMutation,
  useAddQuizMutation,
} from '../queries'
import {
  addCareerPathQuestionToSanity,
  addQuestionToSanity,
  retrieveLoad,
} from '../util'
import { usePrimeLeagueQuizMutation } from '../../Translate/queries'
import { CareerPathQuestion } from '../GenerateQuestion'
import { BiPlus } from 'react-icons/bi'

export const OverviewFinish = (props: {
  setQuestions: any
  questions: (Question | CareerPathQuestion)[]
  resetQuestions: any
  leagueSlug: string
  addMoreQuestions: any
  showIsAdmin: boolean
  isAdmin: boolean
  setIsAdmin: any
  setEditQuestionIndex: any
  setFinishedOverview: any
  quizId?: string
  jsonData?: any
}) => {
  const [fieldErrors, setFieldErrors] = useState<{
    [key: string]: string
  }>({})

  const [uploadingQuiz, setUploadingQuiz] = useState(false)

  const [uploadIndex, setUploadIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [addQuizMutation, { error: addQuizError }] = useAddQuizMutation()

  const query = useLeaguePageQuery(props.leagueSlug, !props.leagueSlug)

  const [defaultStartDate, setDefaultStartDate] = useState<Dayjs | null>(null)
  const [defaultEndDate, setDefaultEndDate] = useState<Dayjs | null>(null)

  const [numberOfQuestions, setNumberOfQuestions] = useState(
    props.questions.length > 8 ? props.questions.length : 8
  )

  const freq = query.data?.league?.frequency ?? LeagueFrequency.weekly
  const languageLeague = query.data?.league?.languageRestrictedLeague

  useEffect(() => {
    if (defaultStartDate) return
    if (!freq) return
    const league = query.data?.league
    if (league) {
      const date = league.activeQuizzes
        .map((it) => new Date(it.availableFrom))
        .sort()
        .reverse()[0]
      const frequencyToType: {
        [key: string]: 'day' | 'week' | 'month'
      } = {
        daily: 'day',
        weekly: 'week',
        monthly: 'month',
      }
      setDefaultStartDate(dayjs(date).add(1, frequencyToType[freq]))
      setDefaultEndDate(dayjs(date).add(2, frequencyToType[freq]))
    }
  }, [query])

  const [addQuestionMutation, { error: addQuestionError }] =
    useAddQuestionMutation()
  const [addCareerPathQuestionMutation] = useAddCareerPathQuestionMutation()
  const [primeLeagueQuizMutation, { error: primeLeagueQuizError }] =
    usePrimeLeagueQuizMutation()

  const {
    id,
    quizName,
    setQuizName,
    promotionalText,
    setPromotionalText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    language,
    setLanguage,
    noExpirationDate,
    setNoExpirationDate,
    randomizeOrder,
    setRandomizeOrder,
    selectedCategories,
    setSelectedCategories,
    load,
    loadStartState,
  } = useFinishContext()

  const t = useT(`${language}`)

  useEffect(() => {
    if (language) return
    if ((languageLeague?.length ?? 0) > 0) {
      if (
        !languageLeague?.includes(Locale.en) &&
        languageLeague?.includes(Locale.no)
      ) {
        setLanguage(Locale.no)
      } else {
        setLanguage(languageLeague![0])
      }
    } else {
      setLanguage(Locale.en)
    }
  }, [languageLeague])

  // Displays the "Successfully uploaded quiz"
  useEffect(() => {
    if (uploadingQuiz && !isLoading) {
      setTimeout(() => {
        props.resetQuestions()
        setUploadingQuiz(false)
        props.setFinishedOverview(true)
        window.localStorage.removeItem(props.leagueSlug)
      }, 2000)
    }
  }, [uploadingQuiz, isLoading])

  if (addQuizError || addQuestionError || primeLeagueQuizError) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Heading level={4}>
          {t(`Something went wrong while publishing the quiz`)}
        </Heading>
        <Text size="small">
          {t(
            `Please refresh the page and try again, if this keeps occuring, contact support with info:`
          )}
        </Text>
        <Flex gap="small" vertical="center">
          {addQuestionError && <Text size="small">addQuestion</Text>}
          {addQuizError && <Text size="small">addQuiz</Text>}
          {primeLeagueQuizError && <Text size="small">primeLeagueQuiz</Text>}
          <Text>-</Text>
          <Text size="small">
            {addQuizError?.message ||
              addQuestionError?.message ||
              primeLeagueQuizError?.message}
          </Text>
        </Flex>
      </Flex>
    )
  }

  if (!query.data?.league) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Heading level={3}>{t(`Could not find series`)}</Heading>
      </Flex>
    )
  }

  if (!language) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Heading level={3}>{t(`Loading locale...`)}</Heading>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Loader />
        <Heading level={3}>
          {uploadIndex}/{props.questions.length}
        </Heading>
      </Flex>
    )
  }
  if (uploadingQuiz) {
    return (
      <Flex horizontal="center">
        <Heading
          level={3}
          css={css`
            opacity: 0; /* Set initial opacity to 0 */
            animation: fadeIn 2s ease-in-out forwards;

            @keyframes fadeIn {
              0%,
              100% {
                opacity: 0; /* Start with opacity 0 */
              }
              10%,
              90% {
                opacity: 1; /* End with opacity 1 */
              }
            }
          `}
        >
          {t(`Successfully uploaded quiz`)}
        </Heading>
      </Flex>
    )
  }
  return (
    <Flex
      horizontal="center"
      column
      gap="medium"
      css={css`
        margin-top: -40px;
        margin-bottom: 80px;
      `}
    >
      <Flex
        css={css`
          position: absolute;
          margin-top: -60px;
        `}
        gap="huge"
        vertical="center"
      >
        {props.showIsAdmin && (
          <Flex gap="small">
            <Heading
              level={6}
              strong={false}
              css={css`
                margin: auto 0;
              `}
            >
              Admin UI:
            </Heading>
            <input
              type="checkbox"
              checked={props.isAdmin}
              onChange={(event) => {
                props.setIsAdmin(event.target.checked)
              }}
              css={css`
                margin: 0;
                height: 30px;
                width: 30px;
              `}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        gap="medium"
      >
        <Flex
          css={css`
            flex: 1;
          `}
          column
        >
          <Text size="medium">{t(`Quiz`)}</Text>
          <TextInput
            type={'text'}
            value={`${query.data?.league?.title}`}
            onValue={() => {}}
            textCss={css`
              border-color: black;
              color: black;
            `}
            disabled
            lock
          />
        </Flex>
        <Flex
          css={css`
            width: 25%;
          `}
          column
          vertical="flex-end"
        >
          <TextInput
            type={'text'}
            value={leagueFrequencyToString(t, freq)}
            onValue={() => {}}
            textCss={css`
              border-color: black;
              color: black;
            `}
            disabled
            lock
          />
        </Flex>
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        gap="medium"
      >
        <Flex
          css={css`
            flex: 1;
          `}
          column
        >
          <Text size="tiny">{t(`Quiz Episode`)}</Text>
          <TextInput
            type={'text'}
            value={quizName}
            textCss={css`
              border-color: black;
            `}
            onValue={setQuizName}
            error={fieldErrors.quizName}
          />
        </Flex>
        <Flex
          css={css`
            width: 25%;
          `}
          column
        >
          <Text size="tiny">{t(`Language`)}</Text>
          <SelectInput
            value={language}
            onValue={(value: any) => {
              if (language !== value && load) {
                if (loadStartState !== props.jsonData()) {
                  if (
                    !confirm(
                      'You have unsaved changes. Do you still want to change language?'
                    )
                  ) {
                    return
                  }
                }
              }
              setLanguage(value)
            }}
            selectCss={css`
              height: 40px;
              border-radius: 5px;
              font-size: 20px;
            `}
          >
            {locales.map((it) => (
              <option value={it} key={it}>
                {localeToFlag[it]}
              </option>
            ))}
          </SelectInput>
        </Flex>
      </Flex>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Flex
          css={css`
            width: 100%;
          `}
          gap="medium"
        >
          <Flex
            css={css`
              flex: 1;
            `}
            column
          >
            <Text size="tiny">{t(`Start date`)}</Text>
            <DateTimePicker
              format="DD-MM-YYYY HH:mm"
              ampm={false}
              onOpen={() => {}}
              css={css`
                border-radius: 5px;

                * {
                  max-height: 40px;
                  z-index: 1;
                }

                fieldset {
                  max-height: 50px;
                  border-color: black;
                  background-color: white;
                  z-index: 0;
                }
              `}
              value={startDate}
              onChange={setStartDate}
              slotProps={{
                ...{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                },
                ...(defaultStartDate
                  ? {
                      textField: {
                        placeholder: `${t(
                          `Default`
                        )}: ${defaultStartDate.format('DD/MM/YYYY hh:mm')}`,
                      },
                    }
                  : {}),
              }}
              sx={
                defaultStartDate
                  ? {
                      input: {
                        color: 'black',
                        '&::placeholder': {
                          opacity: 1,
                          color: colors.grey400,
                        },
                      },
                    }
                  : {}
              }
            />
            {fieldErrors.startDate && (
              <ErrorMessage>{fieldErrors.startDate}</ErrorMessage>
            )}
          </Flex>

          <Flex
            css={css`
              flex: 1;
            `}
            column
          >
            <Text size="tiny">{t(`End date`)}</Text>
            <DateTimePicker
              format="DD-MM-YYYY HH:mm"
              ampm={false}
              disabled={noExpirationDate}
              onOpen={() => {}}
              css={css`
                border-radius: 5px;

                * {
                  max-height: 40px;
                  z-index: 1;
                }

                fieldset {
                  max-height: 50px;
                  border-color: black;
                  background-color: white;
                  z-index: 0;
                }
              `}
              value={endDate}
              onChange={setEndDate}
              slotProps={{
                ...{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                },
                ...(defaultEndDate
                  ? {
                      textField: {
                        placeholder: `${t(`Default`)}: ${defaultEndDate.format(
                          'DD/MM/YYYY hh:mm'
                        )}`,
                      },
                    }
                  : {}),
              }}
              sx={
                defaultEndDate
                  ? {
                      input: {
                        color: 'black',
                        '&::placeholder': {
                          opacity: 1,
                          color: colors.grey400,
                        },
                      },
                    }
                  : {}
              }
            />
            {fieldErrors.endDate && (
              <ErrorMessage>{fieldErrors.endDate}</ErrorMessage>
            )}
          </Flex>
        </Flex>
      </LocalizationProvider>
      <Flex
        css={css`
          width: 100%;
        `}
        gap="medium"
      >
        <Flex
          css={css`
            flex: 1;
          `}
        />
        <Flex
          css={css`
            flex: 1;
          `}
          gap="small"
          vertical="center"
          horizontal="flex-end"
        >
          <Text size="tiny">{t(`No expiration date`)}</Text>
          <input
            type="checkbox"
            checked={noExpirationDate ?? false}
            onChange={(event) => {
              setNoExpirationDate(event.target.checked)
            }}
            css={css`
              margin: 0;
              height: 20px;
              width: 20px;
            `}
          />
        </Flex>
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        column
      >
        <Text size="tiny">{t(`Promotional Text`)}</Text>
        <TextInput
          type={'text'}
          value={promotionalText}
          textCss={css`
            border-color: black;
          `}
          onValue={setPromotionalText}
        />
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        column
      >
        <Text size="tiny">{t(`Tags`)}</Text>
        <SelectCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          languageOverride={language}
        />
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        column
        gap="small"
      >
        <Flex>
          <Text size="medium">{t(`Questions`)}</Text>
          <Flex
            css={css`
              flex: 1;
            `}
            gap="small"
            vertical="center"
            horizontal="flex-end"
          >
            <Text size="tiny">{t(`Randomize order`)}</Text>
            <input
              type="checkbox"
              checked={randomizeOrder ?? false}
              onChange={(event) => {
                setRandomizeOrder(event.target.checked)
              }}
              css={css`
                margin: 0;
                height: 20px;
                width: 20px;
              `}
            />
          </Flex>
        </Flex>
        {[...new Array(numberOfQuestions).keys()].map((index: number) => {
          const question = props.questions[index]
          return (
            <Flex
              key={index}
              css={css`
                width: 100%;
                border-radius: 5px;
              `}
              horizontal="space-between"
              vertical="center"
              gap="small"
            >
              <Text
                css={css`
                  width: 30px;
                `}
              >
                Q{index + 1}
              </Text>
              <TextInput
                css={css`
                  flex: 1;
                `}
                value={`${
                  (question &&
                    (!('careerPathPlayer' in question)
                      ? question?.question
                      : question && 'careerPathPlayer' in question
                      ? question?.careerPathPlayer?.playerName
                      : '')) ??
                  ''
                }`}
                textCss={css`
                  border-color: black;
                `}
                onValue={() => {}}
                onFocus={() => {
                  const edit = index < props.questions.length
                  if (edit) {
                    props.setEditQuestionIndex(index)
                  } else {
                    props.setEditQuestionIndex(-1)
                  }
                  props.addMoreQuestions(edit)
                }}
                disabled={index > props.questions.length}
              />
              {question &&
              !('careerPathPlayer' in question) &&
              question?.question ? (
                <img
                  src="/thumbs_up2.png"
                  css={css`
                    position: absolute;
                    right: 0px;
                    width: 22px;
                  `}
                ></img>
              ) : question &&
                'careerPathPlayer' in question &&
                question?.careerPathPlayer ? (
                <img
                  src="/thumbs_up2.png"
                  css={css`
                    position: absolute;
                    right: 0px;
                    width: 22px;
                  `}
                ></img>
              ) : null}
            </Flex>
          )
        })}

        <Flex horizontal={'center'} vertical={'center'}>
          <PrimaryButton
            css={css`
              background-color: ${colors.green300};
              width: 75%;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: ${props.questions.length < 8 ? 0.5 : 1};
            `}
            onClick={() => {
              setNumberOfQuestions(numberOfQuestions + 1)
            }}
            disabled={props.questions.length < 8}
          >
            <BiPlus size={20} />
            <Text>Increase quiz length</Text>
          </PrimaryButton>
        </Flex>
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        horizontal="flex-end"
        gap="small"
      >
        {fieldErrors.questions && (
          <Flex column vertical="center">
            <ErrorMessage>{fieldErrors.questions}</ErrorMessage>
          </Flex>
        )}
        <PrimaryButton
          css={css`
            padding-left: 40px;
            padding-right: 40px;
          `}
          onClick={async () => {
            const fieldErrors: { [key: string]: string } = {}
            if (!quizName) {
              fieldErrors.quizName = 'Quiz title must be filled out'
            }
            if (!startDate && !defaultStartDate) {
              fieldErrors.startDate = 'Start date must be set'
            }
            const finalStartDate = startDate ?? defaultStartDate
            const finalEndDate = noExpirationDate
              ? undefined
              : endDate ?? defaultEndDate
            if (
              finalStartDate &&
              finalEndDate &&
              finalEndDate < finalStartDate
            ) {
              fieldErrors.endDate = 'End date must be later than start date'
            }
            for (const question of props.questions) {
              if (!('careerPathPlayer' in question) && !question?.question) {
                // This can only happen if you edit quizzes
                // as there is a check for this at GeneratePage
                fieldErrors.questions = 'Some questions are unfilled'
              } else if (
                'careerPathPlayer' in question &&
                (!question?.id || !question?.careerPathPlayer?.careerPath)
              ) {
                fieldErrors.questions = 'Some questions are unfilled'
              }
            }
            if (props.isAdmin) {
              if (props.questions.length == 0) {
                fieldErrors.questions = 'Atleast 1 question is required'
              } else if (props.questions.length !== 8) {
                if (
                  Object.keys(fieldErrors).length == 0 &&
                  !confirm(
                    '8 questions are required, but as an Admin you can override this requirement. Do you want to publish?'
                  )
                ) {
                  fieldErrors.questions = '8 questions are required'
                }
              }
            }
            if (!props.isAdmin) {
              if (props.questions.length !== 8) {
                fieldErrors.questions = '8 questions are required'
              }
            }
            setFieldErrors(fieldErrors)
            if (Object.keys(fieldErrors).length > 0) {
              return
            }
            setUploadIndex(0)
            setIsLoading(true)

            for (let n = 0; n < props.questions.length; n++) {
              const question = props.questions[n]
              const questionLoad = load?.questions[n]

              try {
                if (
                  !(question && 'careerPathPlayer' in question) &&
                  !(questionLoad && 'careerPathPlayer' in questionLoad)
                ) {
                  await addQuestionToSanity(
                    question,
                    language,
                    selectedCategories,
                    addQuestionMutation,
                    props.leagueSlug,
                    questionLoad
                  )
                } else if (question && 'careerPathPlayer' in question) {
                  await addCareerPathQuestionToSanity(
                    question,
                    addCareerPathQuestionMutation,
                    props.leagueSlug
                  )
                }
              } catch (error) {
                //Error is handled earlier in code
                console.error('Error adding question to sanity', error)
                return
              }
              setUploadIndex(n + 1)
            }
            try {
              const addQuizResult = await addQuizMutation({
                variables: {
                  input: {
                    id: id,
                    leagueSlug: props.leagueSlug,
                    title: {
                      ...retrieveLoad(load, 'title'),
                      [language]: quizName,
                    },
                    promotionalText: {
                      ...retrieveLoad(load, 'promotionalText'),
                      [language]: promotionalText,
                    },
                    randomizeOrder: randomizeOrder,
                    startDate: finalStartDate!.toISOString(),
                    endDate: finalEndDate?.toISOString(),
                    questionIds: props.questions.map((it) => it.id),
                    language: language,
                  },
                },
              })
              if (addQuizResult.data?.addQuiz == 'Success') {
                await primeLeagueQuizMutation({
                  variables: {
                    input: { leagueSlug: props.leagueSlug, quizId: id },
                  },
                })
                setIsLoading(false)
                setUploadingQuiz(true)
              } else {
                alert('Something went wrong while uploading the quiz')
              }
            } catch {
              //Error is handled earlier in code
              return
            }
          }}
        >
          {t(`Publish`)}
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}
