/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useCallback, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import { useT } from '../lib/i18n/useT'
import { getMaskCss, MaskShape } from '../lib/imageMask'
import { margin } from '../styles/margin'
import { useCrop } from '../utils/useCrop'
import { SecondaryButton } from './Button'
import { Flex } from './layout/Flex'
import { Text } from './Text'

type UploadImage = {
  file: File
  previewSrc: string
}

type UploadImageFormProps = {
  label: string
  currentImage?: string
  setCropped: (crop: {
    left: number
    top: number
    width: number
    height: number
    file: File
  }) => void
  minWidth: number
  minHeight: number
  maskShape?: MaskShape
  textColor?: string
}

export const CroppedImageForm: FC<UploadImageFormProps> = ({
  label,
  currentImage,
  setCropped,
  minWidth,
  minHeight,
  maskShape,
  textColor = 'black',
}) => {
  const t = useT()
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [selectedImage, setSelectedImage] = useState<UploadImage | null>(null)

  const [crop, setCrop] = useCrop({
    minHeight,
    minWidth,
  })
  const [editMode, setEditMode] = useState(!currentImage)
  const updateCropped = useCallback(() => {
    if (selectedImage) {
      setCropped({
        left: crop.x!,
        top: crop.y!,
        width: crop.width!,
        height: crop.height!,
        file: selectedImage.file,
      })
    }
  }, [crop.height, crop.width, crop.x, crop.y, selectedImage, setCropped])
  const onLoad = useCallback(
    (img: HTMLImageElement) => {
      imgRef.current = img
    },
    [imgRef]
  )
  return (
    <Flex column>
      <Text
        css={css`
          max-width: 360px;
          ${margin.bottom('small')}
          color: ${textColor};
        `}
      >
        {label}
      </Text>
      {editMode ? (
        <Flex gap="medium">
          {selectedImage && (
            <Flex
              css={css`
                max-width: 100%;
                img {
                  max-width: 100%;
                }
              `}
            >
              <ReactCrop
                locked
                src={selectedImage.previewSrc}
                crop={crop}
                onChange={(_crop, percentCrop) => setCrop(percentCrop)}
                onImageLoaded={onLoad}
                onComplete={updateCropped}
                style={
                  selectedImage.file.type === 'image/svg+xml'
                    ? { flex: 1 }
                    : undefined
                } // fixes bug where svg doesn't have width or height: ;
              />
            </Flex>
          )}
          <input
            type="file"
            accept="image/png,image/jpeg,image/svg+xml"
            onChange={(e) => {
              const file = e.target.files?.[0]
              if (file) {
                setSelectedImage({
                  file,
                  previewSrc: URL.createObjectURL(file),
                })
              }
            }}
            css={[
              margin.top(),
              css`
                color: white;
              `,
            ]}
          />
        </Flex>
      ) : (
        <Flex vertical="center" gap="medium">
          {currentImage && (
            <img // TODO fix
              //layout="fixed"
              height={minHeight}
              width={minWidth}
              src={currentImage}
              alt={t('Profile picture')}
              //quality={90}
              css={css`
                ${maskShape && getMaskCss(maskShape)};
                object-fit: contain;
              `}
            />
          )}
          <SecondaryButton
            onClick={() => setEditMode(true)}
            variant={textColor === 'white' ? 'white' : 'black'}
          >
            {t('Edit')}
          </SecondaryButton>
        </Flex>
      )}
    </Flex>
  )
}
