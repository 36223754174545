/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import {
  calculateScoreHeaderHeight,
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { LeagueQuizCampaign } from '../../views/LeagueQuiz/queries'
import { Flex } from '../layout/Flex'
import { AdSize, RevIqAdComponent } from '../reviq/RevIqAdComponent'
import { SanityCampaignHeader } from './CampaignHeader'
import { useDetectAdBlock } from './useDetectAdBlock'
import { isProduction } from '../../config/config'

const QuizAd = ({
  isEmbed = false,
  campaign,
  isChallenge = false,
  isDailyCareerPath = false,
  leagueId,
  quizId,
}: {
  isEmbed?: boolean
  campaign?: LeagueQuizCampaign
  isChallenge?: boolean
  isDailyCareerPath?: boolean
  leagueId?: string
  quizId?: string
}) => {
  const adblockDetected = useDetectAdBlock({ skip: !!campaign })
  const { quizState, isExpandedAdActive } = useLeagueQuizContext()

  const showReviqAdsLeagues = [
    '43690fe5-aadc-45a3-9558-545472ff5276', // fjortoft
    '619d03b0-7664-4407-9afb-cfc691d8780e', // eliteserien 2023
  ]

  // In production, only show reviq ads for certain leagues
  const showReviqAds = isProduction
    ? leagueId && showReviqAdsLeagues.includes(leagueId)
    : true

  const expand = keyframes`
      from {
        height: 33vh;
      }
      to {
        height: ${calculateScoreHeaderHeight};
      }
    `

  const expandCountdown = keyframes`
        from {
            height: 0;
        }
        to {
            height: 33vh;
        }
    `

  const collapse = keyframes`
        from {
          height: ${calculateScoreHeaderHeight};
        }
        to {
            height: 86px;
        }
    `

  if (quizState === QuizState.START_SCREEN) {
    return null
  }

  if (campaign) {
    return (
      <Flex
        horizontal={'center'}
        css={css`
          width: 100%;
          height: ${quizState === QuizState.COUNTDOWN
            ? '33vh'
            : isExpandedAdActive
            ? calculateScoreHeaderHeight
            : '110px'};
          ${quizState != QuizState.COUNTDOWN
            ? isExpandedAdActive && campaign?.header?.expandedImageUrl
              ? css`
                  animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                    forwards;
                `
              : !isExpandedAdActive &&
                css`
                  animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                    forwards;
                `
            : campaign.countdownImageUrl &&
              css`
                animation: ${expandCountdown} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1) forwards;
              `}
        `}
      >
        <SanityCampaignHeader
          campaign={campaign}
          isEmbed={isEmbed}
          isChallenge={isChallenge}
          isDailyCareerPath={isDailyCareerPath}
        />
      </Flex>
    )
  }

  if (!isEmbed && showReviqAds) {
    return (
      <Flex
        horizontal="center"
        vertical="center"
        css={css`
          height: ${isExpandedAdActive ? calculateScoreHeaderHeight : '0px'};
          overflow: hidden;
          ${isExpandedAdActive
            ? css`
                animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `
            : css`
                animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `}
        `}
      >
        <RevIqAdComponent
          adSize={AdSize.MEDIUM_RECTANGLE}
          visible={isExpandedAdActive}
          trackEvent={() => {
            trackEvent(AnalyticsEventType.RevIqAdImpression, {
              leagueId,
              adSize: AdSize.MEDIUM_RECTANGLE,
              quizId,
              isEmbed,
              adblockDetected,
            })
          }}
        />
      </Flex>
    )
  }

  return null
}

export default QuizAd
