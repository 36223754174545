/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/Button'
import { ClubLogo } from '../../components/Picture'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { ClubLeaderboardEntry } from '../League/queries'
import { colorIsLight } from '../Admin/Utils'
import { colors, hexOpacity } from '../../styles/theme'

const CloseOpen = ({
  hideLeaderboard,
  setHideLeaderboard,
}: {
  hideLeaderboard: boolean
  setHideLeaderboard: (value: boolean) => void
}) => (
  <Button
    resetStyling
    css={css`
      position: absolute;
      left: 50%;
      bottom: 0px;
      padding-top: 5px;
      height: 25px;
      width: 100%;
      z-index: 2;
      transform: translateX(-50%);
    `}
    onClick={(e) => {
      const wasHidden =
        window.localStorage.getItem('hideLeaderboard') === 'true'
      window.localStorage.setItem(
        'hideLeaderboard',
        wasHidden ? 'false' : 'true'
      )
      setHideLeaderboard(!wasHidden)
      e.stopPropagation()
      e.preventDefault()
    }}
  >
    <Flex column horizontal="center" gap={2}>
      <img
        src="/chevron_right.svg"
        css={css`
          rotate: ${hideLeaderboard ? '' : '-'}90deg;
          filter: invert(0.75);
          height: 50px;
          width: 20px;
          margin-top: -15px;
          border-radius: 2px;
          transition: rotate 0.25s ease-in-out;
        `}
      />
    </Flex>
  </Button>
)

export const SpecialLeaderboard = ({
  slug,
  clubsLeaderboard,
  leagueColor,
}: {
  slug: string
  clubsLeaderboard: ClubLeaderboardEntry[]
  leagueColor: string
}) => {
  const t = useT()

  const [hideLeaderboard, setHideLeaderboard] = useState(true)

  useEffect(() => {
    setHideLeaderboard(localStorage.getItem('hideLeaderboard') === 'true')
  }, [])

  const textColor = colorIsLight(leagueColor) ? 'black' : 'white'
  const ctaColor = textColor
  const textOnCtaColor = ctaColor === 'black' ? 'white' : 'black'

  return (
    <Link
      css={css`
        text-decoration: none;
        flex: 1;
      `}
      to={`/series/${slug}/total`}
      aria-label={t('To the series page')}
    >
      <Flex
        css={css`
          margin-top: ${hideLeaderboard ? '-155' : '-15'}px;
          max-width: 720px;
          flex-grow: 1;
          position: relative;

          padding: 30px 10px;

          border-radius: 10px;

          background-color: ${leagueColor};

          transition: margin-top 0.25s ease-in-out;
        `}
        horizontal="center"
        gap="medium"
      >
        <Flex
          css={css`
            height: 120px;
            width: 100%;
            gap: 6px;
          `}
          horizontal="center"
          column
        >
          {clubsLeaderboard.map((clubEntry) => (
            <div
              key={clubEntry.id}
              css={css`
                width: 100%;
                height: 100%;
                text-decoration: none;
              `}
            >
              <Flex
                css={css`
                  width: 100%;
                  background-color: ${hexOpacity(colors.black, 0.5)};
                  border-radius: 50px;
                  height: 35px;
                  padding: 15px;
                `}
                vertical="center"
                horizontal="space-between"
              >
                <Flex vertical="center" gap="small">
                  <TextNew
                    css={css`
                      width: 30px;
                    `}
                    strong
                    italic
                    size={24}
                    condensed
                    color={colors.white}
                  >
                    {clubEntry.rank}
                  </TextNew>
                  <ClubLogo
                    size={35}
                    url={clubEntry.club.logoUrl}
                    shape={clubEntry.club.logoShape}
                  />
                  <TextNew
                    strong
                    italic
                    size={24}
                    condensed
                    color={colors.white}
                  >
                    {clubEntry.club.name}
                  </TextNew>
                </Flex>
                <TextNew strong italic size={24} condensed color={colors.white}>
                  {clubEntry.score}
                </TextNew>
              </Flex>
            </div>
          ))}
        </Flex>
        <Flex
          css={css`
            max-width: 100%;
            padding: 0px 15px;
            position: relative;
            z-index: 1;
            @media (max-width: 599px) {
              display: none;
            }
          `}
          column
          vertical="center"
          horizontal="center"
          gap="medium"
        >
          <Text
            color={textColor}
            size="tiny"
            css={css`
              font-size: 11px;
              font-weight: 300;
              line-height: 1.2;
              height: 20px;
            `} //height: 20px; is necessary incase it wraps (in other languages)
          >
            {t(`Help your team claim the top of the leaderboard!`)}
          </Text>
          <Flex css={buttonReset}>
            <Flex
              css={css`
                height: 30px;
                background: ${ctaColor};
                border-radius: 40px;
                padding: 10px;
              `}
              horizontal="center"
              vertical="center"
              gap="tiny"
            >
              <Text
                color={textOnCtaColor}
                size="tiny"
                css={css`
                  font-weight: 300;
                  white-space: nowrap;
                `}
              >
                {t(`Join your Club`)}
              </Text>
              <img
                src={`/arrow-right.svg`}
                css={css`
                  filter: invert(${ctaColor === 'white' ? 1 : 0});
                `}
              ></img>
            </Flex>
          </Flex>
        </Flex>
        <CloseOpen
          hideLeaderboard={hideLeaderboard}
          setHideLeaderboard={setHideLeaderboard}
        />
      </Flex>
    </Link>
  )
}
