/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { LeagueQuiz } from '../../components/leagueQuiz/LeagueQuiz'
import { Page } from '../../components/leagueQuiz/Page'
import { Loader } from '../../components/Loader'
import { margin } from '../../styles/margin'
import theme from '../../styles/theme'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from './LeagueQuizContext'
import { useLeagueQuizInstanceQuery } from './queries'

const Content = ({
  quizId,
  quizType,
}: {
  quizId: string
  quizType?: string
}) => {
  const query = useLeagueQuizInstanceQuery(quizId, quizType)
  const { setHasSanityCampaign, setQuizId, setLeagueId, setInstanceId } =
    useLeagueQuizContext()

  useEffect(() => {
    if (query.data?.leagueQuizInstance) {
      setLeagueId(query.data.leagueQuizInstance.leagueQuiz.league.id)
      setQuizId(query.data.leagueQuizInstance.leagueQuiz.id)

      setInstanceId(query.data.leagueQuizInstance.id)
    }
  }, [quizId, setQuizId, query])

  useEffect(() => {
    if (
      query.data?.leagueQuizInstance?.leagueQuiz.campaign &&
      setHasSanityCampaign
    ) {
      setHasSanityCampaign(true)
    }
  }, [
    query.data?.leagueQuizInstance?.leagueQuiz.campaign,
    setHasSanityCampaign,
  ])

  if (query.loading) {
    return (
      <Page>
        <Flex horizontal="center" css={margin.top('huge')}>
          <Loader variant="green" />
        </Flex>
      </Page>
    )
  }
  if (!query.data?.leagueQuizInstance || query.error) {
    const reason = !query.data?.leagueQuizInstance
      ? 'no instance'
      : query.error?.message

    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`LeagueQuizPage | quizId: ${quizId}, ${reason}`}
        />
      </Page>
    )
  }

  return (
    <Flex
      column
      grow
      css={css`
        overflow-x: hidden;
      `}
    >
      <QuizAd
        campaign={query.data.leagueQuizInstance.leagueQuiz.campaign}
        isDailyCareerPath={
          query.data.leagueQuizInstance.leagueQuiz.league.slug ===
          'the-daily-career-path'
        }
        leagueId={query.data.leagueQuizInstance.leagueQuiz.league.id}
        quizId={query.data.leagueQuizInstance.leagueQuiz.id}
      />

      <LeagueQuiz
        leagueQuizInstance={query.data.leagueQuizInstance}
        isEmbed={false}
        colorTheme={'white'}
      />
    </Flex>
  )
}

const LeagueQuizPage = () => {
  const leagueQuizId = useParams<{ leagueQuizId: string }>().leagueQuizId!
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const quizType = searchParams.get('quizType')

  return (
    <LeagueQuizContextProvider>
      <Layout
        hideHeaderPadding
        hideNavbar
        backgroundColor={theme.colors.grey100}
        css={css`
          height: 100%;
        `}
      >
        <Content quizId={leagueQuizId} quizType={quizType ?? 'text'} />
      </Layout>
    </LeagueQuizContextProvider>
  )
}
export default LeagueQuizPage
