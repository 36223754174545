/** @jsxImportSource @emotion/react */

import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { TextInput } from '../../components/TextInput'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { useForm } from '../../utils/useForm'
import { useCreateClubMutation } from './queries'

export const CreateClubModal = ({ closeModal }: { closeModal: () => void }) => {
  const t = useT()
  const [createClub] = useCreateClubMutation()

  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) => (value ? undefined : t('Club name cannot be empty')),
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        await createClub({ variables: { input: { name: values.name } } })
        closeModal()
      } catch (e) {
        console.error('Failed to create club', e)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  return (
    <Modal heading={t('Create club')} closeModal={closeModal}>
      <TextInput
        value={form.values.name}
        onValue={(val) => form.setValue('name', val)}
        label={t('New club')}
        placeholder={t('Club name')}
      />
      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <Flex horizontal="flex-end" vertical="center" css={margin.top('large')}>
          <SecondaryButton disabled={form.submitting} onClick={closeModal}>
            {t('Cancel')}
          </SecondaryButton>
          <Spacer width="medium" />
          <PrimaryButton disabled={form.submitting} type="submit">
            {t('Create')}
          </PrimaryButton>
        </Flex>
      </form>
    </Modal>
  )
}
