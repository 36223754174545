import { useEffect } from 'react'

declare global {
  interface Window {
    Cookiebot?: {
      renew?: () => void
      withdraw: () => void
      // Add other methods here if needed with appropriate signatures
      consent?: {
        necessary: boolean
        preferences: boolean
        statistics: boolean
        marketing: boolean
      }
    }
  }
}

export const Cookiebot = () => {
  useEffect(() => {
    // Don't load Cookiebot on embed pages
    if (window.location.pathname.includes('embed')) {
      const cookiebotScript = document.getElementById('Cookiebot')
      if (cookiebotScript) {
        cookiebotScript.remove()
      }
    }
  }, [])

  return null
}
