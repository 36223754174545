/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { Flex } from '../../components/layout/Flex'
import { CHALLENGE_MODE_MAX_W } from '../../constants'
import { useViewerQuery } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { ChallengeLeagueQuizInstance } from '../ChallengeMatch/queries'
import { MatchComponent } from './ChallengeMatchComponent'

export const CHALLENGE_HEADER_HEIGHT = '140px'

export const ChallengeQuizHeader = ({
  instance,
}: {
  instance: ChallengeLeagueQuizInstance
}) => {
  const { data: viewerData, refetch } = useViewerQuery()
  const t = useT()

  useEffect(() => {
    refetch()
  }, [instance.id])

  const viewer = viewerData?.viewer
  const challengeUser = instance.challengeUser
  const viewerCorrect = instance.challengeCompletedQuestions.filter((q) =>
    q.alternatives.some((a) => a.isCorrectAlternative && a.selectedAlternative)
  ).length

  const challengeUserCorrect = instance.challengeCompletedQuestions.filter(
    (q) =>
      q.alternatives.some(
        (a) => a.isCorrectAlternative && a.challengeUserSelected
      )
  ).length

  const hasActiveQuestion = !!instance.activeQuestion

  const completed =
    instance.challengeCompletedQuestions.length + (hasActiveQuestion ? 1 : 0)
  const questionsCount = instance.leagueQuiz.questionsCount
  const hasCampaign = !!instance.leagueQuiz.campaign

  return (
    <Flex
      column
      horizontal="center"
      vertical="center"
      css={css`
        padding-top: 24px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      `}
    >
      <MatchComponent
        css={css`
          margin-top: ${hasCampaign ? '-16px' : '0'};
          z-index: 1;
          max-width: ${CHALLENGE_MODE_MAX_W}px;
        `}
        fcQuizLogo
        round={t('Round {{round}}', {
          round: `${completed}/${questionsCount}`,
        })}
        firstuserResults={{
          userId: viewer?.id ?? '',
          displayName: viewer?.displayName ?? t('You'),
          avatarData: viewer?.avatarData,
          result: {
            correctAnswersCount: viewerCorrect,
            totalScore: 0,
            timeBonus: 0,
            correctAnswerScore: 0,
          },
        }}
        secondUserResult={{
          userId: challengeUser?.id ?? '',
          displayName: challengeUser?.displayName,
          avatarData: challengeUser?.avatarData,
          result: {
            correctAnswersCount: challengeUserCorrect,
            totalScore: 0,
            timeBonus: 0,
            correctAnswerScore: 0,
          },
        }}
      />
    </Flex>
  )
}
