/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { TextNew } from '../../../components/TextNew'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import { useViewerQuery } from '../../../lib/AuthContext'
import { langShortToLang, Locale, localeToFlag } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { ChallengeLeagueMinimal } from '../../Challenge/queries'
import { LanguagePicker } from '../../Clubs/ClubProfilePage/EmbedCodeModal'
import { useHostLiveQuizMutation } from '../queries'

export const HostLiveQuizModal = ({
  closeModal,
  quiz,
  league,
}: {
  quiz: { id: string; title: string }
  closeModal: () => void
  league?: ChallengeLeagueMinimal
}) => {
  const viewerQuery = useViewerQuery()
  const [hostLiveQuizMutation] = useHostLiveQuizMutation()

  const [startTime, setStartTime] = useState<Dayjs | null>(
    dayjs().add(15, 'minutes')
  )

  const [locale, setLocale] = useState<Locale>(
    league?.locale || viewerQuery.data?.viewer?.locale || Locale.en
  )
  const t = useT(locale)

  const [useStartTime, setUseStartTime] = useState<boolean>(!!league)

  const DatePicker = () => {
    return (
      <Flex
        column
        css={css`
          background-color: ${colors.grey200};
          padding: 16px;
          border-radius: 8px;
        `}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            format="DD-MM-YYYY HH:mm"
            ampm={false}
            value={startTime}
            onOpen={() => {}}
            onChange={setStartTime}
            minDate={dayjs()}
            css={css`
              background-color: ${colors.white};
              border-radius: 8px;
            `}
            slotProps={{
              ...{
                actionBar: {
                  actions: ['clear', 'accept'],
                },
              },
              ...(startTime
                ? {
                    textField: {
                      placeholder: `${t(`Default`)}: ${startTime.format(
                        'DD/MM/YYYY hh:mm'
                      )}`,
                    },
                  }
                : {}),
            }}
          />
        </LocalizationProvider>
        <Spacer height={12} />
        {startTime && (
          <TextNew>
            {t(
              `The live quiz will start at {{ startTime }}, and will be joinable 15 minutes before start.`,
              {
                startTime: startTime.format('DD/MM/YYYY HH:mm'),
              }
            )}
          </TextNew>
        )}

        {!startTime && (
          <TextNew>
            {t(`Start time not set, the quiz will be joinable immediately`)}
          </TextNew>
        )}
      </Flex>
    )
  }

  const navigate = useNavigate()
  return (
    <Modal closeModal={closeModal} heading={`Host: ${quiz.title}`}>
      <Flex column css={css``}>
        <LanguagePicker locale={locale} onLocale={setLocale} />
        <Spacer height={12} />
        <ToggleSwitch
          label={t(`Start time`)}
          value={useStartTime}
          onValue={setUseStartTime}
        />
        <Spacer height={12} />
        {useStartTime && <DatePicker />}

        {!useStartTime && (
          <TextNew textAlign="center">
            {t(`The quiz will start immediately after going live.`)}
          </TextNew>
        )}

        <Spacer height={24} />

        <TextNew
          textAlign="center"
          css={css`
            line-height: 24px;
          `}
        >
          {t(
            `Text, questions and answers on the display screen will all be in: {{locale}}`,
            {
              locale: langShortToLang[locale].toUpperCase(),
            }
          )}
        </TextNew>
        <TextNew textAlign="center" size={80}>
          {localeToFlag[locale]}
        </TextNew>
        <Spacer height={16} />
        <PrimaryButton
          onClick={async () => {
            const res = await hostLiveQuizMutation({
              variables: {
                input: {
                  quizId: quiz.id,
                  leagueId: league?.id,
                  startAt:
                    useStartTime && startTime
                      ? new Date(startTime.toISOString())
                      : undefined,

                  locale: locale,
                },
              },
            })

            if (res.data?.hostLiveQuiz.id) {
              navigate(`/live-host/${res.data.hostLiveQuiz.id}`)
            }
          }}
        >
          <TextNew condensed italic size={'large'} color={colors.white}>
            {t('Go live!')}
          </TextNew>
        </PrimaryButton>
      </Flex>
    </Modal>
  )
}
