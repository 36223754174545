/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { ErrorMessage, TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import {
  useGetLiveQuizHostInstancesQuery,
  useJoinLiveQuizMutation,
} from '../LiveQuiz/queries'
import { useEffect, useState } from 'react'

const Content = ({ inviteCode }: { inviteCode?: string }) => {
  const t = useT()

  const [joinLiveQuizMutation] = useJoinLiveQuizMutation()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (buttonDisabled) {
        setButtonDisabled(false)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [buttonDisabled])

  const hostInstancesQuery = useGetLiveQuizHostInstancesQuery()

  const inCompleteHostInstances =
    hostInstancesQuery.data?.getLiveQuizHostInstances.filter(
      (instance) => instance.status !== 'completed'
    ) ?? []

  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      code: inviteCode ? inviteCode.slice(0, 6).toUpperCase() : '',
    },
    validate: {
      code: (value) => {
        if (value.length !== 6) {
          return 'Invalid code'
        }
      },
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        setSubmitError(undefined)
        const res = await joinLiveQuizMutation({
          variables: {
            input: {
              invitationCode: values.code,
            },
          },
        })

        trackEvent(AnalyticsEventType.LiveQuizJoined, {
          code: values.code,
        })

        const instanceId = res.data?.joinLiveQuiz?.id

        if (!instanceId) {
          setSubmitError(t('Something went wrong, try again'))
          return
        }

        navigate(`/live-quiz/${instanceId}`)
      } catch (error) {
        if (hasErrorCode(error, ErrorCode.INVALID_INVITATION_CODE)) {
          setSubmitError(t(`Invalid code`))
        } else if (hasErrorCode(error, ErrorCode.QUIZ_ALREADY_STARTED)) {
          setSubmitError(t('You have already completed this quiz'))
          setButtonDisabled(true) // disable button for 3 seconds to avoid spamming
        } else if (hasErrorCode(error, ErrorCode.MAX_CAPACITY_REACHED)) {
          setSubmitError(t(`The max capacity of this quiz has been reached`))
          setButtonDisabled(true) // disable button for 3 seconds to avoid spamming
        } else {
          setSubmitError(t('Something went wrong, try again'))
        }
      }
    },
  })

  const validCode = form.isValid

  return (
    <Flex column grow>
      <PageContent>
        <HeadingNew level={1}>JOIN</HeadingNew>

        <Spacer height={'medium'} />

        <form
          onSubmit={(event) => {
            event.preventDefault()
            form.submitForm()
          }}
        >
          <Flex column>
            <Spacer height={'medium'} />
            <TextInput
              id="code"
              name="code"
              autoComplete="off"
              textCss={css`
                text-align: center;
                font-family: 'Roboto Mono', monospace;
                letter-spacing: 0.5em;
              `}
              placeholder="1A2B3C"
              maxLength={6}
              value={form.values.code}
              onValue={(val) => {
                form.setValue('code', val.toUpperCase())
              }}
            />
            <Spacer height={'medium'} />
            <PrimaryButton
              signUpRequired
              disabled={!validCode || buttonDisabled}
              onClick={async () => {
                await form.submitForm()
              }}
              loading={form.submitting}
            >
              Join
            </PrimaryButton>

            {form.submitError && (
              <div css={margin.top()}>
                <ErrorMessage>{form.submitError}</ErrorMessage>
              </div>
            )}
            {!validCode && (
              <>
                <Spacer height={'medium'} />
                <TextNew textAlign="center" italic color={colors.grey500}>
                  The 6 digit code is displayed on the host screen
                </TextNew>
              </>
            )}
          </Flex>
        </form>
        <Spacer height={80} />
        {inCompleteHostInstances.length > 0 && (
          <Flex
            gap={'medium'}
            column
            css={css`
              border-radius: 8px;
              padding: 16px;
            `}
          >
            <TextNew textAlign="center" size="large">
              {t(`Your incomplete live quizzes`)}
            </TextNew>
            {inCompleteHostInstances
              .filter((instance) => {
                // Filter out instances where the invitation code is not the same as the one entered
                // When scanning a QR code, the invitation code is automatically entered
                if (validCode) {
                  return (
                    instance.invitationCode.toUpperCase() ===
                    form.values.code.toUpperCase()
                  )
                }
                return true
              })
              .map((instance) => {
                const league = instance.league
                const quiz = instance.quiz

                const backgroundColor = league?.backgroundColor ?? colors.white
                const foregroundColor = league?.textColor ?? colors.black

                return (
                  <Link
                    to={`/live-host/${instance.id}`}
                    key={instance.id}
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <Flex
                      column
                      gap={'small'}
                      vertical="center"
                      horizontal="center"
                      css={css`
                        background-color: ${backgroundColor};
                        padding: 16px;
                        border-radius: 8px;
                      `}
                    >
                      {league && (
                        <TextNew italic color={foregroundColor} size="small">
                          {league.title}
                        </TextNew>
                      )}

                      {quiz && (
                        <TextNew color={foregroundColor} size="small">
                          {quiz.title}
                        </TextNew>
                      )}

                      <TextNew color={foregroundColor} size="small">
                        {t('{{count}} player', {
                          count: instance.participantCount,
                        })}
                      </TextNew>

                      <Spacer height={'small'} />

                      <TextNew color={foregroundColor} size="small">
                        {instance.invitationCode}
                      </TextNew>

                      <Flex
                        css={css`
                          background-color: ${foregroundColor};
                          padding: 8px 12px;
                          border-radius: 4px;
                        `}
                      >
                        <TextNew color={backgroundColor}>
                          {t('Re-enter')}
                        </TextNew>
                      </Flex>
                    </Flex>
                  </Link>
                )
              })}
          </Flex>
        )}
      </PageContent>
    </Flex>
  )
}

export const PlayerJoinLiveQuizPage = () => {
  const inviteCode = useParams<{ inviteCode: string }>().inviteCode
  return (
    <Layout backgroundColor={colors.grey200} showDownLoadButtons>
      <Content inviteCode={inviteCode} />
    </Layout>
  )
}
