/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { isDevelopment } from '../../config/config'
import { useT } from '../../lib/i18n/useT'
import { NavItem } from '../Home/Navbar'

export const AdminNavbar = ({
  activePage,
  className,
}: {
  activePage:
    | 'index'
    | 'email'
    | 'push'
    | 'clubs'
    | 'leagues'
    | 'questions'
    | 'analytics'
    | 'liveQuiz'
    | 'challenge-leagues'
    | 'loadtest'
    | 'advertisers'
  className?: string
}) => {
  const t = useT()

  return (
    <Flex wrap className={className} gap="large">
      <NavItem
        to="/admin/users"
        title={t('Users')}
        active={activePage === 'index'}
      />
      <NavItem
        to="/admin/email"
        title={t('Email')}
        active={activePage === 'email'}
      />
      <NavItem to="/admin/push" title="Push" active={activePage === 'push'} />
      <NavItem
        to="/admin/leagues"
        title="Leagues"
        active={activePage === 'leagues'}
      />
      <NavItem
        to="/admin/clubs"
        title="Clubs"
        active={activePage === 'clubs'}
      />
      <NavItem to="/ad-test" title="Ad test" active={false} />

      <NavItem
        to="/admin/quiz"
        title="Sync"
        active={activePage === 'questions'}
      />
      <NavItem
        to="/admin/analytics"
        title="Analytics"
        active={activePage === 'analytics'}
      />
      <NavItem
        to="/admin/live-quiz"
        title="Live Quiz"
        active={activePage === 'liveQuiz'}
      />
      <NavItem
        to="/admin/challenge-leagues"
        title="Challenge Leagues"
        active={activePage === 'challenge-leagues'}
      />
      <NavItem
        to="/admin/advertisers"
        title="Advertisers"
        active={activePage === 'advertisers'}
      />
      {isDevelopment && (
        <NavItem
          to="/admin/loadtest"
          title="Load test"
          active={activePage === 'loadtest'}
        />
      )}
    </Flex>
  )
}
