/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import 'quill/dist/quill.snow.css'
import { useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { ConfirmModal } from '../../components/ConfirmModal'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import {
  Locale,
  LocaleString,
  localeToFlag,
  locales,
} from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useTranslateLocaleStringQuery } from '../Translate/queries'
import { AdminNavbar } from './Navbar'
import { useSendNewsletterMutation, useUsersQuery } from './queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

const Content = () => {
  const t = useT()
  const theme = 'snow'
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  }
  const [subject, setSubject] = useState('')
  const [missingSubject, setMissingSubject] = useState(false)
  const { quillRef, quill } = useQuill({ theme, modules })
  const [sendNewsletter, { loading, data }] = useSendNewsletterMutation()
  const usersQuery = useUsersQuery()

  const [titles, setTitles] = useState<LocaleString>({
    en: '',
  })

  const [bodies, setBodies] = useState<LocaleString>({
    en: '',
  })
  const [showLocales, setShowLocales] = useState(false)
  const [oldLocale, setOldLocale] = useState<Locale>(Locale.en)
  const [locale, setLocale] = useState<Locale>(Locale.en)
  const [runQuery, translateLocaleString] = useTranslateLocaleStringQuery()
  const body = quill?.root.innerHTML
  const [bodyChanged, setBodyChanged] = useState(false)

  useEffect(() => {
    if (!quill) return
    quill.on('text-change', () => {
      setBodyChanged(true)
    })
  }, [quill])

  useEffect(() => {
    setBodyChanged(false)
    if (oldLocale !== locale) {
      setSubject(titles[locale] ?? '')
      if (quill) {
        quill.root.innerHTML = bodies[locale] ?? ''
      }
      setOldLocale(locale)
      return
    }
    if (titles[locale] !== subject) {
      setTitles({ ...titles, [locale]: subject })
    }
    const bodyEmpty =
      (body?.replace(/<(.|\n)*?>/g, '').trim().length ?? 0) === 0
    const _body = bodyEmpty ? '' : body //We want '' instead so that isEmpty == true
    if (bodies[locale] !== _body) {
      setBodies({ ...bodies, [locale]: _body })
    }
  }, [subject, bodyChanged, locale])

  const usersWithEmail = (usersQuery.data?.users ?? []).filter(
    (user) => user.email
  )

  return (
    <ConfirmModal
      onConfirm={() => {
        const content = body

        if (!content || !quill) {
          alert('An error occurred while sending newsletter.')
          return
        }

        return sendNewsletter({
          variables: { input: { subject: titles, content: bodies } },
        }).then((result) => {
          if (result.data?.sendNewsletter) {
            // reset form
            setSubject('')
            try {
              quill.setText('\n')
            } catch (_) {}
          }
        })
      }}
      button={({ openModal }) => (
        <form
          css={margin.top('large')}
          onSubmit={(e) => {
            e.preventDefault()

            if (!subject) {
              setMissingSubject(true)
              return
            }

            openModal()
          }}
        >
          <Flex
            css={css`
              padding: 8px;
              background: ${colors.yellow100};
              border-radius: 2px;
              border: 1px solid gray;
            `}
          >
            <Text>
              {t('Send email to {{count}} users', {
                count: usersWithEmail.length,
              })}
            </Text>
          </Flex>
          <Spacer height={16} />
          {showLocales && (
            <Flex gap="tiny">
              {locales.map((it) => (
                <SecondaryButton
                  key={it}
                  css={[
                    css`
                      border: 1px solid ${colors.grey200};
                      border-bottom: none;
                      border-bottom-left-radius: 0px;
                      border-bottom-right-radius: 0px;
                      padding-bottom: 0px;
                    `,
                    it !== locale &&
                      css`
                        background-color: ${colors.grey200};
                      `,
                  ]}
                  onClick={() => setLocale(it)}
                >
                  {localeToFlag[it]}
                </SecondaryButton>
              ))}
            </Flex>
          )}
          <TextInput
            label={t('Subject')}
            value={subject}
            onValue={(val) => {
              if (val) {
                setMissingSubject(false)
              }
              setSubject(val)
            }}
            css={margin.top('large')}
            error={missingSubject ? t('Subject is required') : undefined}
          />
          <Flex column css={margin.top('large')}>
            <Text>{t('Content')}</Text>
            <div
              css={css`
                ${emailStyles}
              `}
            >
              <div ref={quillRef} />
            </div>
          </Flex>
          <Flex horizontal="space-between">
            <SecondaryButton
              onClick={async () => {
                const prompt =
                  'This will override the non-english titles and bodies, are you sure you want to re-translate?'
                if (showLocales && !confirm(prompt)) return
                if (!bodies.en) {
                  alert('English translation is missing')
                  return
                }
                const words = bodies.en.split(' ').length
                if (words > 100) {
                  alert(
                    `This can take up to 3 min. (Words to translate: ${words})`
                  )
                }
                const newTitles: { [key: string]: string } = {
                  en: titles.en!,
                }
                const newBodies: { [key: string]: string } = {
                  en: bodies.en!,
                }
                for (const locale of locales.filter((it) => it !== Locale.en)) {
                  const res = await runQuery({
                    variables: {
                      input: {
                        englishTexts: [titles.en, bodies.en],
                        toLocale: locale,
                      },
                    },
                  })
                  if (!res.data) {
                    alert(
                      'An error occurred while translating. Please check each locales before sending notification.'
                    )
                    return
                  }
                  const [_titles, _bodies] = res.data.translateLocaleStrings
                  newTitles[locale] = _titles[locale] ?? ''
                  newBodies[locale] = _bodies[locale] ?? ''
                }
                setTitles(newTitles)
                setBodies(newBodies)
                setShowLocales(true)
              }}
              css={margin.top('large')}
              disabled={!subject || !body || locale !== Locale.en}
            >
              {translateLocaleString.loading ? (
                <Loader size={15} delay={0} />
              ) : (
                'Translate'
              )}
            </SecondaryButton>
            <PrimaryButton
              disabled={loading}
              type="submit"
              css={margin.top('large')}
            >
              {t('Send')}
            </PrimaryButton>
          </Flex>
          {data && (
            <Flex css={margin.top()}>
              <Text strong>{t('Email sent')}</Text>
            </Flex>
          )}
        </form>
      )}
    />
  )
}

export const AdminEmailPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="email" />

          <Content />
        </PageContent>

        <Footer />
      </AdminAccessControlComponent>
    </Layout>
  )
}

// Try to replicate layout from https://account.postmarkapp.com/servers/6520287/templates/23874966/edit
const emailStyles = `
strong,
b,
strong *,
b * {
  font-weight: bold;
}
em,
i,
em *,
i * {
  font-style: italic;
}

a {
  color: #3869D4;
}

a img {
  border: none;
}

h1 {
  margin-top: 0;
  color: #333333;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}

h2 {
  margin-top: 0;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

h3 {
  margin-top: 0;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

p,
ul,
ol,
blockquote {
  margin: .4em 0 1.1875em;
  font-size: 16px;
  line-height: 1.625;
}
`
