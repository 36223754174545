/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { Text } from '../../../components/Text'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { colors } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { LeagueQuizInstanceResult } from '../../LeagueQuiz/queries'

export type UserWithResult = {
  userId: string
  displayName?: string
  avatarData?: string
  result: LeagueQuizInstanceResult
}

export const ChallengeScoreboard = ({
  results,
  hideHeader,
}: {
  results: UserWithResult[]
  hideHeader?: boolean
}) => {
  const { authUser } = useAuthContext()

  const sortedResults = results.sort((a, b) => {
    return b.result.totalScore - a.result.totalScore
  })

  return (
    <Flex column>
      {!hideHeader && (
        <Text
          extraCondensed
          strong
          textAlign="center"
          css={css`
            font-size: 24px;
            line-height: 24px;
            font-style: italic;
            margin-bottom: 24px;
          `}
        >
          {t('Scoreboard')}
        </Text>
      )}

      {sortedResults.map((result, index) => {
        const isAuthUser = authUser?.uid === result.userId
        return (
          <Flex
            key={`result_${result.userId}`}
            horizontal="space-between"
            vertical="center"
            css={css`
              background-color: ${isAuthUser
                ? colors.green200
                : index % 2 === 0
                ? colors.yellow100
                : 'transparent'};
              border-radius: 50px;
              padding-left: 16px;
              padding-right: 16px;
              margin-bottom: 8px;
              overflow: hidden;
            `}
          >
            <UserAvatar height={50} avatarData={result.avatarData} />
            <Text
              extraCondensed
              strong
              textAlign="left"
              css={css`
                font-size: 24px;
                line-height: 24px;
                font-style: italic;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                padding-left: 8px;
              `}
            >
              {isAuthUser ? result.displayName ?? t('You') : result.displayName}
            </Text>

            <Text
              extraCondensed
              strong
              textAlign="center"
              css={css`
                font-size: 44px;
                line-height: 1px;
                font-style: italic;
                padding-bottom: 8px;
              `}
            >
              {result.result.totalScore}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
