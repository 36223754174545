/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { BiFullscreen } from 'react-icons/bi'
import { Navigate, useParams } from 'react-router-dom'
import { useWakeLock } from 'react-screen-wake-lock'
import { DefaultError } from '../../../components/DefaultError'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { Loader as LoadingComponent } from '../../../components/Loader'
import { FcQuizQrCode } from '../../../components/QrCodeComponent'
import { TextNew } from '../../../components/TextNew'
import { isDevelopment } from '../../../config/config'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { DisplayLiveQuizInner } from '../../DisplayLiveQuiz/DisplayLiveQuizPage'
import {
  getLiveQuizStatus,
  LiveQuizStatus,
} from '../../LiveQuiz/LiveQuizHostPage'
import { useGetLiveQuizDisplayInstanceForLeagueQuery } from '../../LiveQuiz/queries'
import { ChallengeLeague, useChallengeLeagueQuery } from '../queries'
import { DisplayPageLeaderboard } from './DisplayPageLeaderboard'
import { PageRefreshComponent } from './PageRefreshComponent'
import {
  getCTATextForLeague,
  getIconImageForLeague,
  getSpecialLeagueLanguage,
  getTextColorForLeague,
  imageMaxHeightFromScreenSize,
  ScreenSize,
} from './util'

const Content = ({
  league,
  isLandscape,
  screenSize,
}: {
  league: ChallengeLeague
  isLandscape: boolean
  screenSize: ScreenSize
}) => {
  const textColor = getTextColorForLeague(league)
  const leagueUrl = `https://fcquiz.app/${league.locale}/league/${league.slug}`

  const leagueLanguage = getSpecialLeagueLanguage(league)

  const imageUrl = getIconImageForLeague(league)

  return (
    <Flex
      column={!isLandscape}
      grow
      css={[
        css`
          margin: auto auto;
          width: 100%;
          aspect-ratio: ${isLandscape ? 16 / 9 : 9 / 16};
        `,
        !isLandscape &&
          css`
            padding-top: 10vh;
          `,
      ]}
    >
      <PageRefreshComponent />
      <Flex
        column
        vertical="center"
        horizontal="center"
        css={[
          css`
            padding: 0 40px;
            gap: 3vh;
          `,
          isLandscape &&
            css`
              flex: 3;
            `,
          !isLandscape &&
            css`
              gap: 24px;
            `,
        ]}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt="League icon"
            css={css`
              height: 100%;
              max-height: ${imageMaxHeightFromScreenSize(screenSize)}px;
              max-width: calc(100vw - 40px);
              width: auto;
            `}
          />
        )}

        <HeadingNew
          italic
          condensed
          level={1}
          color={textColor}
          textAlign="center"
          css={[
            css`
              font-size: 9vh;
              line-height: 1;
              letter-spacing: -0.025em;
            `,
            !isLandscape &&
              css`
                font-size: 6vh;
              `,
          ]}
        >
          {getCTATextForLeague(league)}
        </HeadingNew>

        <Flex
          css={[
            css`
              height: 40%;
              aspect-ratio: 1;
            `,
            !isLandscape &&
              css`
                min-height: 300px;
                aspect-ratio: 1;
              `,
          ]}
        >
          <FcQuizQrCode
            url={leagueUrl}
            backgroundColor={league.backgroundColor}
            foregroundColor={textColor}
            logoScale={3}
          />
        </Flex>
      </Flex>
      <Flex
        column
        vertical="center"
        css={[
          css`
            padding: 40px;
          `,
          isLandscape &&
            css`
              padding-top: 0px;
              padding-right: 100px;
              flex: 2;
            `,

          !isLandscape &&
            css`
              padding: 0 24px;
            `,
        ]}
      >
        <DisplayPageLeaderboard
          league={league}
          screenSize={screenSize}
          isLandscape={isLandscape}
          language={leagueLanguage}
        />
      </Flex>
    </Flex>
  )
}

const PageLayout = ({ league }: { league: ChallengeLeague }) => {
  const liveInstanceQuery = useGetLiveQuizDisplayInstanceForLeagueQuery(
    league.id
  )
  const liveInstance =
    liveInstanceQuery.data?.getLiveQuizDisplayInstanceForLeague

  const liveInstanceStatus = liveInstance
    ? getLiveQuizStatus({ ...liveInstance })
    : null

  useEffect(() => {
    if (!liveInstanceStatus || liveInstanceStatus === LiveQuizStatus.FINISHED) {
      liveInstanceQuery.startPolling(10000)
      return
    }

    liveInstanceQuery.startPolling(2000)
  }, [league.id, liveInstanceStatus])

  const { isSupported, released, request, release } = useWakeLock()

  useEffect(() => {
    if (isSupported && !released) {
      console.log('Requesting wake lock')
      request()
    }

    return () => {
      if (isSupported && released) {
        console.log('Releasing wake lock')
        release()
      }
    }
  }, [isSupported, released, request, release])

  const leagueLanguage = getSpecialLeagueLanguage(league)

  const t = useT(leagueLanguage)
  const fullscreenHandle = useFullScreenHandle()

  const windowAspectRatio = window.innerWidth / window.innerHeight

  const [isLandscape, setIsLandscape] = useState(windowAspectRatio > 1)
  const [screenSize, setScreenSize] = useState<ScreenSize>(
    window.innerWidth < 1920
      ? 'small'
      : window.innerWidth < 2560
      ? '1080p'
      : window.innerWidth < 3840
      ? '1440p'
      : '4k'
  )

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth / window.innerHeight > 1)
      setScreenSize(
        window.innerWidth < 1920
          ? 'small'
          : window.innerWidth < 2560
          ? '1080p'
          : window.innerWidth < 3840
          ? '1440p'
          : '4k'
      )
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <FullScreen handle={fullscreenHandle}>
      <Flex
        column
        grow
        css={css`
          background-color: ${league.backgroundColor};
          position: relative;
          height: ${isLandscape ? '100vh' : 'auto'};
          overflow: hidden;
        `}
      >
        {isDevelopment && (
          <TextNew
            css={css`
              position: absolute;
              top: 8px;
              left: 16px;
              background-color: ${colors.black};
              color: ${colors.white};
              padding: 4px;
              font-size: 14px;
            `}
          >
            {screenSize}
          </TextNew>
        )}

        {!fullscreenHandle.active && (
          <button
            aria-label={t('Enter fullscreen')}
            onClick={fullscreenHandle.enter}
            css={[
              buttonReset,
              css`
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 16px;
                right: 16px;
                background-color: ${league.textColor};
                height: 40px;
                width: 40px;
                border-radius: 50%;
                opacity: 0.8;
                transition: opacity 0.2s;
                z-index: 1000;

                &:hover {
                  opacity: 1;
                }
              `,
            ]}
          >
            <BiFullscreen
              size={24}
              css={css`
                color: ${league.backgroundColor};
                padding: 0;
                line-height: 1;
                margin: 0;
              `}
            />
          </button>
        )}

        {liveInstance && (
          <DisplayLiveQuizInner
            instance={liveInstance}
            isLandscape={isLandscape}
            screenSize={screenSize}
          />
        )}

        {!liveInstance && (
          <Content
            league={league}
            isLandscape={isLandscape}
            screenSize={screenSize}
          />
        )}
      </Flex>
    </FullScreen>
  )
}

const Loader = ({ leagueId }: { leagueId: string }) => {
  const leagueQuery = useChallengeLeagueQuery(leagueId)

  if (leagueQuery.loading) {
    return <LoadingComponent center variant="yellow" />
  }

  if (leagueQuery.error || !leagueQuery.data?.group) {
    const reason = leagueQuery.error?.message ?? 'League not found'
    return <DefaultError sentryErrorMessage={`LeagueDisplayPage | ${reason}`} />
  }

  const league = leagueQuery.data.group

  return <PageLayout league={league} />
}

export const ChallengeLeagueDisplayPage = () => {
  const leagueId = useParams().challengeLeagueId

  if (!leagueId) {
    return <Navigate to="/" />
  }

  return (
    <Layout
      hideNavbar
      hideHeaderPadding
      backgroundColor={colors.green600}
      css={css`
        height: 100vh;
      `}
    >
      <Loader leagueId={leagueId} />
    </Layout>
  )
}
