/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import copyToClipboard from 'copy-to-clipboard'
import { useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { Text } from '../../../components/Text'
import { TextInputForm } from '../../../components/TextInputForm'
import { useT } from '../../../lib/i18n/useT'
import { margin } from '../../../styles/margin'
import theme from '../../../styles/theme'
import { useTimeout } from '../../../utils/useTimeout'
import { Club, useInviteToClubMutation } from '../queries'

export const InviteMemberModal = ({
  club,
  closeModal,
}: {
  club: Club
  closeModal: () => void
}) => {
  const t = useT()
  const [inviteToClub] = useInviteToClubMutation()
  const [hasCopied, setHasCopied] = useState(false)
  useTimeout(() => setHasCopied(false), hasCopied ? 3000 : null)

  return (
    <Modal
      heading={t('Invite')}
      closeModal={closeModal}
      styling={css`
        background-color: ${theme.colors.green200};
        text-align: center;
      `}
      textColor={theme.colors.green500}
    >
      <Flex
        column
        css={css`
          text-align: center;
          max-width: 390px;
          margin: 0 auto;
        `}
      >
        <Text
          css={css`
            text-align: center;
            color: ${theme.colors.green500};
          `}
          size="large"
        >
          {t('Invite your friends to join {{clubName}}.', {
            clubName: club.name,
          })}
        </Text>

        <Text
          css={[
            css`
              text-align: center;
              color: ${theme.colors.green500};
            `,
            margin.vertical('large'),
          ]}
          size="small"
        >
          {t(
            'You can send an invite by sending an email or copy the invite link and send it yourself.'
          )}
        </Text>

        <TextInputForm
          label=""
          placeholder={t('Email')}
          formParameters={{
            initialValues: {
              value: '',
            },
            onSubmit: async ({ values, setSubmitError }) => {
              try {
                await inviteToClub({
                  variables: {
                    input: {
                      email: values.value,
                      clubId: club.id,
                    },
                  },
                })
              } catch (e) {
                console.error('Failed to invite user to club', e)
                setSubmitError(t('Something went wrong, try again'))
              }
            },
            validate: {
              value: (value) =>
                !value ? t('Email cannot be empty') : undefined,
            },
          }}
          type="email"
          submitText={t('Send')}
          successText={t('Invite sent')}
        />

        <Flex column horizontal="center" css={margin.top()}>
          <SecondaryButton
            onClick={() => {
              const inviteUrl = `${
                new URL(window.location.href).origin
              }/clubs?invitation=${club.invitationCode}`

              copyToClipboard(inviteUrl)

              setHasCopied(true)
            }}
            css={css`
              height: 38px;
              border-color: ${theme.colors.green500};
              color: ${theme.colors.green500};
            `}
          >
            {t('Copy invite link')}
          </SecondaryButton>
          {hasCopied && (
            <Text css={margin.top('tiny')} strong>
              {t('Copied!')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}
