/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useState } from 'react'
import { SecondaryButton } from '../../components/Button'
import { ClubElement } from '../../components/ClubElement'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { LayoutLoading } from '../../components/layout/LayoutLoading'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useDebouncedValue } from '../../utils/useDebouncedValue'
import { AcceptInvite } from './AcceptInvite'
import { CreateClubModal } from './CreateClubForm'
import { useClubsQuery, useViewerClubsQuery } from './queries'

const Content: FC<{}> = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const [clubQuery, setClubQuery] = useState('')
  const debouncedClubQuery = useDebouncedValue(clubQuery, 300)
  const clubsQuery = useClubsQuery({
    query: debouncedClubQuery,
    limit: debouncedClubQuery.length > 0 ? undefined : 50,
  })

  const viewerClubsQuery = useViewerClubsQuery()
  const viewerClubs =
    viewerClubsQuery.data?.viewer?.clubMemberships.map(
      (membership) => membership.club
    ) ?? []

  return (
    <PageContent>
      <AcceptInvite />

      <Flex horizontal="space-between" vertical="center">
        <Heading level={2} looksLikeLevel={5} color="white">
          {t('My clubs')}
        </Heading>
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton
              onClick={() => {
                openModal()
              }}
              variant="white"
              size="small"
            >
              {t('Create club')}
            </SecondaryButton>
          )}
          modal={({ closeModal }) =>
            !authUser || authUser?.isAnonymous ? (
              <LoginModalInner closeModal={closeModal} />
            ) : (
              <CreateClubModal closeModal={closeModal} />
            )
          }
        />
      </Flex>
      {viewerClubs.map((club) => (
        <ClubElement
          key={club.id}
          club={club}
          isMember
          css={margin.top('tiny')}
        />
      ))}
      {!viewerClubsQuery.loading && viewerClubs.length === 0 && (
        <Text color="white">
          <p css={margin.top()}>{t('You are not member of a club yet.')}</p>
          <p css={margin.top()}>{t('You can either:')}</p>
          <ul
            css={css`
              list-style: inside;
            `}
          >
            <li>{t('Be invited by to an existing club.')}</li>
            <li>{t('Or create a new club and invite your friends.')}</li>
          </ul>
        </Text>
      )}

      <Spacer height="huge" />

      <Flex vertical="center" horizontal="space-between">
        <Heading level={2} looksLikeLevel={5} color="white">
          {t('All clubs')}
        </Heading>

        {clubsQuery.loading && <Loader size={16} />}
      </Flex>

      <TextInput
        value={clubQuery}
        onValue={setClubQuery}
        placeholder={t('Search...')}
        css={margin.top('small')}
      />
      <Spacer height="medium" />

      {clubsQuery.clubs.map((club) => (
        <ClubElement
          club={club}
          key={club.id}
          isMember={club.viewerIsMember}
          css={margin.top('tiny')}
        />
      ))}

      {!clubQuery && clubsQuery.hasMore && (
        <Flex horizontal="center" css={margin.top('large')}>
          <SecondaryButton
            onClick={() => clubsQuery.fetchMore()}
            variant="white"
          >
            {t('More')}
          </SecondaryButton>
        </Flex>
      )}
    </PageContent>
  )
}

export const ClubsPage = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      title={`fcQuiz | ${t('Clubs')}`}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      backgroundColor={colors.green600}
      profileBanner
      withFooter
      showCookiebotButton
    >
      <Content />
    </Layout>
  )
}

export function ClubsLoaderComponent() {
  return (
    <LayoutLoading backgroundColor={colors.green100}>
      <Header color="green" />
    </LayoutLoading>
  )
}
