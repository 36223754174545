/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { FramedClubLogo } from '../../../components/Picture'
import { Text } from '../../../components/Text'
import { Verified } from '../../../components/icons'
import { Flex } from '../../../components/layout/Flex'
import { LoginModalInner } from '../../../components/modal/LoginModalInner'
import { Modal, ModalTrigger } from '../../../components/modal/Modal'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { Spacer, margin } from '../../../styles/margin'
import { hasOneOfClubRoles } from '../clubUtils'
import {
  Club,
  clubProfileQuery,
  useJoinClubMutation,
  useRemoveFromClubMutation,
} from '../queries'
import { InviteMemberModal } from './InviteMemberModal'

const nameTooLong = (str: string) => {
  const strSplit = str.split(' ')
  let longestWord = 0

  for (let i = 0; i < strSplit.length; i++) {
    if (strSplit[i].length > longestWord) {
      longestWord = strSplit[i].length
    }
  }
  return longestWord > 8
}

const Buttons = ({ club }: { club: Club }) => {
  const { authUser } = useAuthContext()
  const t = useT()
  const navigate = useNavigate()
  const [removeFromClub, { loading: removeFromClubLoading }] =
    useRemoveFromClubMutation()
  const [joinClub, { error: joinClubError, loading: joinClubLoading }] =
    useJoinClubMutation()

  return (
    <Flex column horizontal="center">
      {club.viewerIsClubAdmin || club.viewerIsMember ? (
        <Flex css={margin.top()} gap="small">
          {hasOneOfClubRoles(club.viewerRole, 'admin', 'moderator') && (
            <PrimaryButton
              onClick={() => {
                navigate(`/clubs/${club.id}/edit`)
              }}
              variant="white"
            >
              {t('Settings')}
            </PrimaryButton>
          )}

          {club.viewerIsMember && club.viewerRole !== 'admin' && (
            <ModalTrigger
              button={({ openModal }) => (
                <SecondaryButton
                  disabled={removeFromClubLoading}
                  onClick={openModal}
                  variant="white"
                >
                  {t('Leave')}
                </SecondaryButton>
              )}
              modal={({ closeModal }) => (
                <Modal heading={t('Leave club')} closeModal={closeModal}>
                  <Flex column horizontal="flex-start">
                    <Text>
                      {t('Are you sure you want to leave {{clubName}}?', {
                        clubName: club.name,
                      })}
                    </Text>

                    <PrimaryButton
                      css={margin.top()}
                      disabled={removeFromClubLoading}
                      onClick={() => {
                        removeFromClub({
                          variables: {
                            input: {
                              clubId: club.id,
                              userId: authUser?.uid!,
                            },
                          },
                          refetchQueries: [
                            {
                              query: clubProfileQuery,
                              variables: { slug: club.slug },
                            },
                          ],
                        }).then(() => {
                          closeModal()
                        })
                      }}
                    >
                      {t('Leave club')}
                    </PrimaryButton>
                  </Flex>
                </Modal>
              )}
            />
          )}

          {club.viewerIsMember && (
            <ModalTrigger
              button={({ openModal }) => (
                <SecondaryButton onClick={openModal} variant="white">
                  {t('Invite')}
                </SecondaryButton>
              )}
              modal={({ closeModal }) => (
                <InviteMemberModal club={club} closeModal={closeModal} />
              )}
            />
          )}
        </Flex>
      ) : club.public ? (
        !authUser || authUser?.isAnonymous ? (
          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton
                onClick={openModal}
                variant="white"
                css={margin.top('small')}
              >
                {`+ ${t('Join')}`}
              </PrimaryButton>
            )}
            modal={({ closeModal }) => (
              <LoginModalInner closeModal={closeModal} />
            )}
          />
        ) : (
          <PrimaryButton
            onClick={() => {
              joinClub({
                variables: {
                  input: { clubId: club.id },
                },
                refetchQueries: [
                  {
                    query: clubProfileQuery,
                    variables: { slug: club.id },
                  },
                ],
              }).catch((e) => console.error('Failed to join via url', e))
            }}
            variant="white"
            css={margin.top('small')}
            disabled={!!(joinClubLoading || joinClubError)}
          >
            {`+ ${t('Join')}`}
          </PrimaryButton>
        )
      ) : null}
      {joinClubError && <Text>{t('Something went wrong')}</Text>}
    </Flex>
  )
}

const boxStyles = css`
  padding: 16px;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const Header = ({ club }: { club: Club }) => {
  const t = useT()
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px;
      `}
    >
      <Flex
        column
        horizontal="center"
        css={[
          css`
            grid-column: 1 / 3;
          `,
          boxStyles,
        ]}
      >
        <Flex vertical="center" gap="small">
          {club.verified && <Verified height={15} width={12} />}
          <Text
            textAlign="center"
            color="white"
            size="small"
            css={css`
              text-transform: uppercase;
            `}
          >
            {t('Club')}
          </Text>
        </Flex>
        <Heading
          level={1}
          textAlign="center"
          color="white"
          wide
          css={css`
            font-weight: 800;
            font-size: 42px;
            text-transform: uppercase;
            @media (max-width: 599px) {
              font-size: ${nameTooLong(club.name) ? '32px' : '42px'};
            }
          `}
        >
          {club.name}
        </Heading>
        {club.logoUrl && (
          <>
            <Spacer height={8} />
            <FramedClubLogo
              size={100}
              url={club.logoUrl}
              shape={club.logoShape}
            />
            <Spacer height={8} />
          </>
        )}

        <div
          css={[
            css`
              grid-column: 1 / 3;
              display: grid;
              grid-gap: 20px;
            `,
          ]}
        >
          {club.description && (
            <Text textAlign="center" color="white" size="small">
              {club.description}
            </Text>
          )}
        </div>
        <Spacer height={20} />
        <Buttons club={club} />
        <Spacer height={40} />
        <div
          css={[
            css`
              grid-column: 1 / 3;
              display: grid;
            `,
          ]}
        >
          <Text
            color="white"
            size="tiny"
            textAlign="center"
            css={css`
              text-transform: 'uppercase';
            `}
          >
            👥{' '}
            {club.membersCount === 1
              ? `${t('{{count}} member', { count: 1 })}`
              : `${t('{{count}} member_other', {
                  count: club.membersCount,
                })}`}
          </Text>
        </div>
      </Flex>
    </div>
  )
}

export const ClubTopContent = ({ club }: { club: Club }) => {
  return (
    <Flex column horizontal="center">
      <Flex
        column
        css={css`
          padding: 16px;
          width: 100%;
          max-width: 450px;
        `}
      >
        <Header club={club} />
      </Flex>
    </Flex>
  )
}
