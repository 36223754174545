/** @jsxImportSource @emotion/react */

import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { HideAfterDelay } from '../../components/HideAfterDelay'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { SelectInput } from '../../components/SelectInput'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import theme from '../../styles/theme'
import { AdminNavbar } from './Navbar'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

const SubscribeUnsubscribeClubToLeagueClubForm = () => {
  const [clubIdOrSlug, setClubIdOrSlug] = useState<string>('')
  const [externalLeagueId, setExternalLeagueId] = useState<string>('')
  const [isOwner, setIsOwner] = useState<boolean>(false)

  const [subscribeClubToLeagueMutation, subscribeClubToLeagueResult] =
    useMutation<
      unknown,
      { clubIdOrSlug: string; leagueId: string; isOwner: boolean }
    >(gql`
      mutation subscribeClubToLeague(
        $clubIdOrSlug: String!
        $leagueId: String!
        $isOwner: Boolean!
      ) {
        subscribeClubToLeague(
          clubIdOrSlug: $clubIdOrSlug
          leagueId: $leagueId
          isOwner: $isOwner
        ) {
          id
        }
      }
    `)

  const [unsubscribeClubFromLeagueMutation, unsubscribeClubFromLeagueResult] =
    useMutation<unknown, { clubIdOrSlug: string; leagueId: string }>(gql`
      mutation unsubscribeClubFromLeague(
        $clubIdOrSlug: String!
        $leagueId: String!
      ) {
        unsubscribeClubFromLeague(
          clubIdOrSlug: $clubIdOrSlug
          leagueId: $leagueId
        ) {
          id
        }
      }
    `)

  return (
    <div
      css={css`
        background-color: ${theme.colors.blue200};
        border-radius: 8px;
        padding: 20px 24px;
      `}
    >
      <Heading level={4} css={margin.bottom()}>
        Manage club-league subscriptions
      </Heading>

      <TextInput
        value={clubIdOrSlug}
        onValue={(val) => setClubIdOrSlug(val)}
        label="Club slug or club ID"
        placeholder="E.g. liverpool.no or de0e03fe-ace3-4a21-81ae-c0619bb7fe6f"
      />

      <Spacer height="medium" />

      <TextInput
        value={externalLeagueId}
        onValue={(val) => setExternalLeagueId(val)}
        label="League ID"
        placeholder="E.g. 069b67f6-fa17-4dfb-a163-8b22232c296f"
      />

      <Spacer height="large" />

      <Flex horizontal="space-between" vertical="center">
        <Flex horizontal="center" gap="small">
          <Heading
            level={6}
            color="black"
            strong={false}
            css={css`
              margin: auto 0;
            `}
          >
            Is Owner:
          </Heading>
          <input
            type="checkbox"
            checked={isOwner}
            onChange={(event) => {
              setIsOwner(event.target.checked)
            }}
            css={css`
              margin: 0;
              height: 30px;
              width: 30px;
            `}
          />
        </Flex>
        <Flex horizontal="flex-end" vertical="center" gap="medium">
          <HideAfterDelay delayMs={3000}>
            <Text>
              {Boolean(
                subscribeClubToLeagueResult.data ||
                  unsubscribeClubFromLeagueResult.data
              )
                ? 'Success'
                : ''}
            </Text>
          </HideAfterDelay>

          <PrimaryButton
            onClick={() => {
              unsubscribeClubFromLeagueMutation({
                variables: { clubIdOrSlug, leagueId: externalLeagueId },
              })
                .then(() => {
                  setClubIdOrSlug('')
                  setExternalLeagueId('')
                })
                .catch(() => {
                  alert('Something went wrong')
                })
            }}
            loading={unsubscribeClubFromLeagueResult.loading}
            disabled={
              !clubIdOrSlug ||
              !externalLeagueId ||
              subscribeClubToLeagueResult.loading
            }
          >
            Delete subscription
          </PrimaryButton>

          <PrimaryButton
            onClick={() => {
              subscribeClubToLeagueMutation({
                variables: {
                  clubIdOrSlug,
                  leagueId: externalLeagueId,
                  isOwner,
                },
              })
                .then(() => {
                  setClubIdOrSlug('')
                  setExternalLeagueId('')
                  setIsOwner(false)
                })
                .catch(() => {
                  alert('Something went wrong')
                })
            }}
            loading={subscribeClubToLeagueResult.loading}
            disabled={
              !clubIdOrSlug ||
              !externalLeagueId ||
              unsubscribeClubFromLeagueResult.loading
            }
          >
            Add subscription
          </PrimaryButton>
        </Flex>
      </Flex>
    </div>
  )
}

const SetClubIsVerifiedForm = () => {
  const [clubIdOrSlug, setClubIdOrSlug] = useState<string>('')
  const [isVerified, setIsVerified] = useState<'true' | 'false' | '-'>('-')

  const [setClubIsVerifiedMutation, setClubIsVerifiedResult] = useMutation(gql`
    mutation setClubIsVerified($input: SetClubIsVerifiedInput!) {
      setClubIsVerified(input: $input) {
        id
      }
    }
  `)

  return (
    <div
      css={css`
        background-color: ${theme.colors.yellow100};
        border-radius: 8px;
        padding: 20px 24px;
      `}
    >
      <Heading level={4} css={margin.bottom()}>
        Set club is verified
      </Heading>

      <TextInput
        value={clubIdOrSlug}
        onValue={(val) => setClubIdOrSlug(val)}
        label="Club slug or club ID"
        placeholder="E.g. liverpool.no or de0e03fe-ace3-4a21-81ae-c0619bb7fe6f"
      />

      <Spacer height="medium" />

      <SelectInput<'true' | 'false' | '-'>
        onValue={(val) => {
          setIsVerified(val)
        }}
        value={isVerified}
        label="Is verified"
      >
        <option value="-">-</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </SelectInput>

      <Spacer height="large" />

      <Flex horizontal="flex-end">
        <Flex vertical="center" gap="medium">
          <HideAfterDelay delayMs={3000}>
            <Text>
              {Boolean(setClubIsVerifiedResult.data) ? 'Success' : ''}
            </Text>
          </HideAfterDelay>

          <PrimaryButton
            onClick={() => {
              setClubIsVerifiedMutation({
                variables: {
                  input: { clubIdOrSlug, isVerified: isVerified === 'true' },
                },
              })
                .then(() => {
                  setClubIdOrSlug('')
                  setIsVerified('-')
                })
                .catch(() => {
                  alert('Something went wrong')
                })
            }}
            loading={setClubIsVerifiedResult.loading}
            disabled={!clubIdOrSlug || isVerified === '-'}
          >
            Update
          </PrimaryButton>
        </Flex>
      </Flex>
    </div>
  )
}

const Content = () => {
  const navigate = useNavigate()
  return (
    <div css={margin.top('large')}>
      <Flex horizontal="center">
        <SecondaryButton
          onClick={() => {
            navigate('/admin/club-sub')
          }}
        >
          {'View all clubs and subscriptions'}
        </SecondaryButton>
      </Flex>
      <Spacer height="huge" />
      <SubscribeUnsubscribeClubToLeagueClubForm />
      <Spacer height="huge" />
      <SetClubIsVerifiedForm />
    </div>
  )
}

export const AdminClubsPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="clubs" />

          <Content />
        </PageContent>
      </AdminAccessControlComponent>
      <Footer />
    </Layout>
  )
}
