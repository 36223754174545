/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { differenceInSeconds } from 'date-fns'
import { t } from 'i18next'
import { clamp } from 'lodash'
import { FC, useEffect, useState } from 'react'
import {
  AVERAGE_MS_PER_WORD,
  MAX_TIME_BONUS_IN_SECONDS,
  MAX_TIME_TO_ANSWER_IN_SECONDS,
} from '../../constants'
import { SanityQuestion } from '../../lib/sanity/types'
import { expand, fadeIn } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import { AlternativeButton } from '../leagueQuiz/AlternativeButton'
import { QuizStatusComponent } from '../leagueQuiz/QuizStatusComponent'
import { QuestionAnswer } from './ClientSideQuiz'

const getTimeToReadQuestionInMs = (text: string) => {
  const words = text.split(' ')
  return Math.max(3000, words.length * AVERAGE_MS_PER_WORD)
}

export const ActiveQuestionScreen: FC<{
  numCompletedQuestions: number
  activeQuestion: SanityQuestion
  isEmbed: boolean
  questionsCount: number
  answerQuestion: (answer: QuestionAnswer) => Promise<void>
}> = ({
  numCompletedQuestions,
  activeQuestion,
  isEmbed,
  questionsCount,
  answerQuestion,
}) => {
  const initialTime = getTimeToReadQuestionInMs(activeQuestion.text)
  const [remaining, setRemaining] = useState(initialTime)
  const [showSplash, setShowSplash] = useState(true)

  const [skipped, setSkipped] = useState(false)

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const isFirstQuestion = numCompletedQuestions === 0

  const skip = async () => {
    if (skipped) {
      return
    }

    setSkipped(true)
    setShowSplash(false)
  }

  useInterval(
    () => {
      setRemaining((remaining) => remaining - AVERAGE_MS_PER_WORD)
    },
    remaining <= 0 ? null : AVERAGE_MS_PER_WORD
  )

  useEffect(() => {
    if (remaining <= 0) {
      setShowSplash(false)
    }
  }, [remaining])

  const textColor = colors.black

  if (showSplash) {
    return (
      <PageContent
        css={css`
          padding-top: 24px;
          height: calc(100vh - ${campaignHeaderHeight});
        `}
      >
        <Flex
          column
          grow
          css={css`
            width: 100%;
            height: 100%;
            cursor: pointer;
          `}
          onClick={skip}
        >
          <Flex
            column
            grow
            css={css`
              max-width: 560px;
              margin: 0 auto;
              width: 100%;
              height: 100%;
            `}
          >
            <div
              css={css`
                position: relative;
                height: 8px;
                background-color: ${colors.green500};
              `}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  animation: ${expand} ${initialTime}ms linear;
                  animation-fill-mode: forwards;
                  background-color: ${colors.green300};
                  height: 8px;
                `}
              />
            </div>
            <Spacer height={isEmbed ? 0 : 'small'} />
            <QuizStatusComponent
              currentQuestion={numCompletedQuestions + 1}
              totalQuestions={questionsCount}
            />
            <Spacer height={isEmbed ? 0 : 'small'} />
            <Text
              key={activeQuestion.text}
              color={textColor}
              css={margin.vertical(isEmbed ? 'small' : 'medium')}
              textAlign="center"
              size="medium"
            >
              {activeQuestion.text}
            </Text>

            <div
              css={css`
                margin-top: auto;
                margin-left: auto;
                margin-right: auto;
              `}
            >
              <Text
                size="small"
                textAlign="center"
                css={[
                  css`
                    color: ${textColor}80;
                    cursor: default;
                  `,
                  isFirstQuestion
                    ? css`
                        opacity: 1;
                      `
                    : css`
                        opacity: 0;
                        animation: ${fadeIn} 600ms ease-out;
                        animation-delay: 1000ms;
                        animation-fill-mode: forwards;
                      `,
                ]}
              >
                {t('Tap to skip')}
              </Text>
            </div>
          </Flex>
        </Flex>
      </PageContent>
    )
  }

  return (
    <AnswerQuestion
      {...{
        numCompletedQuestions,
        activeQuestion,
        isEmbed,
        questionsCount,
        answerQuestion,
      }}
    />
  )
}

const getTimeBonus = (startedAt: Date, answeredAt: Date) => {
  const secondsUsed = differenceInSeconds(answeredAt, startedAt)

  const timeBonus = Math.floor(
    clamp(MAX_TIME_BONUS_IN_SECONDS - secondsUsed, 0, MAX_TIME_BONUS_IN_SECONDS)
  )

  return timeBonus
}

export const AnswerQuestion: FC<{
  numCompletedQuestions: number
  activeQuestion: SanityQuestion
  isEmbed?: boolean
  questionsCount: number
  answerQuestion: (answer: QuestionAnswer) => Promise<void>
}> = ({
  numCompletedQuestions,
  activeQuestion,
  isEmbed,
  questionsCount,
  answerQuestion,
}) => {
  const [startedAt] = useState(new Date())

  const [questionAnswered, setQuestionAnswered] = useState(false)

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const initialSecondsLeft = MAX_TIME_TO_ANSWER_IN_SECONDS

  const [remaining, setRemaining] = useState(initialSecondsLeft)
  const [timerRunning, setTimerRunning] = useState(true)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 || !timerRunning ? null : 1000
  )

  useEffect(() => {
    if (remaining <= 0) {
      answerQuestion({
        questionId: activeQuestion.id,
        alternativeId: null,
        startedAt,
        answeredAt: new Date(),
        correct: false,
        score: 0,
        timeBonus: 0,
      })
    }
  }, [remaining])

  const shrink = keyframes`
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
`

  const textColor = theme.colors.black

  const progressColorRemaining =
    remaining > 6
      ? theme.colors.green300
      : remaining > 3
      ? theme.colors.yellow400
      : theme.colors.red300

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        grow
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            height: 8px;
            background-color: ${theme.colors.green500};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              animation: ${shrink} ${initialSecondsLeft}s linear;
              animation-play-state: ${timerRunning ? 'running' : 'paused'};
              background-color: ${progressColorRemaining};
              height: 8px;
            `}
          />
        </div>
        <Spacer height={isEmbed ? 0 : 'small'} />
        <QuizStatusComponent
          currentQuestion={numCompletedQuestions + 1}
          totalQuestions={questionsCount}
          seconds={remaining}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={activeQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {activeQuestion.text}
        </Text>

        <Flex column>
          {activeQuestion.alternatives.map((alternative) => {
            const answeredAt = new Date()
            const correct = alternative.correct
            return (
              <AlternativeButton
                key={alternative.id}
                onClick={() => {
                  setQuestionAnswered(true)
                  answerQuestion({
                    questionId: activeQuestion.id,
                    alternativeId: alternative.id,
                    startedAt,
                    answeredAt,
                    correct,
                    score: alternative.correct ? 10 : 0,
                    timeBonus: correct
                      ? getTimeBonus(startedAt, answeredAt)
                      : 0,
                  })
                  setTimerRunning(false)
                }}
                disabled={questionAnswered}
              >
                {alternative.text}
              </AlternativeButton>
            )
          })}
        </Flex>
      </Flex>
    </PageContent>
  )
}
