/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  AD_CAMPAIGN_DISPLAY_HALFTIME,
  AD_CAMPAIGN_DISPLAY_HALFTIME_SHORT,
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_DISPLAY_TIME_SHORT_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_SHORT_MS,
} from '../../constants'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { grow } from '../../styles/animations'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import {
  calculateScoreHeaderHeight,
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { LeagueQuizCampaign } from '../../views/LeagueQuiz/queries'
import { LottieWebComponent } from '../LottieWebComponent'
import { Flex } from '../layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'

interface CampaignHeaderProps {
  campaign: LeagueQuizCampaign
  isEmbed?: boolean
  isChallenge?: boolean
  isDailyCareerPath?: boolean
  instanceId?: string
}

export function getCampaignHeaderWidth(center?: boolean) {
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 500) {
      return center ? '50%' : '22%'
    } else if (window.innerWidth < 800) {
      return center ? '40%' : '15%'
    } else {
      return center ? '35%' : '15%'
    }
  }
  return 0
}

function isEmbedOrMobileImage(isEmbed: boolean, campaign: LeagueQuizCampaign) {
  if (isEmbed || window.innerWidth < 700) {
    return campaign.header?.expandedImageMobileUrl
  } else {
    return campaign.header?.expandedImageUrl
  }
}

export const SanityCampaignHeader: React.FC<CampaignHeaderProps> = ({
  campaign,
  isEmbed,
  isChallenge,
  isDailyCareerPath,
}) => {
  const {
    quizState,
    isExpandedAdActive,
    setIsExpandedAdActive,
    campaignHeaderHeight,
    showMobileImages,
    leagueId,
    quizId,
    instanceId,
  } = useLeagueQuizContext()

  const { authUser } = useAuthContext()

  const initialMillisecondsLeft = isDailyCareerPath
    ? AD_CAMPAIGN_DISPLAY_TIME_SHORT_MS
    : AD_CAMPAIGN_DISPLAY_TIME_MS
  const halftime = isDailyCareerPath
    ? AD_CAMPAIGN_DISPLAY_HALFTIME_SHORT
    : AD_CAMPAIGN_DISPLAY_HALFTIME
  const collapseWhenTimeLeft = isDailyCareerPath
    ? AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_SHORT_MS
    : AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS

  const [remaining, setRemaining] = useState(initialMillisecondsLeft)
  const [timerRunning] = useState(quizState === QuizState.CALCULATING)
  const [shouldCollapseHeader, setShouldCollapseHeader] = useState(false)

  const hasExpandedLottie =
    campaign.header?.expandedLottieUrl || campaign.header?.expandedLottieUrl

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 325) // decrease by 325 every 325 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 325 // run every 325 milliseconds
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (quizState === QuizState.ACTIVE_QUIZ) {
        trackEvent(AnalyticsEventType.AdCampaignShown, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
        })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [quizState])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isExpandedAdActive) {
        trackEvent(AnalyticsEventType.AdCampaignExpanded, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
        })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [isExpandedAdActive])

  useEffect(() => {
    if (remaining <= halftime + 200) {
      console.log('should collapse header first')
      // Trigger collapse animation 200ms before halftime
      if (!shouldCollapseHeader) {
        setShouldCollapseHeader(true)
      }
    }
    if (remaining <= collapseWhenTimeLeft) {
      console.log('should collapse header second')
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const expand = keyframes`
    from {
      height: 110px;
    }
    to {
      height: ${calculateScoreHeaderHeight};
    }
  `

  const collapse = keyframes`
    from {
      height: ${calculateScoreHeaderHeight};
    }
    to {
      height: 110px;
    }
  `

  const onlyCenterImage =
    !campaign.header?.leftImageUrl &&
    !campaign.header?.rightImageUrl &&
    campaign.header?.centerImageUrl

  const getCampaignLink = () => {
    if (!campaign || !campaign.campaignLink || campaign.campaignLink === '') {
      return ''
    }

    // Eg in safari, players may have an account, but embeds are sandboxed
    // Therefore we need to transfer the instance to their own account
    if (
      campaign.campaignLink.includes(`${window.location.host}/signup`) &&
      instanceId &&
      authUser?.isAnonymous
    ) {
      return `${campaign.campaignLink}?instanceId=${instanceId}`
    }

    return campaign.campaignLink
  }

  return (
    <a
      href={getCampaignLink()}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        padding: 0;
        margin: 0;
      `}
      onClick={() => {
        trackEvent(AnalyticsEventType.AdCampaignClicked, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
        })
      }}
    >
      <Flex
        column
        horizontal={'center'}
        vertical={isExpandedAdActive ? 'space-between' : 'center'}
        css={[
          css`
            position: relative;
            background-color: ${campaign.header?.headerColor || colors.grey100};
            height: ${campaignHeaderHeight};
            width: 100vw;
            overflow: hidden;
          `,
          !shouldCollapseHeader &&
            isExpandedAdActive && [
              css`
                animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `,
            ],
          shouldCollapseHeader &&
            isExpandedAdActive &&
            css`
              animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                forwards;
            `,
        ]}
      >
        {quizState === QuizState.COUNTDOWN && campaign?.countdownImageUrl ? (
          <Flex
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 100%;
            `}
          >
            <img
              src={campaign.countdownImageUrl}
              alt="Sponsor logo"
              css={css`
                object-fit: contain;
                max-height: 220px;
                max-width: 90%;
              `}
            />
          </Flex>
        ) : (
          <>
            {!isExpandedAdActive && (
              <Flex
                horizontal="space-between"
                vertical="center"
                css={css`
                  max-width: 750px;
                  width: 100%;
                  align-self: center;
                  padding: 0 16px;
                `}
              >
                {campaign.header?.leftImageUrl ? (
                  <img
                    src={campaign.header?.leftImageUrl}
                    css={css`
                      object-fit: contain;
                    `}
                    width={getCampaignHeaderWidth()}
                  />
                ) : (
                  <div
                    css={css`
                      width: ${getCampaignHeaderWidth()};
                    `}
                  />
                )}
                {campaign.header?.centerImageUrl ? (
                  <img
                    src={campaign.header?.centerImageUrl}
                    width={
                      onlyCenterImage ? '100%' : getCampaignHeaderWidth(true)
                    }
                  />
                ) : (
                  <div />
                )}
                {campaign.header?.rightImageUrl ? (
                  <Flex
                    css={css`
                      width: ${getCampaignHeaderWidth()};
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <img
                      src={campaign.header?.rightImageUrl}
                      css={css`
                        object-fit: contain;
                      `}
                      width="100%"
                    />
                  </Flex>
                ) : (
                  <div
                    css={css`
                      width: ${getCampaignHeaderWidth()};
                    `}
                  />
                )}
              </Flex>
            )}
          </>
        )}
        {isExpandedAdActive && (
          <Flex
            css={css`
              margin: auto;
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
              align-content: flex-end;
              overflow-y: hidden;
              overflow-x: visible;
              max-width: ${window.innerWidth}px;
            `}
          >
            {campaign.header?.expandedLottieUrl && !showMobileImages && (
              <Flex css={css``}>
                <LottieWebComponent url={campaign.header.expandedLottieUrl} />
              </Flex>
            )}
            {campaign.header?.expandedLottieMobileUrl && showMobileImages && (
              <Flex
                css={css`
                  padding: 0 16px;
                `}
              >
                <LottieWebComponent
                  url={campaign.header.expandedLottieMobileUrl}
                />
              </Flex>
            )}
            {(campaign.header?.expandedImageUrl ||
              campaign.header?.expandedImageMobileUrl) &&
              isExpandedAdActive &&
              !hasExpandedLottie && (
                // If isEmbed, show the mobile image
                // do not stretch the image, but show it in its original aspect ratio
                <img
                  css={css`
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                  `}
                  src={isEmbedOrMobileImage(isEmbed ?? false, campaign)}
                />
              )}
          </Flex>
        )}
        {!isChallenge && (
          <div
            css={css`
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: ${colors.grey100};
              z-index: 10;
              width: 100%;
              height: 16px;
              border-radius: 16px 16px 0px 0px;
              z-index: 1;
              overflow: hidden;
            `}
          >
            {isExpandedAdActive && (
              <Flex
                column
                css={css`
                  height: 8px;
                  background-color: ${colors.green300};
                  align-self: flex-start;
                  width: 100%;
                  border-radius: 16px 16px 0px 0px;
                `}
              >
                <Flex
                  column
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 8px;
                    background-color: ${colors.green500};
                    align-self: flex-start;
                    ${remaining <= initialMillisecondsLeft &&
                    css`
                      animation: ${grow}
                        ${initialMillisecondsLeft - collapseWhenTimeLeft}ms
                        linear;
                    `};
                  `}
                ></Flex>
              </Flex>
            )}
          </div>
        )}
      </Flex>
    </a>
  )
}
