/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { SelectInput } from '../../../components/SelectInput'
import { Text } from '../../../components/Text'
import { rootWebAppUrl } from '../../../config/config'
import { getCurrentLocale, Locale } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { margin } from '../../../styles/margin'
import theme, { colors } from '../../../styles/theme'

export const EmbedCodeModal = ({
  clubLeagueSubscriptionId,
  leagueId,
  closeModal,
  quizId,
}: {
  clubLeagueSubscriptionId?: string
  leagueId?: string
  quizId?: string
  closeModal: () => void
}) => {
  const t = useT()
  const language = getCurrentLocale()
  const [locale, onLocale] = useState<Locale>(language ?? 'no')

  const getEmbedPageLink = () => {
    if (clubLeagueSubscriptionId) {
      return `${rootWebAppUrl()}/${locale}/embed/club-league/${clubLeagueSubscriptionId}`
    }
    if (leagueId) {
      return `${rootWebAppUrl()}/${locale}/embed/league/${leagueId}/${
        quizId ?? 'current'
      }`
    }
    return `${rootWebAppUrl()}/${locale}/embed/quiz/${quizId}/${leagueId}`
  }

  const embedCode = `
  <iframe 
    src="${getEmbedPageLink()}"
    width="100%"
    height="550"
    frameBorder="0">
  </iframe>`

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), 3000)
    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Modal
      heading={'Embed'}
      closeModal={closeModal}
      styling={css`
        background-color: ${theme.colors.green200};
        text-align: center;
      `}
      textColor={theme.colors.black}
    >
      <Flex
        column
        css={css`
          text-align: center;
          max-width: 590px;
          margin: 0 auto;
        `}
      >
        <Flex css={margin.vertical()}>
          <LanguagePicker locale={locale} onLocale={onLocale} />
        </Flex>
        <Flex vertical="center" horizontal="space-between">
          <Heading level={5} css={margin.vertical()}>
            {t('Code')}
          </Heading>

          <Flex horizontal="center" gap="small">
            {copied && <Text size="small">{t('Copied!')}</Text>}
            <SecondaryButton
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(embedCode)
                setCopied(true)
              }}
            >
              {t('Copy embed code')}
            </SecondaryButton>
          </Flex>
        </Flex>
        <pre
          css={css`
            background: ${colors.grey100};
            padding: 16px;
            font-family: monospace;
            border-radius: 2px;
            font-size: 14px;
            overflow: auto;
            text-align: left;
          `}
        >
          {embedCode}
        </pre>
        <Flex
          column
          vertical="center"
          horizontal="space-between"
          css={margin.top('huge')}
        >
          <Heading
            level={5}
            css={[
              margin.vertical(),
              css`
                text-align: left;
              `,
            ]}
          >
            {t('Preview')}
          </Heading>
          <iframe
            src={getEmbedPageLink()}
            width="100%"
            height="550"
            frameBorder="0"
          />
        </Flex>
      </Flex>
    </Modal>
  )
}

export const LanguagePicker = ({
  locale,
  onLocale,
}: {
  locale: Locale
  onLocale: (locale: Locale) => void
}) => {
  const t = useT()
  return (
    <SelectInput
      label=""
      value={locale}
      onValue={(value) => onLocale(value as Locale)}
      textColor={'black'}
    >
      {!locale && <option value={undefined}>{t('Choose language...')}</option>}
      <option value={Locale.no}>🇳🇴 Norsk</option>
      <option value={Locale.en}>🇬🇧 English</option>
      <option value={Locale.es}>🇪🇸 Español</option>
      <option value={Locale.de}>🇩🇪 Deutsch</option>
      <option value={Locale.fr}>🇫🇷 Francese</option>
      <option value={Locale.it}>🇮🇹 Italiano</option>
      <option value={Locale.pt}>Português</option>
      <option value={Locale.ar}>عربي</option>
      <option value={Locale.ja}>🇯🇵 日本語</option>
      <option value={Locale.sv}>🇸🇪 Svenska</option>
      <option value={Locale.da}>🇩🇰 Dansk</option>
    </SelectInput>
  )
}
