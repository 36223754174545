/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { HideAfterDelay } from '../../components/HideAfterDelay'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { apolloClient } from '../../lib/apollo/apollo'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { useJoinClubMutation } from './queries'

export const AcceptInvite = () => {
  const t = useT()
  const [joinClub, { error, loading, data }] = useJoinClubMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const invitation = searchParams.get('invitation') as string
  const [unauthorized, setUnauthorized] = useState<boolean>(false)
  const { authUser } = useAuthContext()

  useEffect(() => {
    if (!authUser || authUser?.isAnonymous) {
      setUnauthorized(true)
      return
    }
    if (typeof invitation === 'string' && invitation) {
      setSearchParams(new URLSearchParams()) // clear search params

      joinClub({
        variables: {
          input: {
            invitationCode: invitation,
          },
        },
      })
        .then(() => apolloClient.refetchQueries({ include: 'active' }))
        .catch((e) => console.error('Failed to join via url', e))
    }
  }, [joinClub, invitation])

  if (loading) {
    return <Loader variant="blue" size={20} css={margin.bottom()} />
  }

  if (typeof invitation === 'string' && invitation && unauthorized) {
    return (
      <HideAfterDelay delayMs={10000}>
        <ErrorMessage>{t('Sign up to participate')}</ErrorMessage>
        <Spacer height={20} />
      </HideAfterDelay>
    )
  }

  if (error) {
    return (
      <HideAfterDelay delayMs={10000}>
        <ErrorMessage>{error.message}</ErrorMessage>
        <Spacer height={20} />
      </HideAfterDelay>
    )
  }

  if (data) {
    return (
      <HideAfterDelay delayMs={5000}>
        <Text color="white" css={margin.bottom()}>
          {t('Invitation accepted')}
        </Text>
      </HideAfterDelay>
    )
  }

  return null
}
