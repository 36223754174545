/** @jsxImportSource @emotion/react */

import { OperationVariables, QueryResult } from '@apollo/client'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { LeaderboardItemComponent } from '../../views/League/Leaderboard/LeaderboardItem'
import { LeagueQuiz } from '../../views/LeagueQuiz/queries'
import { TextNew } from '../TextNew'

export const TopPlayers = ({
  leagueQuizFinished,
  numPeople,
  hideLink = false,
  quizId,
  leagueSlug,
}: {
  leagueQuizFinished: QueryResult<
    {
      leagueQuiz?: LeagueQuiz | undefined
    },
    OperationVariables
  >
  numPeople: number
  hideLink?: Boolean
  quizId: string
  leagueSlug?: string
}) => {
  const t = useT()
  const leaderboard = leagueQuizFinished.data?.leagueQuiz?.leaderboard?.slice(
    0,
    numPeople
  )
  return (
    <Flex column>
      <Flex horizontal="space-between" vertical="center">
        <TextNew
          size={16}
          italic
          condensed
          spacingEm={0.01}
          strong
          color={'#676767'}
        >
          {t(`Top players`)}
        </TextNew>
        {!hideLink && leagueSlug && (
          <Link
            target="_parent"
            css={css`
              text-decoration: none;
              display: flex;
              gap: 8px;
              align-items: center;
            `}
            to={`/series/${leagueSlug}/${quizId}`}
          >
            <TextNew
              size={16}
              italic
              condensed
              spacingEm={0.01}
              strong
              color={'#676767'}
            >
              {t(`See the leaderboard`)}
            </TextNew>
            <img
              src="/chevron2.svg"
              css={css`
                width: 4px;
                height: 8px;
                margin-left: -2px;
              `}
            />
          </Link>
        )}
      </Flex>
      <Spacer height={8} />
      <Flex
        css={css`
          position: relative;
          margin-bottom: 4px;
        `}
        column
      >
        <Flex
          column
          css={css`
            gap: 4px;
            padding: 0px 20px;
          `}
        >
          {leaderboard?.map((it, index) => (
            <LeaderboardItemComponent
              key={index}
              item={it}
              isViewer={false}
              height={32}
              small
              clickable={false}
              css={[
                css`
                  flex: 1;
                `,
              ]}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
