/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link, useSearchParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { boxShadows, colors } from '../../styles/theme'
import { getIconAndTitle } from '../../utils/helperFunctions'
import { leagueFrequencyToString } from '../League/LeaguePage'
import { HomePageLeague, LeagueDisplayType } from './queries'
import { TextNew } from '../../components/TextNew'

const NotificationCircle = ({ number }: { number: number }) => {
  return (
    <Flex
      vertical="center"
      horizontal="center"
      css={css`
        position: absolute;
        top: -12px;
        right: -3px;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        background-color: ${colors.red400};
      `}
    >
      <Text
        size="small"
        color="white"
        css={css`
          margin-bottom: 4px;
        `}
      >
        {number}
      </Text>
    </Flex>
  )
}

export const LeagueItemV2 = ({
  league,
  className,
  display,
  wide,
}: {
  league: HomePageLeague
  className?: string
  display?: LeagueDisplayType
  wide?: boolean
}) => {
  const t = useT()

  const [searchParams] = useSearchParams()
  const club = searchParams.get('club') as string
  const quizzesViewerCanStart = league.quizzes.filter((quiz) => {
    return (
      quiz.viewerCanStartQuiz || Boolean(quiz.viewerData.activeQuizInstance?.id)
    )
  })
  const viewerCanStartQuiz = quizzesViewerCanStart.length > 0

  const { title } =
    display === 'latestQuiz'
      ? getIconAndTitle(quizzesViewerCanStart[0]?.title ?? league.title)
      : getIconAndTitle(league.title)

  return (
    <Link
      to={`/series/${league.slug + (club ? `?club=${club}` : '')}`}
      css={[
        css`
          min-width: 156px;
          max-width: 156px;
          min-height: 170px;
          max-height: 170px;
          text-decoration: none;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          ${wide &&
          css`
            min-width: 220px;
            max-width: 380px;
            width: 100%;
          `}
        `,
      ]}
      className={className}
    >
      <Flex
        column
        vertical="space-between"
        css={[
          css`
            flex: 1;
            border-radius: 15px;
            box-shadow: ${boxShadows.default2};
            position: relative;
            background-color: white;
            padding: 12px 0px;
          `,
        ]}
      >
        {viewerCanStartQuiz && (
          <NotificationCircle number={quizzesViewerCanStart.length} />
        )}

        <Flex column gap={10} horizontal={'center'} vertical={'flex-end'}>
          {league.leagueCardImageUrl ? (
            <img
              css={css`
                border-radius: 50%;
              `}
              width={64}
              height={64}
              src={league.leagueCardImageUrl}
            />
          ) : (
            <Logo size={64} />
          )}
          {league.frequency && (
            <Flex css={margin.top('small')}>
              <Chip
                label={leagueFrequencyToString(t, league.frequency)}
                backgroundColor={colors.grey100}
                style={css`
                  height: 30px;
                `}
                color="black"
              />
            </Flex>
          )}

          <TextNew
            size="huge"
            textAlign="center"
            condensed
            italic
            spacingEm={0}
            css={css`
              font-weight: 700;
              max-width: 100%;
            `}
          >
            {title}
          </TextNew>
        </Flex>

        <div>
          <Flex
            horizontal="center"
            css={css`
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            `}
          >
            <PrimaryButton
              css={css`
                width: calc(100% - 30px);
                height: 34px;
                border-radius: 50px;
                span {
                  font-size: 18px;
                  text-transform: capitalize;
                }
              `}
              variant={'black'}
              size="small"
              disabled={!viewerCanStartQuiz}
            >
              {viewerCanStartQuiz ? t('Play quiz') : 'No quizzes'}
            </PrimaryButton>
          </Flex>
        </div>
      </Flex>
    </Link>
  )
}
