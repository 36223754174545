/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { partition } from 'lodash'
import { FC, ReactNode } from 'react'
import { FaCrown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Text } from '../../components/Text'
import { Star, Trophy } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { lineClamp } from '../../styles/styles'
import { boxShadows } from '../../styles/theme'
import { UserAvatar } from '../Avatar/UserAvatar'
import { formatClubRole } from './clubUtils'
import { ClubRole } from './queries'

type Props = {
  username: string
  slug: string
  role: ClubRole
  avatarData?: string
  backgroundColor?: string
  isAdmin?: boolean
  trophies?: Array<{ id: string; club: { id: string } }>
  adminControls?: ReactNode
  className?: string
  displayName?: string
}

export const PlayerElement: FC<Props> = ({
  username,
  slug,
  role,
  avatarData,
  isAdmin,
  trophies,
  backgroundColor,
  adminControls,
  displayName,
  className,
}) => {
  const t = useT()
  const [clubTrophies, publicTrophies] = partition(trophies, (t) =>
    Boolean(t.club)
  )
  return (
    <Link
      to={`/profile/${slug}`}
      css={css`
        text-decoration: none;
        width: 100%;
      `}
    >
      <Flex
        horizontal="space-between"
        css={css`
          ${margin.top('tiny')}
          padding: 12px 20px;
          border-radius: 2px;
          background-color: ${backgroundColor ?? 'white'};
          box-shadow: ${boxShadows.default};
          height: 50px;
        `}
        className={className}
        gap="small"
      >
        <Flex vertical="center" gap="medium">
          <UserAvatar avatarData={avatarData} height={50} />
          <Flex column>
            <Text size="small" css={lineClamp(1)}>
              {displayName ?? username ?? '-'}
            </Text>
            <Text size="tiny" css={lineClamp(1)}>
              {formatClubRole(t, role)}
            </Text>
          </Flex>
        </Flex>

        <Flex vertical="center" gap="small">
          {isAdmin && <FaCrown size={24} />}

          {publicTrophies.length > 0 && (
            <Flex vertical="center" gap="small">
              <Trophy
                css={css`
                  width: 24px;
                  height: 24px;
                `}
              />
              {publicTrophies.length > 1 && (
                <Text size="small">x{publicTrophies.length}</Text>
              )}
            </Flex>
          )}

          {clubTrophies.length > 0 && (
            <Flex vertical="center" gap="small">
              <Star
                css={css`
                  width: 24px;
                  height: 24px;
                `}
              />
              {clubTrophies.length > 1 && (
                <Text size="small">x{clubTrophies.length}</Text>
              )}
            </Flex>
          )}

          {adminControls && <div>{adminControls}</div>}
        </Flex>
      </Flex>
    </Link>
  )
}
