import { useEffect } from 'react'
import { isProduction } from '../config/config'

export const RevIq = () => {
  useEffect(() => {
    // Don't load RevIq on embed pages
    if (window.location.pathname.includes('embed')) {
      return
    }

    if (!isProduction) {
      return
    }

    const script = document.createElement('script')
    script.type = 'module'
    script.src = '//js.rev.iq'
    script.dataset.domain = 'fcquiz.app'
    document.head.appendChild(script)

    console.log('RevIq loaded')
  }, [])

  return null
}
