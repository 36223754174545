/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useRefValue } from '../../utils/useRefValue'
import {
  ActiveQuestion as ActiveQuestionType,
  useAnswerQuestionMutation,
  useSkipReadQuestionMutation,
} from '../LeagueQuiz/queries'

import { t } from 'i18next'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { AlternativeButton } from '../../components/leagueQuiz/AlternativeButton'
import { Page } from '../../components/leagueQuiz/Page'
import { AVERAGE_MS_PER_WORD } from '../../constants'
import { expand, fadeIn } from '../../styles/animations'
import {
  ChallengeLeagueQuizInstance,
  useAnswerChallengeQuestionMutation,
} from '../ChallengeMatch/queries'

export const ChallengeActiveQuestion: FC<{
  instance: ChallengeLeagueQuizInstance
  numCompletedQuestions: number
  activeQuestion: ActiveQuestionType
  quizInstanceId: string
  colorTheme: 'red' | 'white' | 'green'
}> = ({
  instance,
  numCompletedQuestions,
  activeQuestion,
  quizInstanceId,
  colorTheme,
}) => {
  const initialTime = activeQuestion.timeToReadQuestionInMs
  const [remaining, setRemaining] = useState(initialTime)
  const [showSplash, setShowSplash] = useState(true)
  const [skipReadQuestionMutation] = useSkipReadQuestionMutation()
  const [skipped, setSkipped] = useState(false)

  const isFirstQuestion = numCompletedQuestions === 0

  const skipReadQuestion = useCallback(
    () =>
      skipReadQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId: activeQuestion.id,
          },
        },
      }),
    [skipReadQuestionMutation, quizInstanceId]
  )

  const skip = async () => {
    if (skipped) {
      return
    }

    setSkipped(true)

    const skipMutationRes = await skipReadQuestion()

    const skipEntryId = skipMutationRes.data?.skipReadQuestion?.id

    if (!skipEntryId) {
      console.error('Skipping question failed')
      return
    }

    setShowSplash(false)
  }

  useInterval(
    () => {
      setRemaining((remaining) => remaining - AVERAGE_MS_PER_WORD)
    },
    remaining <= 0 ? null : AVERAGE_MS_PER_WORD
  )

  useEffect(() => {
    if (remaining <= 0) {
      setShowSplash(false)
    }
  }, [remaining])

  const backgroundColor = instance.leagueQuiz.campaign
    ? theme.colors.grey100
    : colorTheme === 'red'
    ? theme.colors.red500
    : colorTheme === 'green'
    ? theme.colors.green600
    : theme.colors.grey100

  const textColor = instance.leagueQuiz.campaign
    ? theme.colors.black
    : colorTheme === 'red' || colorTheme === 'green'
    ? theme.colors.white
    : theme.colors.black

  // remaining > 6
  //   ? theme.colors.green300
  //   : remaining > 3
  //   ? theme.colors.yellow400
  //   : theme.colors.red300

  const progressColor = {
    red: theme.colors.red400,
    green: theme.colors.green500,
    white: theme.colors.green500,
  }

  if (showSplash) {
    return (
      <Page backgroundColor={backgroundColor} dense>
        <Flex
          column
          grow
          css={css`
            min-height: 500px;
            width: 100%;
            cursor: pointer;
          `}
          onClick={skip}
        >
          <Flex
            column
            vertical="space-between"
            grow
            css={css`
              max-width: 560px;
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Flex column>
              <div
                css={css`
                  position: relative;
                  height: 8px;
                  background-color: ${progressColor[colorTheme]};
                `}
              >
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    animation: ${expand} ${initialTime}ms linear;
                    animation-fill-mode: forwards;
                    background-color: ${colors.green300};
                    height: 8px;
                  `}
                />
              </div>

              <Text
                key={activeQuestion.text}
                color={textColor}
                css={margin.vertical('medium')}
                textAlign="center"
                size="medium"
              >
                {activeQuestion.text}
              </Text>
            </Flex>

            <div
              css={css`
                margin-top: auto;
                margin-left: auto;
                margin-right: auto;
              `}
            >
              <Text
                size="small"
                textAlign="center"
                css={[
                  css`
                    color: ${textColor}80;
                    cursor: default;
                  `,
                  isFirstQuestion
                    ? css`
                        opacity: 1;
                      `
                    : css`
                        opacity: 0;
                        animation: ${fadeIn} 600ms ease-out;
                        animation-delay: 1000ms;
                        animation-fill-mode: forwards;
                      `,
                ]}
              >
                {t('Tap to skip')}
              </Text>
            </div>
          </Flex>
        </Flex>
      </Page>
    )
  }

  return (
    <ChallengeAnswerQuestion
      {...{
        instance,
        numCompletedQuestions,
        activeQuestion,
        quizInstanceId,
        colorTheme,
      }}
    />
  )
}

const ChallengeAnswerQuestion: FC<{
  instance: ChallengeLeagueQuizInstance
  activeQuestion: ActiveQuestionType
  quizInstanceId: string
  colorTheme: 'red' | 'white' | 'green'
}> = ({ activeQuestion, quizInstanceId, colorTheme, instance }) => {
  const t = useT()
  const [
    answerQuestionMutation,
    { loading: answerQuestionLoading, error: answerQuestionError },
  ] = useAnswerQuestionMutation()

  const [
    answerChallengeQuestionMutation,
    {
      loading: answerChallengeQuestionLoading,
      error: answerChallengeQuestionError,
    },
  ] = useAnswerChallengeQuestionMutation()

  const answerQuestion = useCallback(
    async ({
      questionId,
      alternativeId,
    }: {
      questionId: string
      alternativeId: string | null
    }) => {
      // add 350 ms delay for animations, is compensated on server
      await new Promise((resolve) => setTimeout(resolve, 350))
      return answerChallengeQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId,
            alternativeId,
            challengeQuizInstanceId: instance.challengeQuizInstanceId!,
            challengeMatchId: instance.challengeMatchId!,
          },
        },
      })
    },
    [
      answerQuestionMutation,
      answerChallengeQuestionMutation,
      quizInstanceId,
      instance,
    ]
  )

  const initialSecondsLeft = activeQuestion.secondsLeft

  const [remaining, setRemaining] = useState(initialSecondsLeft)
  const [timerRunning, setTimerRunning] = useState(true)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 || !timerRunning ? null : 1000
  )

  const savedOnFinished = useRefValue(() => {
    if (
      activeQuestion &&
      !answerQuestionLoading &&
      !answerChallengeQuestionLoading
    ) {
      answerQuestion({
        questionId: activeQuestion.id,
        alternativeId: null,
      })
    }
  })
  useEffect(() => {
    if (remaining <= 0) {
      savedOnFinished.current()
    }
  }, [remaining, savedOnFinished])

  const shrink = keyframes`
    from {
      width: ${
        100 * (initialSecondsLeft / activeQuestion.maxTimeToAnswerInSeconds)
      }%;
    }
    to {
      width: 0%;
    }
`

  const backgroundColor = instance.leagueQuiz.campaign
    ? theme.colors.grey100
    : colorTheme === 'red'
    ? theme.colors.red500
    : colorTheme === 'green'
    ? theme.colors.green600
    : theme.colors.grey100

  const textColor = instance.leagueQuiz.campaign
    ? theme.colors.black
    : colorTheme === 'red' || colorTheme === 'green'
    ? theme.colors.white
    : theme.colors.black

  const progressColorRemaining =
    remaining > 6
      ? theme.colors.green300
      : remaining > 3
      ? theme.colors.yellow400
      : theme.colors.red300

  const progressColor = {
    red: theme.colors.red400,
    green: theme.colors.green500,
    white: theme.colors.green500,
  }

  const error = answerQuestionError || answerChallengeQuestionError

  return (
    <Page backgroundColor={backgroundColor} dense>
      <Flex
        column
        grow
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            height: 8px;
            background-color: ${progressColor[colorTheme]};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              animation: ${shrink} ${initialSecondsLeft}s linear;
              animation-play-state: ${timerRunning ? 'running' : 'paused'};
              background-color: ${progressColorRemaining};
              height: 8px;
            `}
          />
        </div>
        <Text
          key={activeQuestion.text}
          color={textColor}
          css={margin.vertical('medium')}
          textAlign="center"
          size="medium"
        >
          {activeQuestion.text}
        </Text>

        {error && (
          <Flex
            css={css`
              margin-bottom: 10px;
            `}
            horizontal="center"
          >
            <ErrorMessage>
              {error.name.includes('INTERNET')
                ? t(`Please check your Internet connection and try again`)
                : t(`Something went wrong`)}
            </ErrorMessage>
          </Flex>
        )}

        <Flex column>
          {activeQuestion.alternatives.map((alternative) => {
            return (
              <AlternativeButton
                key={alternative.id}
                onClick={() => {
                  answerQuestion({
                    questionId: activeQuestion.id,
                    alternativeId: alternative.id,
                  })
                  setTimerRunning(false)
                }}
                disabled={
                  answerQuestionLoading || answerChallengeQuestionLoading
                }
              >
                {alternative.text}
              </AlternativeButton>
            )
          })}
        </Flex>
      </Flex>
    </Page>
  )
}
