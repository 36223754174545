/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { FC, useCallback, useState } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { AlternativeButton } from '../../../components/leagueQuiz/AlternativeButton'
import { QuizStatusComponent } from '../../../components/leagueQuiz/QuizStatusComponent'
import { Text } from '../../../components/Text'
import { ErrorMessage } from '../../../components/TextInput'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../../styles/animations'
import { margin, Spacer } from '../../../styles/margin'
import theme from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { useLeagueQuizContext } from '../../LeagueQuiz/LeagueQuizContext'
import {
  ActiveQuestion as ActiveQuestionType,
  LeagueQuiz as LeagueQuizType,
} from '../../LeagueQuiz/queries'
import { usePlayerAnswerQuestionMutation } from '../../LiveQuiz/queries'

export const LiveReadQuestionScreen: FC<{
  leagueQuiz: LeagueQuizType
  numCompletedQuestions: number
  activeQuestion: ActiveQuestionType
  colorTheme: 'red' | 'white' | 'green'
}> = ({
  leagueQuiz,
  numCompletedQuestions,
  activeQuestion,
  colorTheme = 'white',
}) => {
  const { campaignHeaderHeight } = useLeagueQuizContext()

  const isFirstQuestion = numCompletedQuestions === 0

  const t = useT()

  const textColor = leagueQuiz.campaign
    ? theme.colors.black
    : colorTheme === 'red' || colorTheme === 'green'
    ? theme.colors.white
    : theme.colors.black

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        grow
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <Flex
          column
          grow
          css={css`
            max-width: 560px;
            margin: 0 auto;
            width: 100%;
            height: 100%;
          `}
        >
          <Spacer height={'small'} />
          <QuizStatusComponent
            currentQuestion={numCompletedQuestions}
            totalQuestions={leagueQuiz.questionsCount}
          />
          <Spacer height={'small'} />
          <Text
            key={activeQuestion.text}
            color={textColor}
            css={margin.vertical('medium')}
            textAlign="center"
            size="medium"
          >
            {activeQuestion.text}
          </Text>

          <div
            css={css`
              margin-top: auto;
              margin-left: auto;
              margin-right: auto;
            `}
          >
            <Text
              size="small"
              textAlign="center"
              css={[
                css`
                  color: ${textColor}80;
                  cursor: default;
                `,
                isFirstQuestion
                  ? css`
                      opacity: 1;
                    `
                  : css`
                      opacity: 0;
                      animation: ${fadeIn} 600ms ease-out;
                      animation-delay: 1000ms;
                      animation-fill-mode: forwards;
                    `,
              ]}
            >
              {t(`Waiting for the host to start the question`)}
            </Text>
          </div>
        </Flex>
      </Flex>
    </PageContent>
  )
}

export const LiveActiveQuestion: FC<{
  leagueQuiz: LeagueQuizType
  numCompletedQuestions: number
  activeQuestion: ActiveQuestionType
  quizInstanceId: string
  colorTheme: 'red' | 'white' | 'green'
  isLastQuestion: boolean
}> = ({
  leagueQuiz,
  numCompletedQuestions,
  activeQuestion,
  quizInstanceId,
  colorTheme,
  isLastQuestion,
}) => {
  const t = useT()

  const [answeredAlternative, setAnsweredAlternative] = useState<
    string | undefined
  >()

  const [
    answerQuestionMutation,
    { loading: answerQuestionLoading, error: answerQuestionError },
  ] = usePlayerAnswerQuestionMutation()

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const answerQuestion = useCallback(
    async ({
      questionId,
      alternativeId,
    }: {
      questionId: string
      alternativeId: string | null
    }) => {
      return answerQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId,
            alternativeId,
            isLastQuestion,
          },
        },
      })
    },
    [answerQuestionMutation, quizInstanceId]
  )

  const initialSecondsLeft = activeQuestion.secondsLeft

  const [remaining, setRemaining] = useState(initialSecondsLeft)
  const [timerRunning] = useState(true)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 || !timerRunning ? null : 1000
  )

  // const savedOnFinished = useRefValue(() => {
  //   if (activeQuestion && !answerQuestionLoading) {
  //     answerQuestion({
  //       questionId: activeQuestion.id,
  //       alternativeId: null,
  //     })
  //   }
  // })

  // useEffect(() => {
  //   if (remaining <= 0) {
  //     if (answeredAlternative) {
  //       return
  //     }
  //     savedOnFinished.current()
  //   }
  // }, [remaining, savedOnFinished])

  const shrink = keyframes`
    from {
      width: ${
        100 * (initialSecondsLeft / activeQuestion.maxTimeToAnswerInSeconds)
      }%;
    }
    to {
      width: 0%;
    }
`

  const textColor = leagueQuiz.campaign
    ? theme.colors.black
    : colorTheme === 'red' || colorTheme === 'green'
    ? theme.colors.white
    : theme.colors.black

  const progressColorRemaining =
    remaining > 6
      ? theme.colors.green300
      : remaining > 3
      ? theme.colors.yellow400
      : theme.colors.red300

  const progressColor = {
    red: theme.colors.red400,
    green: theme.colors.green500,
    white: theme.colors.green500,
  }

  const error = answerQuestionError

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        grow
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            height: 8px;
            background-color: ${progressColor[colorTheme]};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              animation: ${shrink} ${initialSecondsLeft}s linear;
              animation-play-state: ${timerRunning ? 'running' : 'paused'};
              background-color: ${progressColorRemaining};
              height: 8px;
            `}
          />
        </div>
        <Spacer height={'small'} />
        <QuizStatusComponent
          currentQuestion={numCompletedQuestions}
          totalQuestions={leagueQuiz.questionsCount}
        />
        <Spacer height={'small'} />

        {!answeredAlternative && (
          <>
            <Text
              key={activeQuestion.text}
              color={textColor}
              css={margin.vertical('medium')}
              textAlign="center"
              size="medium"
            >
              {activeQuestion.text}
            </Text>

            {error && (
              <Flex
                css={css`
                  margin-bottom: 10px;
                `}
                horizontal="center"
              >
                <ErrorMessage>
                  {error.name.includes('INTERNET')
                    ? t(`Please check your Internet connection and try again`)
                    : t(`Something went wrong`)}
                </ErrorMessage>
              </Flex>
            )}

            <Flex column>
              {activeQuestion.alternatives.map((alternative) => {
                return (
                  <AlternativeButton
                    key={alternative.id}
                    onClick={async () => {
                      if (answeredAlternative) {
                        return
                      }
                      await answerQuestion({
                        questionId: activeQuestion.id,
                        alternativeId: alternative.id,
                      })

                      setAnsweredAlternative(alternative.id)
                    }}
                    disabled={
                      answerQuestionLoading || answeredAlternative !== undefined
                    }
                  >
                    {alternative.text}
                  </AlternativeButton>
                )
              })}
            </Flex>
          </>
        )}
        {answeredAlternative && (
          <Flex
            column
            grow
            horizontal="center"
            css={css`
              margin-top: 100px;
              opacity: 0;
              animation: ${growAndFade} 350ms cubic-bezier(0.4, 0, 0.15, 1);
              animation-delay: 600ms;
              animation-fill-mode: forwards;
            `}
          >
            <Text
              color={textColor}
              css={margin.vertical('medium')}
              textAlign="center"
              size="medium"
            >
              {t(`Waiting for the other players to answer ⚽️`)}
            </Text>
          </Flex>
        )}
      </Flex>
    </PageContent>
  )
}
